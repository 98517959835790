var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.subscriptionRequests.length
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "brand-medium pointer",
            on: {
              click: function ($event) {
                return _vm.$router.push("/invoices?v=subscriptions")
              },
            },
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("welcome.currently-have")) + " "),
            _c("span", { staticStyle: { color: "var(--v-primary-base)" } }, [
              _vm._v(_vm._s(_vm.subscriptionRequests.length)),
            ]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.$tc(
                    "welcome.subscription-payments",
                    _vm.subscriptionRequests.length
                  )
                ) +
                ". "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "column-format",
            staticStyle: { gap: "8px", "margin-top": "12px" },
          },
          _vm._l(_vm.subscriptionRequests, function (request) {
            return _c(
              "div",
              {
                key: request.id,
                staticClass: "action-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.openRequest(request)
                  },
                },
              },
              _vm._l(request.productSelections, function (product, index) {
                return _c("div", { key: index }, [
                  _vm._v(
                    " " +
                      _vm._s(product.quantity) +
                      " x " +
                      _vm._s(product.subscriptionProduct.name) +
                      " @ " +
                      _vm._s(
                        _vm.$formatters.dollars(
                          product.subscriptionProduct.prices[0].unitAmount /
                            100,
                          true,
                          true,
                          product.subscriptionProduct.prices[0].currency
                        )
                      ) +
                      " "
                  ),
                ])
              }),
              0
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }