var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "confirm-dialog-div" },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "", rounded: "" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.handleClose()
            },
          },
        },
        [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _vm.headingText
          ? _c("div", { staticClass: "modal-title text-center mb-2 mt-4" }, [
              _c(
                "span",
                { staticClass: "pr-1" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mt-n1",
                      attrs: { color: _vm.severity, size: "24" },
                    },
                    [_vm._v(_vm._s(_vm.icon))]
                  ),
                ],
                1
              ),
              _vm._v(" " + _vm._s(_vm.headingText) + " "),
            ])
          : _c("div", { staticClass: "mt-6" }),
        _vm.bodyText
          ? _c("div", {
              staticClass: "modal-subtitle text-center",
              domProps: { innerHTML: _vm._s(_vm.bodyText) },
            })
          : _vm._e(),
      ]),
      _c(
        "v-form",
        {
          ref: "confirmDialog",
          attrs: { id: "confirmDialog", "lazy-validation": "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleSubmit()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              !_vm.hideNo
                ? _c(
                    "v-btn",
                    {
                      class: `${
                        _vm.noIsSuperAction ? "super-action" : "primary-action"
                      } mr-1`,
                      attrs: { "min-width": "100" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleClose()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.no))]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  class: `${
                    !_vm.noIsSuperAction ? "super-action" : "primary-action"
                  } ml-1`,
                  attrs: { type: "submit", "min-width": "100", elevation: "0" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleSubmit()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.yes) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }