import { render, staticRenderFns } from "./ElementTypes.vue?vue&type=template&id=2034af66&scoped=true"
import script from "./ElementTypes.vue?vue&type=script&lang=js"
export * from "./ElementTypes.vue?vue&type=script&lang=js"
import style0 from "./ElementTypes.vue?vue&type=style&index=0&id=2034af66&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2034af66",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2503387672/src/struxture-clientportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2034af66')) {
      api.createRecord('2034af66', component.options)
    } else {
      api.reload('2034af66', component.options)
    }
    module.hot.accept("./ElementTypes.vue?vue&type=template&id=2034af66&scoped=true", function () {
      api.rerender('2034af66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/agreements/schema/ElementTypes.vue"
export default component.exports