var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: `padding-left: ${_vm.padding(
        _vm.item.pl
      )}px; padding-right: ${_vm.padding(_vm.item.pr)}px; padding-bottom: ${
        _vm.item.pb
      }px; padding-top: ${_vm.item.pt}px;`,
      attrs: { id: _vm.item.id },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _vm.userSigners.length
                ? _c(
                    "v-col",
                    {
                      staticClass: "column-format gap-2",
                      attrs: { cols: _vm.cols },
                    },
                    _vm._l(_vm.userSigners, function (userSigner) {
                      return _c("signer", {
                        key: userSigner.id,
                        attrs: {
                          agreement: _vm.agreement,
                          item: _vm.element,
                          signer: userSigner,
                          "is-current-user":
                            _vm.signer && _vm.signer.id === userSigner.id,
                        },
                        on: {
                          signed: function ($event) {
                            return _vm.handleSigned($event)
                          },
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.clientSigners.length
                ? _c(
                    "v-col",
                    {
                      staticClass: "column-format gap-2",
                      attrs: { cols: _vm.cols },
                    },
                    _vm._l(_vm.clientSigners, function (clientSigner) {
                      return _c("signer", {
                        key: clientSigner.id,
                        attrs: {
                          agreement: _vm.agreement,
                          item: _vm.element,
                          signer: clientSigner,
                          "is-current-user":
                            _vm.signer && _vm.signer.id === clientSigner.id,
                        },
                        on: {
                          signed: function ($event) {
                            return _vm.handleSigned($event)
                          },
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.userSigners.length === 0 && _vm.clientSigners.length === 0
                ? _c(
                    "v-col",
                    { staticClass: "font-gray_80 py-8" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            elevation: "0",
                            color: "secondary",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$store.state.eventBus.$emit(
                                "agreement-show-signers"
                              )
                            },
                          },
                        },
                        [_vm._v("Add signers")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }