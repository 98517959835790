var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { "margin-left": "0px", "margin-right": "3px" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "draggable",
        {
          attrs: {
            list: _vm.tasks,
            tag: "section",
            animation: 200,
            handle: ".drag",
          },
          on: { end: _vm.endDragHandler },
        },
        _vm._l(_vm.tasks, function (t, index) {
          return _c(
            "div",
            {
              key: t.id,
              class: `row-format ${
                _vm.detailView ? "task-item-detail" : "task-item"
              }`,
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-top": "1px" } },
                [
                  _c("v-checkbox", {
                    staticStyle: { "margin-top": "-7px" },
                    attrs: {
                      dense: "",
                      ripple: false,
                      disabled: _vm.disabled,
                      "hide-details": "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$emit("tasks-updated")
                      },
                    },
                    model: {
                      value: t.complete,
                      callback: function ($$v) {
                        _vm.$set(t, "complete", $$v)
                      },
                      expression: "t.complete",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  class:
                    "force-wrap edit-content " +
                    (t.complete ? "item-complete" : ""),
                  staticStyle: { outline: "none", "max-width": "320px" },
                  attrs: {
                    contenteditable: !t.complete && !_vm.disabled,
                    id: `task${t.id}`,
                  },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                          "Backspace",
                          "Delete",
                          "Del",
                        ])
                      )
                        return null
                      return _vm.checkDeleteTask(
                        t,
                        index,
                        $event.target.innerText
                      )
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      )
                        return null
                      $event.preventDefault()
                      _vm.setDescription(t, $event.target.innerText)
                      _vm.addNewTask(index + 1)
                    },
                    blur: function ($event) {
                      return _vm.setDescription(t, $event)
                    },
                  },
                },
                [_vm._v(_vm._s(t.description))]
              ),
              !_vm.disabled
                ? _c(
                    "div",
                    {
                      staticClass: "row-format",
                      staticStyle: { "margin-left": "auto" },
                    },
                    [
                      _vm.detailView
                        ? _c(
                            "div",
                            {
                              staticClass: "icon mr-1 pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteTask(index)
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: _vm.detailView ? 18 : 12 } },
                                [_vm._v("$delete")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "drag icon" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: _vm.detailView ? 18 : 12 } },
                            [_vm._v("$drag")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
      !_vm.disabled
        ? _c(
            "div",
            {
              class: _vm.detailView ? "add-full" : "add-compact",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.addNewTask(_vm.tasks.length)
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "fit-content" } },
                [
                  _c("v-icon", { staticClass: "mb-1", attrs: { size: "14" } }, [
                    _vm._v("$plus"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("projects.add-task"))),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }