var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        {
          key: _vm.refreshKey,
          class: `${
            _vm.inFrame ? "outer-wrapper-in-frame" : "outer-wrapper"
          } pa-6 column-format align-center main-form`,
          style: `background-color: ${_vm.styles.backgroundColor}; width:100%; --font-color: ${_vm.styles.fontColor}; --form-font-size: ${_vm.styles.fontSize}px; --form-input-color: ${_vm.styles.formInputColor}; font-family: '${_vm.form.font}'; color: ${_vm.styles.fontColor}!important`,
        },
        [
          !_vm.complete
            ? _c(
                "div",
                {
                  style: `width:100%; min-width: 300px; max-width: ${_vm.styles.maxWidth}px`,
                },
                [
                  _vm._l(_vm.pages[_vm.currentPage], function (item, index) {
                    return _c(
                      "div",
                      { key: item.id, attrs: { id: item.id } },
                      [
                        _c("item-renderer", {
                          ref: "item",
                          refInFor: true,
                          attrs: {
                            index: index + (_vm.currentPage + 1) * 1000,
                            item: item,
                            styles: _vm.styles,
                            font: _vm.font,
                            "button-props": _vm.buttonProps,
                            disabled: _vm.disabled,
                            "current-page": _vm.currentPage + 1,
                            "page-count": _vm.pages.length,
                            "account-logo": _vm.accountLogo,
                            active: false,
                            results: _vm.results,
                            "known-data": _vm.defaultContact,
                          },
                          on: {
                            "next-page": function ($event) {
                              return _vm.nextPage()
                            },
                            "previous-page": function ($event) {
                              return _vm.previousPage()
                            },
                            input: function ($event) {
                              return _vm.handleInput($event)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm.isLastPage
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "row-format gap-2 pt-2 px-2",
                            style: `width: 100%; justify-content: ${_vm.justification}`,
                          },
                          [
                            _vm.pages.length > 1
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "elevation-0",
                                    style: `font-size: var(--form-font-size) !important; ${
                                      _vm.buttonProps.block
                                        ? "width: 49%!important;"
                                        : ""
                                    }`,
                                    attrs: {
                                      color: _vm.styles.buttonColor,
                                      "x-small": _vm.buttonProps.xSmall,
                                      small: _vm.buttonProps.small,
                                      large: _vm.buttonProps.large,
                                      "x-large": _vm.buttonProps.xLarge,
                                    },
                                    on: { click: _vm.previousPage },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: {
                                          color: _vm.styles.buttonTextColor,
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.styles.prevPageIcon))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        style: `font-family: ${_vm.font}; ; text-transform: none; color: ${_vm.styles.buttonTextColor}`,
                                      },
                                      [_vm._v(_vm._s(_vm.styles.prevPageText))]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                staticClass: "elevation-0",
                                style: `font-size: var(--form-font-size) !important; ${
                                  _vm.pages.length > 1 && _vm.buttonProps.block
                                    ? "width: 49%!important;"
                                    : ""
                                }`,
                                attrs: {
                                  color: _vm.styles.buttonColor,
                                  "x-small": _vm.buttonProps.xSmall,
                                  small: _vm.buttonProps.small,
                                  large: _vm.buttonProps.large,
                                  "x-large": _vm.buttonProps.xLarge,
                                  block:
                                    _vm.pages.length === 1 &&
                                    _vm.buttonProps.block,
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitForm()
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    style: `font-family: ${_vm.font}; ; text-transform: none; color: ${_vm.styles.buttonTextColor}`,
                                  },
                                  [_vm._v(_vm._s(_vm.styles.submitText))]
                                ),
                                _vm.styles.submitIcon
                                  ? _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "ml-1 material-symbols-outlined",
                                        attrs: {
                                          color: _vm.$vuetify.theme.dark
                                            ? "black"
                                            : _vm.styles.buttonTextColor,
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.styles.submitIcon))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._m(0),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _c("div", [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.form.confirmationText) },
                }),
              ]),
          _c(
            "v-snackbar",
            {
              attrs: { top: "", color: "gray_80", timeout: 5000 },
              scopedSlots: _vm._u(
                [
                  {
                    key: "action",
                    fn: function ({ attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._b(
                            {
                              attrs: { color: "error", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.showError = false
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          [_vm._v(" OK ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                441339782
              ),
              model: {
                value: _vm.showError,
                callback: function ($$v) {
                  _vm.showError = $$v
                },
                expression: "showError",
              },
            },
            [_vm._v(" " + _vm._s(_vm.errorText) + " ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "font-10 font-gray_70 mt-8" }, [
      _vm._v(" This page is protected by reCAPTCHA and the Google "),
      _c("a", { attrs: { href: "https://policies.google.com/privacy" } }, [
        _vm._v("Privacy Policy"),
      ]),
      _vm._v(" and "),
      _c("a", { attrs: { href: "https://policies.google.com/terms" } }, [
        _vm._v("Terms of Service"),
      ]),
      _vm._v(" apply. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }