var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "v-app",
        {
          style: `--primary-font: ${_vm.primaryFont}; height:100%;`,
          attrs: { "data-app": "" },
        },
        [
          _vm.$store.state.isLoggedIn
            ? _c("div", { staticClass: "column-format fill-all" }, [
                _c(
                  "div",
                  {
                    staticClass: "row-format",
                    style: `--v-right-nav-width:${_vm.rightNavWidth}; --v-left-nav-width:${_vm.leftNavWidth}; overflow-y: hidden`,
                    attrs: { id: "app-area" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-none d-sm-flex",
                        class: this.$store.state.leftNavExpanded
                          ? "left-nav-expanded"
                          : "left-nav-collapsed",
                        attrs: { id: "left-nav" },
                      },
                      [_c("left-nav")],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "column-format",
                        attrs: { id: "right-section" },
                      },
                      [
                        _c("top-nav"),
                        _c("div", { attrs: { id: "main-app" } }, [
                          _c("div", { staticClass: "row-format" }, [
                            _c(
                              "div",
                              { attrs: { id: "main-content" } },
                              [_c("v-main", [_c("router-view")], 1)],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.$store.state.rightNavExpanded ||
                                      !_vm.mobile,
                                    expression:
                                      "$store.state.rightNavExpanded || !mobile",
                                  },
                                ],
                                staticClass: "ml-4",
                                attrs: { id: "right-bar" },
                              },
                              [_c("right-nav")],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _c(
                "div",
                { staticStyle: { height: "100%" } },
                [_c("router-view")],
                1
              ),
          _c(
            "v-snackbar",
            {
              staticStyle: { "font-size": "1.1em" },
              attrs: {
                id: "main-snackbar",
                color: _vm.color,
                timeout: _vm.timeout,
                top: true,
              },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.message))]),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", icon: "", id: "main-snackbar-close" },
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-overlay",
            {
              staticStyle: { "z-index": "1000" },
              attrs: { value: _vm.$store.state.loadingState },
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
          _c("global-modal-controller", { ref: "gmc" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }