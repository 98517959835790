var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "text-left",
      style: `padding-left: ${_vm.padding(
        _vm.item.pl
      )}px; padding-right: ${_vm.padding(_vm.item.pr)}px; padding-bottom: ${
        _vm.item.pb
      }px; padding-top: ${_vm.item.pt}px; font-size: ${_vm.item.fontSize}px `,
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [_c("div", { domProps: { innerHTML: _vm._s(_vm.tokenizedText) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }