var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      _c("v-progress-circular", {
        attrs: { indeterminate: true, size: "200", color: "light-blue" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }