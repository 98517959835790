var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      style: _vm.styles,
      attrs: { xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true" },
      on: {
        mouseover: function ($event) {
          _vm.internalHover = true
        },
        mouseleave: function ($event) {
          _vm.internalHover = false
        },
      },
    },
    [_c("use", { attrs: { id: _vm.id, "xlink:href": _vm.href } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }