var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "topNavWrapper",
      staticClass: "row-format align-center",
      attrs: { id: "top-nav-wrapper" },
    },
    [
      _c(
        "div",
        {
          ref: "topNav",
          staticStyle: { width: "100%" },
          attrs: { id: "top-nav" },
        },
        [
          !_vm.mobile
            ? _c(
                "div",
                { staticClass: "row-format", staticStyle: { width: "100%" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row-format align-center",
                      attrs: { id: "left-side" },
                    },
                    [
                      _c("tabs", {
                        attrs: {
                          "tab-config": _vm.currentTabsConfig,
                          "current-path": _vm.currentPath,
                          "offset-width": _vm.$refs.rightSide
                            ? _vm.$refs.rightSide.clientWidth
                            : 508,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      ref: "rightSide",
                      staticClass: "ml-auto",
                      attrs: { id: "right-side" },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: `mr-2 right ${
                            _vm.$store.state.rightNavExpanded
                              ? "right-big"
                              : "right-small"
                          }`,
                          staticStyle: {
                            "justify-content": "space-evenly",
                            "border-left": "1px solid var(--v-gray_50-base)",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: `pointer top-button ${
                                _vm.$store.state.rightNavFocus ===
                                "notifications"
                                  ? "active-top"
                                  : ""
                              }`,
                              on: {
                                click: function ($event) {
                                  return _vm.openRightNav("notifications")
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  class:
                                    _vm.$store.state.rightNavFocus ===
                                    "notifications"
                                      ? ""
                                      : "material-symbols-outlined",
                                  attrs: {
                                    color:
                                      _vm.$store.state.rightNavFocus ===
                                      "notifications"
                                        ? "primary"
                                        : "secondary",
                                  },
                                },
                                [_vm._v("notifications_active")]
                              ),
                              _vm.$store.state.actionCount
                                ? _c("div", { staticClass: "top-umi" }, [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.$store.state.actionCount)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "content-class": "user-menu",
                                "offset-y": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("div", _vm._g({}, on), [
                                          _c(
                                            "div",
                                            { staticClass: "profile-pic" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "white--text" },
                                                [_vm._v(_vm._s(_vm.initials))]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2641173716
                              ),
                            },
                            [_c("user-menu")],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm.mobile
            ? _c(
                "div",
                {
                  staticClass: "row-format align-center",
                  staticStyle: { width: "100%" },
                },
                [
                  _c("div", { staticClass: "ml-2 pointer" }, [
                    _c("img", {
                      staticStyle: {
                        "max-width": "120px",
                        "max-height": "50px",
                      },
                      attrs: { src: _vm.logo },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/welcome")
                        },
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-auto row-format gap-2" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-btn",
                                          _vm._b(
                                            { attrs: { icon: "" } },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "material-symbols-outlined",
                                                attrs: {
                                                  size: "30",
                                                  color: "black",
                                                },
                                              },
                                              [_vm._v("notifications_active")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.$store.state.actionCount
                                          ? _c(
                                              "div",
                                              { staticClass: "top-umi" },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$store.state
                                                        .actionCount
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4051291297
                          ),
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "column-format align-center",
                              staticStyle: {
                                width: "300px",
                                height: "calc(100vh - 120px)",
                                "background-color": "var(--v-white-base)",
                              },
                            },
                            [_c("notifications")],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-menu",
                        {
                          staticClass: "pages",
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mr-2",
                                            attrs: { icon: "" },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "30",
                                              color: "black",
                                            },
                                          },
                                          [_vm._v("menu")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3373471997
                          ),
                        },
                        [_c("MobileNav")],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }