var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-ach-dialog-div" },
    [
      _c(
        "v-container",
        { staticClass: "mt-0 mb-4", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: "1" } }),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("invoices.verify-ach-account.enter-verification")
                    )
                  ),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c("v-icon", { on: { click: _vm.handleClose } }, [
                    _vm._v("$close"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "accountForm",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                      model: {
                        value: _vm.isValid,
                        callback: function ($$v) {
                          _vm.isValid = $$v
                        },
                        expression: "isValid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          label: _vm.$t(
                            "invoices.verify-ach-account.enter-deposit-1"
                          ),
                          "hide-details": "",
                          maxlength: "2",
                          prefix: "$0.",
                          rules: _vm.requiredRules,
                          "persistent-placeholder": "",
                        },
                        model: {
                          value: _vm.amount1,
                          callback: function ($$v) {
                            _vm.amount1 = $$v
                          },
                          expression: "amount1",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          label: _vm.$t(
                            "invoices.verify-ach-account.enter-deposit-2"
                          ),
                          "hide-details": "",
                          maxlength: "2",
                          prefix: "$0.",
                          rules: _vm.requiredRules,
                          "persistent-placeholder": "",
                        },
                        model: {
                          value: _vm.amount2,
                          callback: function ($$v) {
                            _vm.amount2 = $$v
                          },
                          expression: "amount2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "pt-2 pb-4",
          staticStyle: { "border-top": "1px solid var(--v-gray_50-base)" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              on: { click: _vm.handleClose },
            },
            [_vm._v(_vm._s(_vm.$t("global.cancel")))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action ml-2",
              attrs: { disabled: !_vm.isValid },
              on: { click: _vm.verifyAccount },
            },
            [_vm._v(_vm._s(_vm.$t("global.verify")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }