var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c("div", { staticClass: "ma-4" }, [
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("v-data-table", {
              attrs: {
                headers: _vm.headers,
                "hide-default-footer": true,
                "disable-pagination": true,
                "mobile-breakpoint": 200,
                items: _vm.accounts,
                "no-data-text": _vm.$t("invoices.viewer.link-account"),
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.label",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "span",
                          { staticStyle: { "text-transform": "capitalize" } },
                          [_vm._v(_vm._s(item.label.toLowerCase()))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.routing",
                    fn: function ({ item }) {
                      return [
                        item.routing
                          ? _c("span", [_vm._v(_vm._s(item.routing))])
                          : _c("span", [_vm._v("--")]),
                      ]
                    },
                  },
                  {
                    key: "item.status",
                    fn: function ({ item }) {
                      return [
                        item.status === "verified"
                          ? _c(
                              "span",
                              {
                                staticStyle: { "text-transform": "capitalize" },
                              },
                              [_vm._v(_vm._s(item.status))]
                            )
                          : _c(
                              "v-btn",
                              {
                                staticClass: "primary-action",
                                on: {
                                  click: function ($event) {
                                    return _vm.verifyAccount(item)
                                  },
                                },
                              },
                              [
                                _c("v-icon", [_vm._v("$alert")]),
                                _vm._v(_vm._s(_vm.$t("global.verify"))),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                  {
                    key: "item.id",
                    fn: function ({ item }) {
                      return [
                        item.type === "bank_account"
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "pointer",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteAccount(item)
                                  },
                                },
                              },
                              [_vm._v("$delete")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                1413517552
              ),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-left mt-6 row-format gap-2" },
          [
            _vm.achEnabled
              ? _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    staticStyle: { width: "240px" },
                    on: {
                      click: function ($event) {
                        return _vm.linkNewAccount()
                      },
                    },
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v("$plus")]
                    ),
                    _c("span", { staticClass: "brand-medium" }, [
                      _vm._v(
                        _vm._s(_vm.$t("invoices.ach-account.new-account"))
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-btn",
              {
                staticClass: "primary-action",
                on: {
                  click: function ($event) {
                    return _vm.managePaymentMethods()
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("invoices.ach-account.manage-payment-methods")
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }