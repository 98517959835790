import { render, staticRenderFns } from "./InvoiceSettings.vue?vue&type=template&id=6334f025&scoped=true"
import script from "./InvoiceSettings.vue?vue&type=script&lang=js"
export * from "./InvoiceSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6334f025",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2503387672/src/struxture-clientportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6334f025')) {
      api.createRecord('6334f025', component.options)
    } else {
      api.reload('6334f025', component.options)
    }
    module.hot.accept("./InvoiceSettings.vue?vue&type=template&id=6334f025&scoped=true", function () {
      api.rerender('6334f025', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/invoices/invoiceRendererV2/InvoiceSettings.vue"
export default component.exports