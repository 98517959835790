var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mx-0 my-5",
      attrs: { id: "proposal-wrapper" },
      on: {
        click: function ($event) {
          return _vm.setEditIndex(-1)
        },
      },
    },
    [
      _c("div", { attrs: { id: "proposal-area" } }, [
        _c(
          "div",
          {
            style: `--v-proposal-height:${_vm.proposalHeight}px;`,
            attrs: { id: "proposal" },
          },
          [
            _c(
              "div",
              {
                staticClass: "proposal-wrapper",
                attrs: { id: "proposal-background" },
              },
              [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      {
                        class: _vm.editMode ? "edit-mode" : "",
                        attrs: { id: "cover-page-row" },
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-0 mb-5", attrs: { cols: "12" } },
                          [
                            _c(
                              "cover-page",
                              _vm._b(
                                {
                                  attrs: {
                                    "proposal-type": _vm.proposal.proposalType,
                                    mceConfigHeader: _vm.mceConfigCover,
                                    editMode: _vm.editMode,
                                    templateMode: _vm.templateMode,
                                    formatting: _vm.proposal.formatting,
                                    "client-name": _vm.clientName,
                                    "contractor-name": _vm.contractorName,
                                    "client-mode": _vm.clientMode,
                                    "heading-position":
                                      _vm.proposal.coverPage.headingPosition,
                                    logo: _vm.logo,
                                  },
                                  on: {
                                    changed: function ($event) {
                                      return _vm.handleCoverPageChange($event)
                                    },
                                    "cover-modal": function ($event) {
                                      return _vm.$emit("cover-menu-open")
                                    },
                                  },
                                },
                                "cover-page",
                                _vm.proposal.coverPage,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isReady
                      ? _c(
                          "draggable",
                          {
                            attrs: { group: "schema", handle: ".handle" },
                            on: { end: _vm.dragEnd },
                            model: {
                              value: _vm.proposal.contentBlocks,
                              callback: function ($$v) {
                                _vm.$set(_vm.proposal, "contentBlocks", $$v)
                              },
                              expression: "proposal.contentBlocks",
                            },
                          },
                          _vm._l(_vm.contentBlocks, function (block, index) {
                            return _c(
                              "v-row",
                              {
                                key: block.id,
                                attrs: { align: "center" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.setEditIndex(index)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "content-block pa-0 mb-1",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "intersect",
                                          rawName: "v-intersect",
                                          value: {
                                            handler: _vm.handleIntersect,
                                            options: _vm.scrollObserverOptions,
                                          },
                                          expression:
                                            "{\n\t\t\t\t\t\t\t\t\t\thandler: handleIntersect,\n\t\t\t\t\t\t\t\t\t\toptions: scrollObserverOptions,\n\t\t\t\t\t\t\t\t\t}",
                                        },
                                      ],
                                      staticStyle: { height: "1px" },
                                      attrs: {
                                        id: `proposal-wrapper-${index}`,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "builder-wrapper" },
                                      [
                                        !_vm.clientMode && _vm.isEditable
                                          ? _c(
                                              "div",
                                              { staticClass: "add-new-button" },
                                              [
                                                _c("div", {
                                                  staticClass: "add-new-rule",
                                                }),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "gray_20",
                                                      elevation: "0",
                                                      small: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addContentAt(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "16" } },
                                                      [_vm._v("$plus")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "ml-1 brand-semilight",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "proposal.content.add"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.clientMode &&
                                        _vm.isEditable &&
                                        _vm.editIndex === index
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "builder-control",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tippy",
                                                        rawName: "v-tippy",
                                                        value: {
                                                          content: "Move Up",
                                                        },
                                                        expression:
                                                          "{ content: 'Move Up' }",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "secondary-action",
                                                    attrs: {
                                                      icon: "",
                                                      text: "",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.shiftUp(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "20" } },
                                                      [_vm._v("$chevronUp")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tippy",
                                                        rawName: "v-tippy",
                                                        value: {
                                                          content: "Move Down",
                                                        },
                                                        expression:
                                                          "{ content: 'Move Down' }",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "secondary-action",
                                                    attrs: {
                                                      icon: "",
                                                      text: "",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.shiftDown(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "20" } },
                                                      [_vm._v("$chevronDown")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tippy",
                                                        rawName: "v-tippy",
                                                        value: {
                                                          content:
                                                            "Delete section",
                                                        },
                                                        expression:
                                                          "{ content: 'Delete section' }",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "secondary-action",
                                                    attrs: {
                                                      icon: "",
                                                      text: "",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.confirmRemoveSection(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "20" } },
                                                      [_vm._v("$delete")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          block.blockType,
                                          _vm._b(
                                            {
                                              key: block.id,
                                              ref: block.id,
                                              refInFor: true,
                                              tag: "component",
                                              attrs: {
                                                block: block,
                                                "edit-mode":
                                                  !_vm.clientMode &&
                                                  _vm.isEditable &&
                                                  _vm.editIndex === index,
                                                "template-mode":
                                                  _vm.templateMode,
                                                account:
                                                  _vm.proposal.accountMini,
                                                client: _vm.proposal.clientMini,
                                                "proposal-id": _vm.proposal.id,
                                                "client-id":
                                                  _vm.proposal.clientId,
                                                "mce-config-cover":
                                                  _vm.mceConfigCover,
                                                "mce-config-header":
                                                  _vm.mceConfigHeader,
                                                "mce-config-text":
                                                  _vm.mceConfigText,
                                                "deliverables-count":
                                                  _vm.deliverablesCount,
                                                "force-all-deliverables":
                                                  _vm.proposal
                                                    .forceAllDeliverables,
                                                "allow-multiple-deliverables":
                                                  _vm.proposal
                                                    .allowMultipleDeliverables,
                                                "deliverable-selected":
                                                  _vm.deliverableSelected,
                                                "proposal-service":
                                                  _vm.proposalService,
                                                "template-service":
                                                  _vm.templateService,
                                                "client-mode": _vm.clientMode,
                                                formatting:
                                                  _vm.proposal.formatting,
                                                "header-style": _vm.headerStyle,
                                                "body-style": _vm.bodyStyle,
                                                "is-locked": _vm.isLocked,
                                                "refresh-key": _vm.refreshKey,
                                                currency: _vm.proposal.currency,
                                                "proposal-type":
                                                  _vm.proposal.proposalType,
                                                "up-front-amount":
                                                  _vm.upFrontAmount,
                                                "payment-node-count":
                                                  _vm.paymentCount,
                                                "pending-charge":
                                                  _vm.proposal.pendingCharge,
                                                "card-payment-failed":
                                                  _vm.cardPaymentFailed,
                                              },
                                              on: {
                                                changed: function ($event) {
                                                  return _vm.handleContentBlockChange(
                                                    index,
                                                    $event
                                                  )
                                                },
                                                toggleMultipleDeliverables:
                                                  function ($event) {
                                                    return _vm.toggleMultipleDeliverables()
                                                  },
                                                "total-upfront-amount":
                                                  function ($event) {
                                                    return _vm.handleUpFrontAmount(
                                                      block,
                                                      $event
                                                    )
                                                  },
                                                "create-package-from-deliverables":
                                                  function ($event) {
                                                    return _vm.$emit(
                                                      "create-package-from-deliverables",
                                                      $event
                                                    )
                                                  },
                                                "request-them-sign": function (
                                                  $event
                                                ) {
                                                  return _vm.requestThemSign(
                                                    block
                                                  )
                                                },
                                              },
                                            },
                                            "component",
                                            block,
                                            false
                                          )
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      { attrs: { align: "center" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "content-block py-0 px-12",
                            attrs: { cols: "12" },
                          },
                          [
                            !_vm.clientMode && _vm.isEditable
                              ? _c(
                                  "div",
                                  { staticClass: "add-new-button" },
                                  [
                                    _c("div", { staticClass: "add-new-rule" }),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "gray_20",
                                          elevation: "0",
                                          small: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addContentAt(
                                              _vm.proposal.contentBlocks.length
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "16" } },
                                          [_vm._v("$plus")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ml-1 brand-semilight",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("proposal.content.add")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.removeSectionDialog, persistent: true },
          on: {
            close: function ($event) {
              return _vm.cancelRemoveSection()
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { id: "delete-section-modal" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-dialog",
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("$close")])],
                1
              ),
              _c("div", { staticClass: "modal-padding" }, [
                _c("div", { staticClass: "modal-title text-center" }, [
                  _c(
                    "span",
                    { staticClass: "pr-1" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mt-n1",
                          attrs: { color: "warning", size: "24" },
                        },
                        [_vm._v("$alert")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("global.confirm")) + " "),
                ]),
                _c("div", { staticClass: "modal-subtitle text-center mx-4" }, [
                  _vm._v(_vm._s(_vm.$t("proposal.delete.section"))),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "super-action mr-1",
                      staticStyle: { width: "120px" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelRemoveSection()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action ml-1",
                      staticStyle: { width: "120px" },
                      on: {
                        click: function ($event) {
                          return _vm.removeSection()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("global.delete")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.contentMenuOpen, persistent: true },
          on: {
            close: function ($event) {
              _vm.contentMenuOpen = false
            },
          },
        },
        [
          _c("add-component", {
            attrs: {
              "signature-count": _vm.signatureCount,
              "terms-count": _vm.termsCount,
              "accept-count": _vm.acceptCount,
              "payment-count": _vm.paymentCount,
            },
            on: {
              "add-section": _vm.addSection,
              close: function ($event) {
                _vm.contentMenuOpen = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }