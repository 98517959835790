var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "proposal-comments-menu" } },
    [
      !_vm.clientMode
        ? _c(
            "v-btn",
            {
              staticClass: "secondary-action activator-button",
              on: {
                click: function ($event) {
                  return _vm.openMenu()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "font-12 d-flex align-center" },
                [
                  _c("v-icon", { attrs: { small: "" } }, [_vm._v("$comment")]),
                  _c("div", { staticClass: "ml-2" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("proposal.comments.comments")) + " "
                    ),
                  ]),
                  _vm.hasUnread
                    ? _c("div", { staticClass: "unread-indicator" })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.clientMode
        ? _c(
            "v-btn",
            {
              staticClass: "primary-action",
              on: {
                click: function ($event) {
                  return _vm.openMenu()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "row-format align-center" },
                [
                  _c("v-icon", { attrs: { small: "" } }, [_vm._v("$comment")]),
                  _c("div", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(_vm.$t("proposals.leave-a-comment"))),
                  ]),
                  _vm.unReadCount
                    ? _c("div", { staticClass: "ml-1" }, [
                        _vm._v("(" + _vm._s(_vm.unReadCount) + " unread)"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.menu, persistent: false },
          on: {
            close: function ($event) {
              return _vm.closeMenu()
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { id: "proposal-comments-modal" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-dialog",
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeMenu()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("$close")])],
                1
              ),
              _c("div", { staticClass: "modal-padding" }, [
                _c(
                  "div",
                  { staticClass: "modal-title" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("proposals.comments")) + " "),
                    _c("v-chip", { attrs: { pill: "", "x-small": "" } }, [
                      _vm._v(_vm._s(_vm.comments.length)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { attrs: { id: "comments-wrapper" } },
                  _vm._l(_vm.comments, function (comment, index) {
                    return _c(
                      "div",
                      {
                        key: comment.id,
                        class:
                          "comment-wrapper" +
                          (_vm.canEdit(comment, index) ? " editable" : ""),
                        on: {
                          mouseover: function ($event) {
                            return _vm.setHoverIndex(index)
                          },
                          mouseleave: function ($event) {
                            return _vm.setHoverIndex(null)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "author-timestamp-buttons" }, [
                          _c("div", { staticClass: "author-timestamp" }, [
                            _c(
                              "div",
                              { staticClass: "author" },
                              [
                                comment.privateComment
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          size: "12",
                                          color: "var(--v-gray_90-base)",
                                        },
                                      },
                                      [_vm._v("$lock")]
                                    )
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(comment.author) + " "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "timestamp" },
                              [
                                _c("v-icon", { attrs: { size: "12" } }, [
                                  _vm._v("$clock"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatForTimeAgo")(
                                        comment.timestamp
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "edit-delete-buttons" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { "x-small": "", icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.editComment(comment, index)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { size: "16" } }, [
                                    _vm._v("$edit"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { "x-small": "", icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.confirmDelete(comment)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { size: "16" } }, [
                                    _vm._v("$delete"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.editIndex !== index
                          ? _c("div", {
                              staticClass: "comment",
                              domProps: { innerHTML: _vm._s(comment.comment) },
                            })
                          : _vm._e(),
                        _vm.editIndex === index
                          ? _c(
                              "div",
                              { staticClass: "comment" },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    rows: "1",
                                    "auto-grow": "",
                                    outlined: "",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.commentToEdit.comment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.commentToEdit,
                                        "comment",
                                        $$v
                                      )
                                    },
                                    expression: "commentToEdit.comment",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "foot" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticStyle: { width: "120px" },
                                        attrs: {
                                          color: "secondary-action mr-1",
                                          small: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelEdit()
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "primary-action ml-1",
                                        staticStyle: { width: "120px" },
                                        attrs: { small: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveEdit()
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("global.save")))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "modal-footer px-6 pb-5" }, [
                _c("div", { staticClass: "textarea-wrapper" }, [
                  _c(
                    "div",
                    [
                      _c("v-textarea", {
                        staticClass: "no-rules",
                        attrs: {
                          "persistent-placeholder": "",
                          "hide-details": "",
                          autofocus: true,
                          "auto-grow": "",
                          placeholder: _vm.$t("proposals.write-a-comment"),
                          rows: "1",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleEnter($event)
                          },
                        },
                        model: {
                          value: _vm.newComment,
                          callback: function ($$v) {
                            _vm.newComment = $$v
                          },
                          expression: "newComment",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vertical-rule" }),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "send-button",
                        on: {
                          click: function ($event) {
                            return _vm.sendComment()
                          },
                        },
                      },
                      [
                        _vm.willSendEmail
                          ? [_vm._v(" " + _vm._s(_vm.$t("global.send")) + " ")]
                          : [_vm._v(_vm._s(_vm.$t("global.save")))],
                      ],
                      2
                    ),
                  ]),
                ]),
                !_vm.clientMode
                  ? _c("div", { staticClass: "checkboxes mt-3" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.privateComment,
                              expression: "privateComment",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.privateComment)
                              ? _vm._i(_vm.privateComment, null) > -1
                              : _vm.privateComment,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.privateComment,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.privateComment = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.privateComment = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.privateComment = $$c
                              }
                            },
                          },
                        }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("proposals.private-comment"))),
                        ]),
                      ]),
                      !_vm.privateComment
                        ? _c("label", { staticClass: "ml-8" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sendEmail,
                                  expression: "sendEmail",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.sendEmail)
                                  ? _vm._i(_vm.sendEmail, null) > -1
                                  : _vm.sendEmail,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.sendEmail,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.sendEmail = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.sendEmail = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.sendEmail = $$c
                                  }
                                },
                              },
                            }),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("proposals.send-email"))),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm.confirmDeleteDialog
        ? _c("confirm-dialog", {
            attrs: {
              dialog: _vm.confirmDeleteDialog,
              "body-text": _vm.$t("proposal.comments.confirm-delete"),
            },
            on: {
              close: function ($event) {
                return _vm.cancelDelete()
              },
              confirm: function ($event) {
                return _vm.deleteComment()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }