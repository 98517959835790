var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%", "min-height": "100%" } },
    [
      _vm.schemaVersion === 1
        ? _c("form-v1", {
            ref: "form",
            attrs: {
              form: _vm.form,
              font: _vm.font,
              "in-frame": false,
              complete: _vm.isComplete,
            },
            on: {
              complete: function ($event) {
                return _vm.saveForm($event)
              },
            },
          })
        : _vm._e(),
      _vm.schemaVersion === 2
        ? _c("form-v2", {
            ref: "form",
            attrs: {
              form: _vm.form,
              font: _vm.font,
              "in-frame": false,
              complete: _vm.isComplete,
            },
            on: {
              complete: function ($event) {
                return _vm.saveForm($event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }