var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.isValid,
        callback: function ($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid",
      },
    },
    [
      _c("div", { staticClass: "column-format gap-2" }, [
        _vm.showBusiness || _vm.showWebsite
          ? _c(
              "div",
              {
                staticClass: "row-format gap-2",
                staticStyle: { "flex-wrap": "wrap" },
              },
              [
                _vm.showBusiness
                  ? _c("v-text-field", {
                      staticStyle: { "min-width": "250px" },
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        label: "Business / Organization name",
                        disabled: _vm.previewMode,
                        rules: _vm.required ? _vm.inputRequired : [],
                      },
                      on: { input: _vm.handleChange },
                      model: {
                        value: _vm.businessName,
                        callback: function ($$v) {
                          _vm.businessName = $$v
                        },
                        expression: "businessName",
                      },
                    })
                  : _vm._e(),
                _vm.showWebsite
                  ? _c("v-text-field", {
                      staticStyle: { "min-width": "250px" },
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        label: _vm.$t("contact.website"),
                        disabled: _vm.previewMode,
                        rules: _vm.required ? _vm.inputRequired : [],
                      },
                      on: { input: _vm.handleChange },
                      model: {
                        value: _vm.website,
                        callback: function ($$v) {
                          _vm.website = $$v
                        },
                        expression: "website",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.showContact
          ? _c(
              "div",
              {
                staticClass: "row-format gap-2",
                staticStyle: { "flex-wrap": "wrap" },
              },
              [
                _c("v-text-field", {
                  staticStyle: { "min-width": "250px" },
                  attrs: {
                    label: "First name",
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    rules: _vm.required ? _vm.inputRequired : [],
                    disabled: _vm.previewMode,
                  },
                  on: { input: _vm.handleChange },
                  model: {
                    value: _vm.firstName,
                    callback: function ($$v) {
                      _vm.firstName = $$v
                    },
                    expression: "firstName",
                  },
                }),
                _c("v-text-field", {
                  staticStyle: { "min-width": "250px" },
                  attrs: {
                    label: "Last name",
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    disabled: _vm.previewMode,
                    rules: _vm.required ? _vm.inputRequired : [],
                  },
                  on: { input: _vm.handleChange },
                  model: {
                    value: _vm.lastName,
                    callback: function ($$v) {
                      _vm.lastName = $$v
                    },
                    expression: "lastName",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.showEmail || _vm.showPhone
          ? _c(
              "div",
              {
                staticClass: "row-format gap-2",
                staticStyle: { "flex-wrap": "wrap" },
              },
              [
                _vm.showEmail
                  ? _c("v-text-field", {
                      staticStyle: { "min-width": "250px" },
                      attrs: {
                        label: "Email",
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        disabled: _vm.previewMode,
                        rules: _vm.required ? _vm.emailRequired : [],
                      },
                      on: { input: _vm.handleChange },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    })
                  : _vm._e(),
                _vm.showPhone
                  ? _c("v-text-field", {
                      staticStyle: { "min-width": "250px" },
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        label: "Phone",
                        disabled: _vm.previewMode,
                        rules: _vm.required ? _vm.inputRequired : [],
                      },
                      on: { input: _vm.handleChange },
                      model: {
                        value: _vm.phone,
                        callback: function ($$v) {
                          _vm.phone = $$v
                        },
                        expression: "phone",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.showAddress
          ? _c("div", { staticClass: "column-format gap-2" }, [
              _c(
                "div",
                {
                  staticClass: "row-format gap-2",
                  staticStyle: { "flex-wrap": "wrap" },
                },
                [
                  _c("v-text-field", {
                    staticStyle: { "min-width": "250px" },
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      label: _vm.$t("contact.address1"),
                      disabled: _vm.previewMode,
                      rules: _vm.required ? _vm.inputRequired : [],
                    },
                    on: { input: _vm.handleChange },
                    model: {
                      value: _vm.address1,
                      callback: function ($$v) {
                        _vm.address1 = $$v
                      },
                      expression: "address1",
                    },
                  }),
                  _c("v-text-field", {
                    staticStyle: { "min-width": "250px" },
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      label: _vm.$t("contact.address2"),
                      disabled: _vm.previewMode,
                    },
                    on: { input: _vm.handleChange },
                    model: {
                      value: _vm.address2,
                      callback: function ($$v) {
                        _vm.address2 = $$v
                      },
                      expression: "address2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "row-format gap-2",
                  staticStyle: { "flex-wrap": "wrap" },
                },
                [
                  _c("v-text-field", {
                    staticStyle: { "min-width": "250px" },
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      label: _vm.$t("contact.city"),
                      disabled: _vm.previewMode,
                      rules: _vm.required ? _vm.inputRequired : [],
                    },
                    on: { input: _vm.handleChange },
                    model: {
                      value: _vm.city,
                      callback: function ($$v) {
                        _vm.city = $$v
                      },
                      expression: "city",
                    },
                  }),
                  _c("v-text-field", {
                    staticStyle: { "min-width": "250px" },
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      label: _vm.$t("contact.locality"),
                      disabled: _vm.previewMode,
                      rules: _vm.required ? _vm.inputRequired : [],
                    },
                    on: { input: _vm.handleChange },
                    model: {
                      value: _vm.locality,
                      callback: function ($$v) {
                        _vm.locality = $$v
                      },
                      expression: "locality",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "row-format gap-2",
                  staticStyle: { "flex-wrap": "wrap" },
                },
                [
                  _c("v-text-field", {
                    staticStyle: { "min-width": "250px" },
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      label: _vm.$t("contact.postal"),
                      disabled: _vm.previewMode,
                      rules: _vm.required ? _vm.inputRequired : [],
                    },
                    on: { input: _vm.handleChange },
                    model: {
                      value: _vm.postal,
                      callback: function ($$v) {
                        _vm.postal = $$v
                      },
                      expression: "postal",
                    },
                  }),
                  _c(
                    "div",
                    {
                      style: _vm.$vuetify.breakpoint.smAndDown
                        ? "width: 100%"
                        : "width:49%",
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          disabled: _vm.previewMode,
                          label: _vm.$t("contact.country"),
                          items: _vm.countries.getCountriesSimple(),
                          "item-value": "code",
                          "item-text": "name",
                          rules: _vm.required ? _vm.inputRequired : [],
                        },
                        on: { input: _vm.handleChange },
                        model: {
                          value: _vm.country,
                          callback: function ($$v) {
                            _vm.country = $$v
                          },
                          expression: "country",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }