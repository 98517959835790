var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h-header", {
        scopedSlots: _vm._u([
          {
            key: "filter",
            fn: function () {
              return [
                _vm.view === "overview"
                  ? _c(
                      "div",
                      {
                        staticClass: "row-format",
                        staticStyle: { width: "200px!important" },
                      },
                      [
                        _c("v-select", {
                          staticClass: "ml-3",
                          attrs: {
                            attach: true,
                            dense: "",
                            "hide-details": "",
                            items: _vm.filterOptions,
                            placeholder: "Filter",
                          },
                          model: {
                            value: _vm.filter,
                            callback: function ($$v) {
                              _vm.filter = $$v
                            },
                            expression: "filter",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass: "mx-0 pt-4 show-scrollbar",
          style: `height: calc(100vh - ${_vm.headerHeight}px)`,
          attrs: { id: "project-container" },
        },
        [
          _vm.view === "overview"
            ? _c(
                "div",
                { staticClass: "mx-3" },
                _vm._l(_vm.filteredProjects, function (project) {
                  return _c("project", {
                    key: project.id,
                    staticClass: "project-wrapper",
                    attrs: { project: project, "status-list": _vm.statusList },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.view === "kanban" && _vm.fullReadProjects.length > 0
            ? _c(
                "div",
                [
                  _c("project-kanban", {
                    attrs: {
                      "assignable-users": _vm.assignableUsers,
                      "has-full-access-projects":
                        _vm.fullAccessProjects.length > 0,
                      deliverables: _vm.deliverables,
                      "is-collaborator": true,
                      columns: _vm.statusList,
                      "header-height": _vm.headerHeight,
                    },
                    on: {
                      "edit-deliverable": function ($event) {
                        return _vm.editDeliverable($event)
                      },
                      "update-kanban-props": function ($event) {
                        return _vm.updateKanbanProperties($event)
                      },
                      "add-deliverable": function ($event) {
                        return _vm.addDeliverable($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.view === "timeline" && _vm.fullReadProjects.length > 0
            ? _c(
                "div",
                {
                  staticClass: "show-scrollbar mr-3",
                  staticStyle: { height: "100%" },
                },
                [
                  _c("gantt-view", {
                    attrs: {
                      "assignable-users": _vm.assignableUsers,
                      deliverables: _vm.deliverables,
                      "status-list": _vm.statusList,
                      "header-height": _vm.headerHeight,
                    },
                    on: {
                      "edit-deliverable": function ($event) {
                        return _vm.editDeliverable($event)
                      },
                      "dates-updated": function ($event) {
                        return _vm.datesUpdated($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.view === "calendar" && _vm.fullReadProjects.length > 0
            ? _c(
                "div",
                {
                  staticClass: "show-scrollbar mr-3",
                  staticStyle: { height: "100%" },
                },
                [
                  _c("project-calendar", {
                    attrs: {
                      projects: _vm.filteredProjects,
                      deliverables: _vm.deliverables,
                      "status-list": _vm.statusList,
                    },
                    on: {
                      "edit-deliverable": function ($event) {
                        return _vm.editDeliverable($event)
                      },
                      "update-due-date": function ($event) {
                        return _vm.datesUpdated($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }