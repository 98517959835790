var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "notfound" } }, [
      _c("div", { staticClass: "notfound" }, [
        _c("div", { staticClass: "notfound-404" }, [
          _c("h3", [_vm._v("Oops! Page not found")]),
          _c("h1", [
            _c("span", [_vm._v("4")]),
            _c("span", [_vm._v("0")]),
            _c("span", [_vm._v("4")]),
          ]),
        ]),
        _c("h2", [
          _vm._v("we are sorry, but the page you requested was not found"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }