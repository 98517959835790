var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "font-14" },
    _vm._l(_vm.timerEvents, function (item) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "pb-2",
          staticStyle: { "line-height": "1.3em" },
        },
        [
          _c("span", [
            _vm._v(_vm._s(item.timeWorked) + " • " + _vm._s(item.dateDisplay)),
          ]),
          item.deliverableName
            ? _c("span", [_vm._v("• " + _vm._s(item.deliverableName) + " ")])
            : _vm._e(),
          item.notes
            ? _c("span", { staticStyle: { "white-space": "pre-wrap" } }, [
                _vm._v("• " + _vm._s(item.notes) + " "),
              ])
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }