var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.dialog,
        width: "unset",
        persistent: true,
        "overlay-color": _vm.overlayColor,
        "overlay-opacity": _vm.overlayOpacity,
        fullscreen: _vm.fullscreen,
        "content-class": "basic-modal",
      },
      on: {
        "click:outside": function ($event) {
          !_vm.persistent ? _vm.handleClose() : ""
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }