var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "pa-0 ma-0 selector_text proposal-text-area",
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { style: _vm.headerStyle, attrs: { cols: "12" } },
            [
              !_vm.editMode
                ? _c("span", { domProps: { innerHTML: _vm._s(_vm.lHeader) } })
                : _vm._e(),
              _vm.editMode
                ? _c("editor", {
                    ref: "headerEditor",
                    staticClass: "grey-dots",
                    attrs: {
                      "api-key": _vm.$store.getters.getTinyMceKey,
                      "initial-value": _vm.lHeader,
                      inline: true,
                      init: _vm.mceConfigHeader,
                    },
                    on: { input: _vm.handleHeaderInput },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticStyle: { "text-align": "left" }, attrs: { cols: "12" } },
            [
              _c(
                "span",
                { style: _vm.bodyStyle },
                [
                  !_vm.editMode
                    ? _c("span", { domProps: { innerHTML: _vm._s(_vm.lText) } })
                    : _vm._e(),
                  _vm.editMode
                    ? _c("editor", {
                        ref: "textEditor",
                        staticStyle: { padding: "4px" },
                        attrs: {
                          iniline: true,
                          "api-key": _vm.$store.getters.getTinyMceKey,
                          "initial-value": _vm.lText,
                          init: _vm.mceConfigText,
                        },
                        on: { input: _vm.handleTextInput },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }