var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isConditionMet
    ? _c(
        "div",
        {
          staticClass: "text-left",
          on: {
            click: function ($event) {
              return _vm.$emit("click", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-question" },
            [
              _vm._v(_vm._s(_vm.item.question) + " "),
              _vm.item.required
                ? _c("span", { staticClass: "font-red brand-medium" }, [
                    _vm._v("*"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showConditionalHelper
                ? _c("v-icon", { attrs: { small: "", color: "gray_50" } }, [
                    _vm._v("account_tree"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
              model: {
                value: _vm.isValid,
                callback: function ($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid",
              },
            },
            [
              _c(
                "v-radio-group",
                {
                  key: _vm.refreshKey,
                  staticClass: "mt-0",
                  attrs: {
                    "hide-details": "",
                    dense: "",
                    row: !_vm.item.formatVertically,
                    rules: _vm.item.required ? _vm.radioInputRequired : [],
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleChange()
                    },
                  },
                  model: {
                    value: _vm.selection,
                    callback: function ($$v) {
                      _vm.selection = $$v
                    },
                    expression: "selection",
                  },
                },
                _vm._l(_vm.item.options, function (option, index) {
                  return _c("v-radio", {
                    key: index,
                    staticClass: "form-checkbox form-input",
                    attrs: {
                      disabled: _vm.disabled,
                      value: option,
                      label: option,
                      dense: "",
                      "hide-details": "",
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }