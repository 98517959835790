var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.assignedUser
      ? _c(
          "div",
          {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { content: _vm.firstName + " " + _vm.lastName },
                expression: "{ content: firstName + ' ' + lastName }",
              },
            ],
            staticClass: "row-format align-center",
          },
          [
            _vm.assignedUser.profilePicture
              ? _c("v-avatar", { attrs: { size: "25" } }, [
                  _c("img", {
                    staticClass: "pic",
                    attrs: { src: _vm.assignedUser.profilePicture },
                  }),
                ])
              : _c("v-avatar", { attrs: { size: "25", color: "primary" } }, [
                  _c("span", { staticStyle: { color: "white" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.firstName.substr(0, 1).toUpperCase() +
                          _vm.lastName.substr(0, 1).toUpperCase()
                      )
                    ),
                  ]),
                ]),
            _vm.showName
              ? _c("div", { staticClass: "ml-2 assigned-user-name ellipsis" }, [
                  _vm._v(_vm._s(_vm.firstName) + " " + _vm._s(_vm.lastName)),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }