var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h-header"),
      _c("div", { staticClass: "pa-4", staticStyle: { height: "var(--vh)" } }, [
        _c(
          "div",
          {
            staticClass: "mt-4 row-format",
            staticStyle: { "flex-wrap": "wrap" },
          },
          [
            _vm._l(_vm.schedulers, function (scheduler) {
              return _c(
                "div",
                { key: scheduler.id, staticClass: "scheduler" },
                [
                  _c("div", { staticClass: "brand-medium mb-2" }, [
                    _vm._v(_vm._s(scheduler.name)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "font-14 mb-2",
                      staticStyle: { "text-transform": "capitalize" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("meetings.duration")) +
                          " " +
                          _vm._s(scheduler.meetingLength.duration) +
                          " " +
                          _vm._s(
                            scheduler.meetingLength.timeUnit.toLowerCase()
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action mt-auto",
                      attrs: { large: "" },
                      on: {
                        click: function ($event) {
                          return _vm.bookNow(scheduler)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("meetings.schedule.book-now")) + " ")]
                  ),
                ],
                1
              )
            }),
            _vm.schedulers.length === 0
              ? _c("div", { staticClass: "scheduler column-format centered" }, [
                  _c("div", [_vm._v(_vm._s(_vm.$t("meetings.no-schedulers")))]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }