var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentProjectLabel
    ? _c(
        "div",
        { staticClass: "item-project-selector" },
        [
          !_vm.disabled
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "nudge-bottom": 0,
                    elevation: 0,
                    "content-class": "project-menu",
                    bottom: "",
                    left: "",
                    rounded: "",
                    "offset-overflow": "",
                    "offset-y": "",
                    transition: "slide-y-transition",
                    disabled: _vm.disabled,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                { staticClass: "activator-label-wrapper" },
                                on
                              ),
                              [
                                _c(
                                  "div",
                                  {
                                    class:
                                      "d-flex justify-start activator-label " +
                                      _vm.showHide,
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "mr-1 material-symbols-outlined",
                                        attrs: { size: "16" },
                                      },
                                      [_vm._v("folder_special")]
                                    ),
                                    _c("div", { staticClass: "font-12" }, [
                                      _vm._v(_vm._s(_vm.currentProjectLabel)),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3203402993
                  ),
                },
                [
                  _c(
                    "div",
                    { staticClass: "project-menu-items" },
                    [
                      _vm._l(_vm.projects, function (project, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class: _vm.menuItemClass(project),
                            on: {
                              click: function ($event) {
                                return _vm.selectProject(project)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(project.name) + " ")]
                        )
                      }),
                      _vm.item.projectId
                        ? _c(
                            "div",
                            { staticClass: "menu-item" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary-action ml-n2 mt-n1",
                                  staticStyle: { width: "140px" },
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearSelection()
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { size: "14" } }, [
                                    _vm._v("$close"),
                                  ]),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "invoice.clear-project-selection"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            : _c(
                "div",
                { class: "d-flex justify-start my-1" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-outlined",
                      attrs: { size: "16" },
                    },
                    [_vm._v("folder_special")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "font-12",
                      staticStyle: {
                        "margin-left": "2px",
                        cursor: "default",
                        color: "var(--v-gray_90-base)",
                      },
                    },
                    [_vm._v(_vm._s(_vm.currentProjectLabel))]
                  ),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }