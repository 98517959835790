var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "100%",
        "background-color": "#fff",
      },
    },
    [
      _vm.podId
        ? _c("iframe", {
            staticStyle: { border: "none", "background-color": "#fff" },
            attrs: { src: _vm.fullPath, height: "99%", width: "100%" },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }