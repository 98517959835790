var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ticket
    ? _c(
        "div",
        { staticClass: "mx-3" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-left", attrs: { cols: "12" } },
                    [
                      _c("div", { staticClass: "font-20" }, [
                        _vm._v(
                          _vm._s(_vm.ticket.ticketNumber) +
                            ": " +
                            _vm._s(_vm.ticket.subject)
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-left order-last order-md-first",
                      attrs: { cols: "12", md: "8" },
                    },
                    [
                      _c("v-textarea", {
                        attrs: {
                          rows: "8",
                          label: _vm.$t("requests.comment"),
                          dense: "",
                          outlined: "",
                          "persistent-placeholder": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.comment,
                          callback: function ($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment",
                        },
                      }),
                      _c(
                        "div",
                        {
                          on: {
                            drop: function ($event) {
                              $event.preventDefault()
                              return _vm.addDropFile.apply(null, arguments)
                            },
                            dragover: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c("v-file-input", {
                            attrs: {
                              placeholder: _vm.$t(
                                "requests.upload-attachments"
                              ),
                              label: _vm.$t("requests.attachments"),
                              multiple: "",
                              "hide-details": "",
                              "prepend-icon": "attachment",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function ({ text }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticStyle: {
                                            color: "var(--v-white-base)",
                                          },
                                          attrs: {
                                            small: "",
                                            label: "",
                                            color: "primary",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(text) + " ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2620837592
                            ),
                            model: {
                              value: _vm.attachments,
                              callback: function ($$v) {
                                _vm.attachments = $$v
                              },
                              expression: "attachments",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "row-format my-5",
                          staticStyle: { gap: "12px" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary-action",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("cancel")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "super-action",
                              attrs: { disabled: !_vm.comment, width: "200" },
                              on: {
                                click: function ($event) {
                                  return _vm.addComment()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("requests.add-comment")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-container",
                        { staticClass: "pa-0 ma-0" },
                        _vm._l(_vm.comments, function (comment) {
                          return _c(
                            "v-col",
                            {
                              key: comment.id,
                              staticClass: "text-left",
                              staticStyle: {
                                "border-top": "1px solid var(--v-gray_30-base)",
                              },
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "font-12 font-gray_70" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getCommentBy(comment.commentBy)
                                      ) +
                                      " " +
                                      _vm._s(_vm.$t("global.on")) +
                                      " " +
                                      _vm._s(
                                        _vm.DateTime.fromISO(
                                          comment.created
                                        ).toLocaleString(
                                          _vm.DateTime.DATETIME_FULL
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              comment.updated
                                ? _c(
                                    "div",
                                    { staticClass: "font-12 font-gray_70" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("requests.existing.updated")
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.DateTime.fromISO(
                                              comment.updated
                                            ).toLocaleString(
                                              _vm.DateTime.DATETIME_FULL
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              comment.commentFormat === "PlainText"
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "white-space": "pre-wrap",
                                      },
                                    },
                                    [_vm._v(_vm._s(comment.comment))]
                                  )
                                : _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(comment.comment),
                                    },
                                  }),
                              comment.attachments && comment.attachments.length
                                ? _c(
                                    "div",
                                    { staticClass: "py-2" },
                                    _vm._l(
                                      comment.attachments,
                                      function (attachment) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: attachment.fileName,
                                            staticClass: "pointer",
                                            on: {
                                              click: function ($event) {
                                                return _vm.downloadFile(
                                                  attachment
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("attachment")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(attachment.fileName) +
                                                " "
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-container",
                        {
                          staticClass: "ma-0 font-14",
                          staticStyle: {
                            border: "1px solid var(--v-gray_50-base)",
                            "border-radius": "4px",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4", align: "left" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("requests.existing.requester")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8", align: "right" } },
                                [
                                  _vm.requester
                                    ? _c(
                                        "span",
                                        { staticClass: "brand-medium" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.requester.firstName) +
                                              " " +
                                              _vm._s(_vm.requester.lastName)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4", align: "left" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("requests.existing.type"))
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8", align: "right" } },
                                [
                                  _c("span", { staticClass: "brand-medium" }, [
                                    _vm._v(_vm._s(_vm.ticketType.type)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4", align: "left" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("requests.existing.status"))
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8", align: "right" } },
                                [
                                  _vm.ticket.open
                                    ? _c("div", { staticClass: "status" }, [
                                        _vm._v(_vm._s(_vm.ticket.status)),
                                      ])
                                    : _c("div", { staticClass: "closed" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("requests.closed"))
                                        ),
                                      ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4", align: "left" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("requests.existing.created"))
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8", align: "right" } },
                                [
                                  _c("span", { staticClass: "brand-medium" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.DateTime.fromISO(
                                          _vm.ticket.created
                                        ).toLocaleString(
                                          _vm.DateTime.DATETIME_MED
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4", align: "left" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("requests.existing.updated"))
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8", align: "right" } },
                                [
                                  _c("span", { staticClass: "brand-medium" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.DateTime.fromISO(
                                          _vm.ticket.updated
                                        ).toLocaleString(
                                          _vm.DateTime.DATETIME_MED
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ticket.formData && _vm.ticket.formData.answers.length
                        ? _c("div", { staticStyle: { height: "16px" } })
                        : _vm._e(),
                      _vm.ticket.formData && _vm.ticket.formData.answers.length
                        ? _c(
                            "v-container",
                            {
                              staticClass: "ma-0 pa-0 font-14",
                              staticStyle: {
                                border: "1px solid var(--v-gray_50-base)",
                                "border-radius": "4px",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pa-4" },
                                    _vm._l(
                                      _vm.ticket.formData.answers,
                                      function (answer, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "text-left font-14 pb-3",
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "font-12 font-gray_70",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  answer.question
                                                ),
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "text-pre-wrap" },
                                              [_vm._v(_vm._s(answer.answer))]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }