var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "proposal-details" } }, [
    _vm.proposal
      ? _c("div", { attrs: { id: "header-area" } }, [
          _c("div", { staticClass: "header-left pl-md-6 pl-4" }, [
            _c(
              "div",
              { staticClass: "mr-6 brand-medium" },
              [
                _c("h-icon2", {
                  staticClass: "mr-4",
                  attrs: { name: "proposals", active: true, size: "24" },
                }),
                _vm._v(" " + _vm._s(_vm.proposal.coverPage.heading) + " "),
              ],
              1
            ),
            _c(
              "div",
              [
                _vm.proposal
                  ? _c("comments-menu", {
                      staticClass: "mr-1",
                      attrs: {
                        comments: _vm.proposal.comments,
                        "proposal-id": _vm.proposal.id,
                        "client-id": _vm.proposal.clientId,
                        "proposal-service": _vm.proposalService,
                        "client-mode": true,
                        "viewed-timestamps": _vm.proposal.viewedTimestamps,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "header-right pr-md-6 pr-4" }, [
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.handleClose()
                      },
                    },
                  },
                  [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
                  1
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "show-scrollbar",
        staticStyle: { "max-height": "calc(100vh - 50px)" },
      },
      [
        _vm.isReady
          ? _c("proposal-renderer", {
              attrs: {
                proposal: _vm.proposal,
                account: _vm.account,
                "proposal-service": _vm.proposalService,
                "edit-mode": false,
                "pod-service": null,
                "client-service": null,
                "need-save": false,
                saving: false,
                "client-mode": true,
                "is-locked": _vm.isLocked,
                "card-payment-failed": _vm.cardPaymentFailed,
              },
              on: {
                "deliverable-selected": function ($event) {
                  return _vm.deliverableSelected($event)
                },
                "client-signed": function ($event) {
                  return _vm.clientFinalized($event)
                },
                "client-accepted": function ($event) {
                  return _vm.clientFinalized($event)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }