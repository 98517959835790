var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0 selector_image", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _vm.editMode
            ? _c(
                "v-col",
                { attrs: { cols: "12", align: "center" } },
                [
                  _vm.signedUrl !== null
                    ? _c("v-slider", {
                        staticStyle: { width: "400px" },
                        attrs: { min: "0", max: "100", "thumb-label": "" },
                        on: { end: _vm.sliderChanged },
                        model: {
                          value: _vm.lScale,
                          callback: function ($$v) {
                            _vm.lScale = $$v
                          },
                          expression: "lScale",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("div", { staticClass: "baseContainer" }, [
              _c(
                "div",
                {
                  staticClass: "imageContainer",
                  style: _vm.calculatedImageWidth,
                },
                [
                  _vm.templateMode || (_vm.editMode && _vm.signedUrl !== null)
                    ? _c(
                        "div",
                        { staticClass: "deleteButton" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "deleteIcon",
                              attrs: { small: "", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDeleteFile()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v("$delete"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.signedUrl !== null
                    ? _c("img", {
                        staticClass: "image",
                        attrs: { src: _vm.signedUrl },
                      })
                    : _vm._e(),
                  _vm.editMode && _vm.lFile === null
                    ? _c(
                        "vue-dropzone",
                        {
                          ref: "fileDropzone",
                          attrs: {
                            id: "dropzone",
                            "use-custom-slot": true,
                            options: _vm.dropzoneOptions,
                          },
                          on: {
                            "vdropzone-success": _vm.uploadSuccess,
                            "vdropzone-error": _vm.uploadFailure,
                            "vdropzone-sending": _vm.addFileUploadAuthHeader,
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("v-icon", [_vm._v("$vuetify.icons.upload")]),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-2",
                                  staticStyle: { "font-size": "0.8em" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("proposal.image.upload"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.confirmDelete,
            callback: function ($$v) {
              _vm.confirmDelete = $$v
            },
            expression: "confirmDelete",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "brand-regular",
              staticStyle: { "background-color": "var(--v-white-base)" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-dialog",
                  attrs: { icon: "", rounded: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.cancelDeleteFile()
                    },
                  },
                },
                [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$close")])],
                1
              ),
              _c("div", { staticClass: "modal-padding" }, [
                _c("div", { staticClass: "modal-title text-center" }, [
                  _c(
                    "span",
                    { staticClass: "pr-1" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mt-n1",
                          attrs: { color: "warning", size: "24" },
                        },
                        [_vm._v("$alert")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("global.confirm")) + " "),
                ]),
                _c("div", { staticClass: "modal-subtitle text-center mx-3" }, [
                  _vm._v(_vm._s(_vm.$t("proposal.image.delete"))),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1 super-action",
                      attrs: { width: "120" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelDeleteFile()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-1 primary-action",
                      attrs: { width: "120" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteFile()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("global.delete")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }