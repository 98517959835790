var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "pa-0 ma-0 selector_signature",
      staticStyle: { "text-align": "left" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "span",
              { style: _vm.headerStyle },
              [
                !_vm.editMode
                  ? _c("span", { domProps: { innerHTML: _vm._s(_vm.lHeader) } })
                  : _vm._e(),
                _vm.editMode
                  ? _c("editor", {
                      ref: "headerEditor",
                      staticClass: "grey-dots",
                      attrs: {
                        "api-key": _vm.$store.getters.getTinyMceKey,
                        "initial-value": _vm.lHeader,
                        inline: true,
                        init: _vm.mceConfigHeader,
                      },
                      on: { input: _vm.handleHeaderInput },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { style: _vm.fontStyle },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c("span", { staticClass: "label-wrapper font-14" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.account
                          ? _vm.account.accountName
                          : _vm.templateAccountName
                      )
                  ),
                ]),
              ]),
              _c("signature", {
                ref: "us",
                attrs: {
                  align: "center",
                  signature: _vm.us,
                  mode: "us",
                  "edit-mode": _vm.editMode,
                  "client-mode": _vm.clientMode,
                  "template-mode": _vm.templateMode,
                  "deliverable-selected": _vm.areDeliverablesSelected,
                },
                on: { signed: _vm.signedUs },
              }),
              _c(
                "div",
                {
                  staticClass: "column-format",
                  staticStyle: { "text-align": "left" },
                },
                [
                  _c("div", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(_vm._s(_vm.us.signerName)),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.us.signerTitle))]),
                ]
              ),
              _vm.us.ip.ip
                ? _c("div", { staticClass: "ipBlock" }, [
                    _vm._v(" " + _vm._s(_vm.$t("proposals.country")) + ": "),
                    _c("img", {
                      attrs: { src: _vm.us.ip.countryFlag, width: "15px" },
                    }),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("proposals.ip")) +
                        ": " +
                        _vm._s(_vm.us.ip.ip)
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("proposals.location")) +
                        ": " +
                        _vm._s(_vm.us.ip.city) +
                        ", " +
                        _vm._s(_vm.us.ip.region) +
                        ", " +
                        _vm._s(_vm.us.ip.country)
                    ),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm.us.dts
                ? _c("div", { staticClass: "ipBlock" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.DateTime.fromISO(_vm.us.dts).toLocaleString(
                            _vm.DateTime.DATETIME_FULL
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c("span", { staticClass: "label-wrapper truncate font-14" }, [
                  _vm._v(
                    _vm._s(
                      _vm.client ? _vm.client.name : _vm.templateClientName
                    )
                  ),
                ]),
                _vm.settingSigner === true
                  ? _c(
                      "div",
                      { staticStyle: { "margin-left": "auto" } },
                      [
                        _c(
                          "v-icon",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: { small: "", color: "green" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.saveSignerInfo()
                              },
                            },
                          },
                          [_vm._v("$success")]
                        ),
                        _c(
                          "v-icon",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: { small: "", color: "grey" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.cancelSignerInfo()
                              },
                            },
                          },
                          [_vm._v("$cancel ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("signature", {
                ref: "them",
                attrs: {
                  align: "center",
                  signature: _vm.them,
                  mode: "them",
                  "edit-mode": _vm.editMode,
                  "client-mode": _vm.clientMode,
                  "template-mode": _vm.templateMode,
                  "deliverable-selected": _vm.areDeliverablesSelected,
                },
                on: {
                  signed: _vm.signedThem,
                  "signer-info": _vm.signerInfoUpdated,
                  "set-signer": function ($event) {
                    _vm.settingSigner = true
                  },
                  "request-them-sign": _vm.requestThemSign,
                },
              }),
              !_vm.settingSigner && (_vm.themSignerName || _vm.themSignerTitle)
                ? _c(
                    "div",
                    {
                      staticClass: "column-format",
                      staticStyle: { "text-align": "left" },
                    },
                    [
                      _c("div", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(this.them.signerName)),
                      ]),
                      _c("div", [_vm._v(_vm._s(this.them.signerTitle))]),
                    ]
                  )
                : _vm._e(),
              _vm.them.ip.ip
                ? _c("div", { staticClass: "ipBlock" }, [
                    _vm._v(" " + _vm._s(_vm.$t("proposals.country")) + " "),
                    _c("img", {
                      attrs: { src: _vm.them.ip.countryFlag, width: "15px" },
                    }),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("proposals.ip")) +
                        ": " +
                        _vm._s(_vm.them.ip.ip)
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("proposals.location")) +
                        ": " +
                        _vm._s(_vm.them.ip.city) +
                        ", " +
                        _vm._s(_vm.them.ip.region) +
                        ", " +
                        _vm._s(_vm.them.ip.country)
                    ),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm.them.dts
                ? _c("div", { staticClass: "ipBlock" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.DateTime.fromISO(_vm.them.dts).toLocaleString(
                            _vm.DateTime.DATETIME_FULL
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.cardPaymentFailed
                ? _c(
                    "v-btn",
                    {
                      staticClass: "my-3",
                      attrs: { large: "", block: "", color: "primary" },
                      on: { click: _vm.requestThemSign },
                    },
                    [_vm._v("Resubmit Payment")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }