var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-2" },
    [
      _c("pie-chart", {
        staticStyle: { height: "450px" },
        attrs: { "chart-data": _vm.chartData, options: _vm.options },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }