var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        (_vm.isPreview ? "preview" : "") +
        (_vm.logo ? " with-logo" : " without-logo") +
        " " +
        _vm.getHeightClass(),
      style: _vm.backgroundImage,
      attrs: { id: "cover-wrapper" },
    },
    [
      _c("div", { class: _vm.coverTextClass }, [
        _vm.headingPosition === "Prominent"
          ? _c(
              "h1",
              { class: _vm.headingClass, style: _vm.headerStyle },
              [
                _c("editor", {
                  ref: "headerEditor",
                  attrs: {
                    disabled: !_vm.editMode || _vm.clientMode,
                    "api-key": _vm.$store.getters.getTinyMceKey,
                    "initial-value": _vm.headingInitial,
                    inline: true,
                    init: _vm.mceConfigHeader,
                    placeholder: _vm.$t(
                      "proposals.cover-page.heading-placeholder"
                    ),
                  },
                  on: { input: _vm.handleHeaderInput },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            class:
              "subheader " +
              (_vm.logo ? "with-logo" : "without-logo") +
              (_vm.isPreview ? " preview" : ""),
          },
          [
            _c("div", { staticClass: "logo-box" }, [
              _c(
                "div",
                {
                  staticClass: "logo-box-inner",
                  style: `background-image: url(${_vm.logo});`,
                },
                [
                  _c("img", {
                    staticClass: "account-logo",
                    staticStyle: { visibility: "hidden" },
                    attrs: { src: _vm.logo, alt: "Placeholder for sizing" },
                  }),
                ]
              ),
            ]),
            _c(
              "h2",
              { class: _vm.headingClass, style: _vm.preparedStyle },
              [
                _c("editor", {
                  ref: "customBy",
                  attrs: {
                    disabled: !_vm.editMode || _vm.clientMode,
                    "api-key": _vm.$store.getters.getTinyMceKey,
                    "initial-value": _vm.customByInitial,
                    inline: true,
                    init: _vm.mceConfigHeader,
                    placeholder: _vm.$t("proposals.cover-page.by-placeholder"),
                  },
                  on: { onblur: _vm.handleByInput },
                }),
                _c("editor", {
                  ref: "customFor",
                  attrs: {
                    disabled: !_vm.editMode || _vm.clientMode,
                    "api-key": _vm.$store.getters.getTinyMceKey,
                    "initial-value": _vm.customForInitial,
                    inline: true,
                    init: _vm.mceConfigHeader,
                    placeholder: _vm.$t("proposals.cover-page.for-placeholder"),
                  },
                  on: { onblur: _vm.handleForInput },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      !_vm.isPreview && _vm.editMode
        ? _c(
            "div",
            { staticClass: "edit-button" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action",
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cover-modal")
                    },
                  },
                },
                [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$edit")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }