var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "row-format gap-2 pt-2",
          style: `justify-content: ${_vm.justification}`,
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "elevation-0",
              style: `font-size: var(--form-font-size) !important; ${
                _vm.buttonProps.block ? "width: 49%!important;" : ""
              }`,
              attrs: {
                color: _vm.styles.buttonColor,
                "x-small": _vm.buttonProps.xSmall,
                small: _vm.buttonProps.small,
                large: _vm.buttonProps.large,
                "x-large": _vm.buttonProps.xLarge,
                disabled: _vm.currentPage === 1,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("previous-page")
                },
              },
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-1",
                  attrs: { color: _vm.styles.buttonTextColor },
                },
                [_vm._v(_vm._s(_vm.styles.prevPageIcon))]
              ),
              _c(
                "span",
                {
                  style: `font-family: ${_vm.font}; ; text-transform: none; color: ${_vm.styles.buttonTextColor}`,
                },
                [_vm._v(_vm._s(_vm.styles.prevPageText))]
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "elevation-0",
              style: `font-size: var(--form-font-size) !important; ${
                _vm.buttonProps.block ? "width: 49%!important;" : ""
              }`,
              attrs: {
                "x-small": _vm.buttonProps.xSmall,
                small: _vm.buttonProps.small,
                large: _vm.buttonProps.large,
                "x-large": _vm.buttonProps.xLarge,
                color: _vm.styles.buttonColor,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("next-page")
                },
              },
            },
            [
              _c(
                "span",
                {
                  style: `font-family: ${_vm.font}; ; text-transform: none; color: ${_vm.styles.buttonTextColor}`,
                },
                [_vm._v(_vm._s(_vm.styles.nextPageText))]
              ),
              _c(
                "v-icon",
                {
                  staticClass: "ml-1",
                  attrs: { color: _vm.styles.buttonTextColor },
                },
                [_vm._v(_vm._s(_vm.styles.nextPageIcon))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }