var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-input" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
          model: {
            value: _vm.isValid,
            callback: function ($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid",
          },
        },
        [
          _c("label", {
            staticClass: "question",
            domProps: { innerHTML: _vm._s(_vm.htmlText) },
          }),
          _c("v-textarea", {
            attrs: {
              type: "text",
              outlined: "",
              dense: "",
              "hide-details": "",
              "persistent-placeholder": "",
              placeholder: _vm.placeholder,
              rules: _vm.required ? _vm.inputRequired : [],
              rows: parseInt(_vm.rows, 10),
              "auto-grow": "",
              disabled: _vm.previewMode,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }