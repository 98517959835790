var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row-format",
      staticStyle: { height: "100%", width: "100%", "max-height": "100%" },
    },
    [
      _c(
        "v-container",
        {
          key: _vm.forceRenderIndex,
          staticClass: "px-0 px-md-4 mt-0",
          staticStyle: {
            width: "100%",
            height: "100%",
            "max-height": "100%",
            "overflow-y": "auto",
            "background-color": "var(--v-gray_20-base)",
          },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: "12", id: "invoice-wrapper" } }, [
                _vm.invoice && _vm.account
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            ref: "invoice-renderer",
                            style: `width:${
                              _vm.$vuetify.breakpoint.width > 1000
                                ? 1000
                                : _vm.$vuetify.breakpoint.width
                            }px`,
                            attrs: { id: "invoice-renderer" },
                          },
                          [
                            _c("div", { attrs: { id: "header" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "column-format text-left",
                                  style: _vm.$vuetify.breakpoint.smAndDown
                                    ? "flex: 0 0 100%;"
                                    : "flex: 0 0 50%;",
                                },
                                [
                                  _vm.documentHeader
                                    ? _c(
                                        "div",
                                        { staticClass: "font-20 inter-medium" },
                                        [_vm._v(_vm._s(_vm.documentHeader))]
                                      )
                                    : _vm._e(),
                                  _vm.logo
                                    ? _c("div", {
                                        class: _vm.editable
                                          ? "pointer text-left"
                                          : "text-left",
                                        attrs: { id: "logo" },
                                        domProps: {
                                          innerHTML: _vm._s(_vm.logo),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.editable
                                              ? _vm.$emit("account-info-modal")
                                              : null
                                          },
                                        },
                                      })
                                    : _vm.editable
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            attrs: { id: "add-logo" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$emit(
                                                  "account-info-modal"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mt-n1",
                                                    attrs: {
                                                      size: "32",
                                                      left: "",
                                                    },
                                                  },
                                                  [_vm._v("$ribbon")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-12 text-left",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "invoice.upload-logo"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                { attrs: { id: "details" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: _vm.$vuetify.breakpoint.xsOnly
                                        ? "column-format"
                                        : "row-format",
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "div",
                                          {
                                            class:
                                              "mr-5 font-20 inter-medium" +
                                              (_vm.editable ? " pointer" : ""),
                                            staticStyle: {
                                              "letter-spacing": "-0.15px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.editable
                                                  ? _vm.$emit(
                                                      "invoice-number-format-modal"
                                                    )
                                                  : null
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoice.invoice-number-appended",
                                                    {
                                                      invoiceNumber: _vm.invoice
                                                        .invoiceNumberFormatted
                                                        ? _vm.invoice
                                                            .invoiceNumberFormatted
                                                        : _vm.invoice
                                                            .invoiceNumberFormattedPreview,
                                                    }
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          class:
                                            (_vm.$vuetify.breakpoint.smAndUp
                                              ? "ml-auto"
                                              : "mt-2") +
                                            " font-20 inter-semibold text-right",
                                          staticStyle: {
                                            "letter-spacing": "-0.18px",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$formatters.dollars(
                                                  _vm.invoice.total,
                                                  true,
                                                  true,
                                                  _vm.invoice.currency
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm.editMode &&
                                          _vm.invoice.currency !==
                                            _vm.$store.state.defaultCurrency
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-12 font-gray_70",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$formatters.dollars(
                                                          _vm.invoice.localTotal
                                                            ? _vm.invoice
                                                                .localTotal
                                                            : 0,
                                                          true,
                                                          true,
                                                          _vm.$store.state
                                                            .defaultCurrency
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.invoice.purchaseOrder
                                    ? _c(
                                        "div",
                                        {
                                          class:
                                            "font-16" +
                                            (_vm.editable ? " pointer" : ""),
                                          on: {
                                            click: function ($event) {
                                              _vm.editable
                                                ? _vm.handlePurchaseOrderClick()
                                                : null
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "invoice.details.purchase-order-display",
                                                  {
                                                    purchaseOrder:
                                                      _vm.invoice.purchaseOrder,
                                                  }
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm.editable
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "payment-link mb-0 mt-1",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlePurchaseOrderClick()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "brand-regular" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "invoice.details.purchase-order-button"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.editable || _vm.recurring,
                                        "close-on-content-click": true,
                                        "close-on-click": true,
                                        "min-width": 250,
                                        "max-width": 300,
                                        "nudge-bottom": 28,
                                        "nudge-right": 0,
                                        bottom: "",
                                        left: "",
                                        origin: "bottom left",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    {
                                                      class:
                                                        "due-date mt-4" +
                                                        (_vm.editable &&
                                                        !_vm.recurring
                                                          ? " editable"
                                                          : ""),
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-space-between",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "inter-semibold gray_80--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "invoice.details.issued"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm.recurring
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "font-14 font-gray_80 mt-1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " [" +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "invoice.details.recurring-disclaimer"
                                                                        )
                                                                      ) +
                                                                      "] "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "date-due-display",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$DateTime.shortDate(
                                                                      _vm
                                                                        .invoice
                                                                        .dateSent
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "ml-1",
                                                          },
                                                          [
                                                            _vm.editable &&
                                                            !_vm.recurring
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      rounded:
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "$edit"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        265804599
                                      ),
                                    },
                                    [
                                      _c("v-date-picker", {
                                        staticClass:
                                          "invoice-renderer-date-picker",
                                        attrs: {
                                          "first-day-of-week":
                                            _vm.$store.getters.firstDayOfWeek,
                                          value: _vm.invoice.dateSent,
                                          "no-title": true,
                                        },
                                        on: {
                                          input: function ($event) {
                                            _vm.invoice.dateSent = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        disabled:
                                          !_vm.editable || _vm.recurring,
                                        "close-on-content-click": true,
                                        "close-on-click": true,
                                        "min-width": 250,
                                        "max-width": 300,
                                        "nudge-bottom": 28,
                                        "nudge-right": 0,
                                        bottom: "",
                                        left: "",
                                        origin: "bottom left",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    {
                                                      class:
                                                        "due-date mt-4" +
                                                        (_vm.editable &&
                                                        !_vm.recurring
                                                          ? " editable"
                                                          : ""),
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-space-between",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "inter-semibold gray_80--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "invoice.details.due"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm.recurring
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "font-14 brand-medium font-primary mt-1 pointer",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.handlePaymentTermsClick.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm.paymentDays >
                                                                  0
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.paymentDays
                                                                              ) +
                                                                              " days after invoice is sent "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "On receipt"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  class:
                                                                    _vm.dateDueWarning +
                                                                    " date-due-display",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$DateTime.shortDate(
                                                                          _vm.dateDue
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _vm.invoice
                                                                    .status ===
                                                                    "DRAFT" &&
                                                                  _vm.invoice
                                                                    .dateDue
                                                                    ? _c(
                                                                        "v-btn",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "tippy",
                                                                                rawName:
                                                                                  "v-tippy",
                                                                                value:
                                                                                  {
                                                                                    content:
                                                                                      "Revert to default due date",
                                                                                  },
                                                                                expression:
                                                                                  "{ content: 'Revert to default due date' }",
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                _vm.invoice.dateDue =
                                                                                  null
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "$close"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "ml-1",
                                                          },
                                                          [
                                                            _vm.editable &&
                                                            !_vm.recurring
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      icon: "",
                                                                      rounded:
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "$edit"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3042489266
                                      ),
                                      model: {
                                        value: _vm.dateDueMenu,
                                        callback: function ($$v) {
                                          _vm.dateDueMenu = $$v
                                        },
                                        expression: "dateDueMenu",
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        staticClass:
                                          "invoice-renderer-date-picker",
                                        attrs: {
                                          "first-day-of-week":
                                            _vm.$store.getters.firstDayOfWeek,
                                          value: _vm.dateDue,
                                          "no-title": true,
                                        },
                                        on: {
                                          input: function ($event) {
                                            _vm.invoice.dateDue = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                class: _vm.$vuetify.breakpoint.xsOnly
                                  ? "column-format"
                                  : "d-flex justify-space-between",
                                attrs: { id: "subheader" },
                              },
                              [
                                _vm.invoice.accountInfo
                                  ? _c(
                                      "div",
                                      {
                                        class: _vm.editable ? "editable" : "",
                                        attrs: { id: "freelancer-address" },
                                        on: {
                                          click: function ($event) {
                                            _vm.editable
                                              ? _vm.$emit("account-info-modal")
                                              : ""
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "inter-medium" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("invoice.from"))
                                                ),
                                              ]
                                            ),
                                            _vm.editable
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-n1" },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          rounded: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "account-info-modal"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("$edit"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.invoice.accountInfo
                                                .accountName
                                            )
                                          ),
                                        ]),
                                        _vm.invoice.accountInfo.address1
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.invoice.accountInfo
                                                    .address1
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.invoice.accountInfo.address2
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.invoice.accountInfo
                                                    .address2
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.accountInfoCityStateZip
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.accountInfoCityStateZip
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.invoice.accountInfo.country
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.countries.codeToName(
                                                      _vm.invoice.accountInfo
                                                        .country
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.invoice.accountInfo.phone
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.invoice.accountInfo.phone
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.invoice.accountInfo.taxId
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.invoice.accountInfo
                                                      .taxId
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    class: _vm.editable ? "editable" : "",
                                    attrs: { id: "bill-to-details" },
                                    on: {
                                      click: function ($event) {
                                        _vm.editable
                                          ? _vm.$emit("client-info-modal")
                                          : ""
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "inter-medium" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("invoice.bill-to")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.editable
                                          ? _c(
                                              "div",
                                              { staticClass: "mt-n1" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      rounded: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$emit(
                                                          "client-info-modal"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("$edit"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.invoice.clientInfo.name)
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.invoice.clientInfo.address1)
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.invoice.clientInfo.address2)
                                      ),
                                    ]),
                                    _vm.clientInfoCityStateZip
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(_vm.clientInfoCityStateZip)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.invoice.clientInfo.country
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.countries.codeToName(
                                                  _vm.invoice.clientInfo.country
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.invoice.clientInfo.phone
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(_vm.invoice.clientInfo.phone)
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.invoice.clientInfo.taxId
                                      ? _c("div", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.invoice.clientInfo.taxId
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _vm.invoice
                              ? _c("v-simple-table", {
                                  staticStyle: {
                                    "background-color": "var(--v-white-base)",
                                  },
                                  attrs: { id: "invoice-items-table" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function () {
                                          return [
                                            _c("thead", [
                                              _c(
                                                "tr",
                                                _vm._l(
                                                  _vm.itemHeaders,
                                                  function (item, i) {
                                                    return _c(
                                                      "th",
                                                      {
                                                        key: i,
                                                        class:
                                                          item.class +
                                                          (_vm.editable
                                                            ? " editable"
                                                            : ""),
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.text) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]),
                                            _c(
                                              "tbody",
                                              [
                                                _vm._l(
                                                  _vm.invoice.items,
                                                  function (item, i) {
                                                    return [
                                                      (_vm.editable &&
                                                        item.type ===
                                                          "ADHOC") ||
                                                      item.type === "EXPENSE"
                                                        ? [
                                                            _c(
                                                              "tr",
                                                              {
                                                                key: i,
                                                                staticClass:
                                                                  "text-left",
                                                              },
                                                              [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "trash",
                                                                  },
                                                                  [
                                                                    _vm.editable
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row-format align-center",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "column-format ml-3",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        small:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.moveUp(
                                                                                            i
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "$arrowUp"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        small:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.moveDown(
                                                                                            i
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "$arrowDown"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: "",
                                                                                    small:
                                                                                      "",
                                                                                    rounded:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.handleDeleteItemDialog(
                                                                                        i
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        size: "20",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "$delete"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "description editable",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-textarea",
                                                                      {
                                                                        staticClass:
                                                                          "pause-save item-description",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-bottom":
                                                                              "-10px",
                                                                          },
                                                                        attrs: {
                                                                          rows: "1",
                                                                          "auto-grow":
                                                                            "",
                                                                          dense:
                                                                            "",
                                                                          id: `item-description-${i}`,
                                                                        },
                                                                        on: {
                                                                          focus:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.$emit(
                                                                                "pause-auto-save",
                                                                                true
                                                                              )
                                                                            },
                                                                          blur: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$emit(
                                                                              "pause-auto-save",
                                                                              false
                                                                            )
                                                                          },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .invoice
                                                                              .items[
                                                                              i
                                                                            ]
                                                                              .description,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .invoice
                                                                                  .items[
                                                                                  i
                                                                                ],
                                                                                "description",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "invoice.items[i].description",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "project-select",
                                                                      {
                                                                        staticClass:
                                                                          "mt-2",
                                                                        attrs: {
                                                                          clientId:
                                                                            _vm
                                                                              .invoice
                                                                              .clientId,
                                                                          item: item,
                                                                          editable:
                                                                            _vm.editable,
                                                                        },
                                                                        on: {
                                                                          "select-project":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.assignProjectToItem(
                                                                                $event,
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "qty",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        ref: "qty",
                                                                        refInFor: true,
                                                                        staticClass:
                                                                          "pause-save text-hover-blue",
                                                                        attrs: {
                                                                          type: "number",
                                                                          min: "0",
                                                                          dense:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          focus:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.manualQtyFocus(
                                                                                i
                                                                              )
                                                                            },
                                                                          blur: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.manualQtyBlur(
                                                                              i
                                                                            )
                                                                          },
                                                                          keyup:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.itemChange()
                                                                            },
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.itemChange()
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .invoice
                                                                              .items[
                                                                              i
                                                                            ]
                                                                              .qty,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .invoice
                                                                                  .items[
                                                                                  i
                                                                                ],
                                                                                "qty",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "invoice.items[i].qty",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "price",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        staticClass:
                                                                          "pause-save text-hover-blue",
                                                                        attrs: {
                                                                          type: "number",
                                                                          dense:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          focus:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.manualPriceFocus(
                                                                                i
                                                                              )
                                                                            },
                                                                          blur: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.manualPriceBlur(
                                                                              i
                                                                            )
                                                                          },
                                                                          keyup:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.itemChange()
                                                                            },
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.itemChange()
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .invoice
                                                                              .items[
                                                                              i
                                                                            ]
                                                                              .price,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .invoice
                                                                                  .items[
                                                                                  i
                                                                                ],
                                                                                "price",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "invoice.items[i].price",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.taxEnabled
                                                                  ? _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "taxable",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-simple-checkbox",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                dense:
                                                                                  "",
                                                                                right:
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.itemChange(
                                                                                    true
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .invoice
                                                                                    .items[
                                                                                    i
                                                                                  ]
                                                                                    .taxable,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .invoice
                                                                                        .items[
                                                                                        i
                                                                                      ],
                                                                                      "taxable",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "invoice.items[i].taxable",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "subtotal",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$formatters.dollars(
                                                                            _vm
                                                                              .invoice
                                                                              .items[
                                                                              i
                                                                            ]
                                                                              .subtotal,
                                                                            true,
                                                                            true,
                                                                            _vm
                                                                              .invoice
                                                                              .currency
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : [
                                                            _c(
                                                              "tr",
                                                              {
                                                                key: i,
                                                                class:
                                                                  item.type ===
                                                                    "HOURS" ||
                                                                  item.type ===
                                                                    "OVERAGE"
                                                                    ? "pointer"
                                                                    : "",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleItemDetailsClick(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "trash",
                                                                  },
                                                                  [
                                                                    _vm.editable
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row-format align-center",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "column-format ml-3",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        small:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.moveUp(
                                                                                            i
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "$arrowUp"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        small:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.moveDown(
                                                                                            i
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "$arrowDown"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm.editable &&
                                                                            item.type !==
                                                                              "DEPOSIT" &&
                                                                            item.type !==
                                                                              "OVERAGE"
                                                                              ? _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "",
                                                                                        small:
                                                                                          "",
                                                                                        rounded:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.handleDeleteItemDialog(
                                                                                            i
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            size: "20",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "$delete"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                                _vm.editable &&
                                                                item.type !==
                                                                  "OVERAGE"
                                                                  ? _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "description editable",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-textarea",
                                                                          {
                                                                            staticClass:
                                                                              "pause-save item-description",
                                                                            staticStyle:
                                                                              {
                                                                                "margin-bottom":
                                                                                  "-10px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                rows: "1",
                                                                                "auto-grow":
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.itemChange()
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .invoice
                                                                                    .items[
                                                                                    i
                                                                                  ]
                                                                                    .description,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .invoice
                                                                                        .items[
                                                                                        i
                                                                                      ],
                                                                                      "description",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "invoice.items[i].description",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row-format mt-2",
                                                                            staticStyle:
                                                                              {
                                                                                gap: "4px",
                                                                              },
                                                                          },
                                                                          [
                                                                            item.type ===
                                                                            "DEPOSIT"
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "font-12",
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "var(--v-black-base)",
                                                                                        cursor:
                                                                                          "default",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " Deposit "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _c(
                                                                              "project-select",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    clientId:
                                                                                      _vm
                                                                                        .invoice
                                                                                        .clientId,
                                                                                    item: item,
                                                                                    editable:
                                                                                      _vm.editable,
                                                                                    prefix:
                                                                                      item.type ===
                                                                                      "DEPOSIT"
                                                                                        ? " / "
                                                                                        : null,
                                                                                  },
                                                                                on: {
                                                                                  "select-project":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.assignProjectToItem(
                                                                                        $event,
                                                                                        item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                            item.retainerPeriod
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "font-12",
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "var(--v-black-base)",
                                                                                        cursor:
                                                                                          "default",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.DateTime.fromISO(
                                                                                            item
                                                                                              .retainerPeriod
                                                                                              .start
                                                                                          ).toLocaleString(
                                                                                            _vm
                                                                                              .DateTime
                                                                                              .DATE_MED
                                                                                          )
                                                                                        ) +
                                                                                        " - " +
                                                                                        _vm._s(
                                                                                          _vm.DateTime.fromISO(
                                                                                            item
                                                                                              .retainerPeriod
                                                                                              .end
                                                                                          ).toLocaleString(
                                                                                            _vm
                                                                                              .DateTime
                                                                                              .DATE_MED
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "description",
                                                                        staticStyle:
                                                                          {
                                                                            "min-width":
                                                                              "200px !important",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "item-description my-1",
                                                                            staticStyle:
                                                                              {
                                                                                "white-space":
                                                                                  "pre-wrap",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.description
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row-format align-center",
                                                                            staticStyle:
                                                                              {
                                                                                gap: "4px",
                                                                              },
                                                                          },
                                                                          [
                                                                            item.type ===
                                                                            "DEPOSIT"
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "font-12",
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "var(--v-black-base)",
                                                                                        cursor:
                                                                                          "default",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " Deposit "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _c(
                                                                              "project-select",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    clientId:
                                                                                      _vm
                                                                                        .invoice
                                                                                        .clientId,
                                                                                    item: item,
                                                                                    editable:
                                                                                      _vm.editable,
                                                                                    prefix:
                                                                                      item.type ===
                                                                                      "DEPOSIT"
                                                                                        ? " / "
                                                                                        : null,
                                                                                  },
                                                                                on: {
                                                                                  "select-project":
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.assignProjectToItem(
                                                                                        $event,
                                                                                        item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                            item.retainerPeriod
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "font-12",
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "var(--v-black-base)",
                                                                                        cursor:
                                                                                          "default",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.DateTime.fromISO(
                                                                                            item
                                                                                              .retainerPeriod
                                                                                              .start
                                                                                          ).toLocaleString(
                                                                                            _vm
                                                                                              .DateTime
                                                                                              .DATE_MED
                                                                                          )
                                                                                        ) +
                                                                                        " - " +
                                                                                        _vm._s(
                                                                                          _vm.DateTime.fromISO(
                                                                                            item
                                                                                              .retainerPeriod
                                                                                              .end
                                                                                          ).toLocaleString(
                                                                                            _vm
                                                                                              .DateTime
                                                                                              .DATE_MED
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            item
                                                                              .timerEventIds
                                                                              .length &&
                                                                            _vm.allowDetailedInvoice
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "font-12 font-primary nowrap pointer",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "invoice.details.show-hours"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                item.type ===
                                                                  "DELIVERABLE" &&
                                                                !item.product
                                                                  .hourly &&
                                                                _vm.editable
                                                                  ? _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "qty editable",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            ref: "qty",
                                                                            refInFor: true,
                                                                            staticClass:
                                                                              "pause-save text-hover-blue",
                                                                            attrs:
                                                                              {
                                                                                type: "number",
                                                                                min: "0",
                                                                                dense:
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              focus:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.manualQtyFocus(
                                                                                    i
                                                                                  )
                                                                                },
                                                                              blur: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.manualQtyBlur(
                                                                                  i
                                                                                )
                                                                              },
                                                                              keyup:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.itemChange()
                                                                                },
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.itemChange()
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .invoice
                                                                                    .items[
                                                                                    i
                                                                                  ]
                                                                                    .qty,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .invoice
                                                                                        .items[
                                                                                        i
                                                                                      ],
                                                                                      "qty",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "invoice.items[i].qty",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "td",
                                                                      {
                                                                        class:
                                                                          "qty" +
                                                                          (_vm.clickableQty(
                                                                            item
                                                                          )
                                                                            ? " editable pointer"
                                                                            : ""),
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handleQtyClick(
                                                                                i
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$formatters.decimal(
                                                                                  _vm
                                                                                    .invoice
                                                                                    .items[
                                                                                    i
                                                                                  ]
                                                                                    .qty,
                                                                                  0,
                                                                                  3
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    class:
                                                                      "price" +
                                                                      (_vm.clickableFee(
                                                                        item
                                                                      )
                                                                        ? " editable"
                                                                        : ""),
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handlePriceClick(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$formatters.dollars(
                                                                            _vm
                                                                              .invoice
                                                                              .items[
                                                                              i
                                                                            ]
                                                                              .price,
                                                                            true,
                                                                            _vm.editable
                                                                              ? false
                                                                              : true,
                                                                            _vm
                                                                              .invoice
                                                                              .currency
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.taxEnabled
                                                                  ? _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "taxable",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-simple-checkbox",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !_vm.editable,
                                                                                dense:
                                                                                  "",
                                                                                right:
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.itemChange(
                                                                                    true
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .invoice
                                                                                    .items[
                                                                                    i
                                                                                  ]
                                                                                    .taxable,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm
                                                                                        .invoice
                                                                                        .items[
                                                                                        i
                                                                                      ],
                                                                                      "taxable",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "invoice.items[i].taxable",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "subtotal",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$formatters.dollars(
                                                                            _vm
                                                                              .invoice
                                                                              .items[
                                                                              i
                                                                            ]
                                                                              .subtotal,
                                                                            true,
                                                                            true,
                                                                            _vm
                                                                              .invoice
                                                                              .currency
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                    ]
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3777519586
                                  ),
                                })
                              : _vm._e(),
                            _vm.editMode &&
                            (_vm.totalLateFee || _vm.invoice.lateFeeWaived) &&
                            _vm.invoice.daysPastDue > 0
                              ? _c(
                                  "div",
                                  {
                                    class:
                                      "calculated text-left" +
                                      (_vm.invoice.lateFeeWaived
                                        ? " waived"
                                        : ""),
                                    attrs: { id: "late-payment" },
                                  },
                                  [
                                    _c("div", { staticClass: "left" }),
                                    _vm.invoice.lateFeeWaived &&
                                    _vm.invoice.status === "PAID"
                                      ? _c(
                                          "div",
                                          { staticClass: "center" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoice.details.late-payment-fee-waived-paid",
                                                    {
                                                      lateFee:
                                                        _vm.$formatters.dollars(
                                                          _vm.totalLateFee,
                                                          true,
                                                          true,
                                                          _vm.invoice.currency
                                                        ),
                                                      days: _vm.invoice
                                                        .daysPastDue,
                                                      lateFeePercentage: _vm
                                                        .invoice.paymentTerms
                                                        ? _vm.invoice
                                                            .paymentTerms
                                                            .latePaymentFee
                                                        : 0,
                                                    }
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm.editMode &&
                                            _vm.invoice.status !== "PAID"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: _vm.waiveLateFee,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.details.apply-late-fee"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm.invoice.lateFeeWaived
                                      ? _c(
                                          "div",
                                          { staticClass: "center" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoice.details.late-payment-fee-waived",
                                                    {
                                                      lateFee:
                                                        _vm.$formatters.dollars(
                                                          _vm.totalLateFee,
                                                          true,
                                                          true,
                                                          _vm.invoice.currency
                                                        ),
                                                      days: _vm.invoice
                                                        .daysPastDue,
                                                      lateFeePercentage: _vm
                                                        .invoice.paymentTerms
                                                        ? _vm.invoice
                                                            .paymentTerms
                                                            .latePaymentFee
                                                        : 0,
                                                    }
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm.editMode &&
                                            _vm.invoice.status !== "PAID"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: _vm.waiveLateFee,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.details.apply-late-fee"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "center" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoice.details.late-payment-fee-added",
                                                    {
                                                      lateFee:
                                                        _vm.$formatters.dollars(
                                                          _vm.totalLateFee,
                                                          true,
                                                          true,
                                                          _vm.invoice.currency
                                                        ),
                                                      days: _vm.invoice
                                                        .daysPastDue,
                                                      lateFeePercentage: _vm
                                                        .invoice.paymentTerms
                                                        ? _vm.invoice
                                                            .paymentTerms
                                                            .latePaymentFee
                                                        : 0,
                                                    }
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm.editMode &&
                                            _vm.invoice.status !== "PAID"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: _vm.waiveLateFee,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.details.waive-late-fee"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                  ]
                                )
                              : _vm.totalLateFee && _vm.invoice.daysPastDue > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "applied text-left",
                                    attrs: { id: "late-payment" },
                                  },
                                  [
                                    _c("div", { staticClass: "left" }),
                                    _c("div", { staticClass: "center" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "invoice.details.late-payment-fee-added",
                                              {
                                                lateFee:
                                                  _vm.$formatters.dollars(
                                                    _vm.totalLateFee,
                                                    true,
                                                    true,
                                                    _vm.invoice.currency
                                                  ),
                                                days: _vm.invoice.daysPastDue,
                                                lateFeePercentage: _vm.invoice
                                                  .paymentTerms
                                                  ? _vm.invoice.paymentTerms
                                                      .latePaymentFee
                                                  : 0,
                                              }
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "right" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$formatters.dollars(
                                              _vm.totalLateFee,
                                              true,
                                              true,
                                              _vm.invoice.currency
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.editable &&
                            _vm.invoice.invoiceType === "STANDARD"
                              ? [
                                  _c(
                                    "div",
                                    { attrs: { id: "add-a-new-line" } },
                                    [
                                      _c(
                                        "div",
                                        { attrs: { id: "product-container" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              staticStyle: {
                                                "overflow-y": "auto",
                                              },
                                              attrs: {
                                                "max-height": "90vh",
                                                "max-width": "900",
                                                attach: "#add-a-new-line",
                                                "close-on-content-click": false,
                                                "close-on-click": true,
                                                "content-class":
                                                  "filter-menu-class",
                                                origin: "top center",
                                                transition: "scale-transition",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "pointer row-format align-center",
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      size: "20",
                                                                      left: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "$plusCircle"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "brand-regular",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "invoice.details.add-a-new-line-item"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                437715740
                                              ),
                                              model: {
                                                value: _vm.showProductsMenu,
                                                callback: function ($$v) {
                                                  _vm.showProductsMenu = $$v
                                                },
                                                expression: "showProductsMenu",
                                              },
                                            },
                                            [
                                              _c("products", {
                                                attrs: {
                                                  currency:
                                                    _vm.invoice.currency,
                                                  "client-id":
                                                    _vm.invoice.clientId,
                                                },
                                                on: {
                                                  close: function ($event) {
                                                    _vm.showProductsMenu = false
                                                  },
                                                  select: function ($event) {
                                                    return _vm.handleSelectProduct(
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  !_vm.recurring
                                    ? _c(
                                        "div",
                                        { attrs: { id: "add-a-new-line2" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleAddNewTrackedItemModal()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "20",
                                                    left: "",
                                                  },
                                                },
                                                [_vm._v("$kanban")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "brand-regular",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "invoice.details.add-line-item-from-billable"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.hasExpenses && !_vm.recurring
                                    ? _c(
                                        "div",
                                        { attrs: { id: "add-a-new-expense" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleAddExpenses()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "20",
                                                    left: "",
                                                  },
                                                },
                                                [_vm._v("$plusCircle")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "brand-regular",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "invoice.details.add-expenses"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.balanceExists &&
                                  !_vm.invoice.creditAmount &&
                                  !_vm.recurring
                                    ? _c(
                                        "div",
                                        { attrs: { id: "apply-deposit" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleApplyDeposit()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "20",
                                                    left: "",
                                                  },
                                                },
                                                [_vm._v("$money")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "brand-regular",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "invoice.details.apply-deposit-credit"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _c(
                              "div",
                              { attrs: { id: "totals" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "line" },
                                  [
                                    _vm.invoice.invoiceType === "STANDARD"
                                      ? [
                                          _c("div", { staticClass: "left" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoice.details.subtotal"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("div", { staticClass: "center" }),
                                          _c("div", { staticClass: "right" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$formatters.dollars(
                                                    _vm.invoice.itemSubtotal,
                                                    true,
                                                    true,
                                                    _vm.invoice.currency
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm.invoice.convenienceFee
                                  ? _c("div", { staticClass: "line" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "invoice.details.convenience-fee"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "center" }),
                                      _c("div", { staticClass: "right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$formatters.dollars(
                                                _vm.invoice.convenienceFee,
                                                true,
                                                true,
                                                _vm.invoice.currency
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.totalLateFee
                                  ? _c("div", { staticClass: "line" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("invoice.details.late-fee")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "center" }),
                                      _c("div", { staticClass: "right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$formatters.dollars(
                                                _vm.totalLateFee,
                                                true,
                                                true,
                                                _vm.invoice.currency
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.invoice.invoiceType !== "DEPOSIT" &&
                                _vm.invoice.taxRateLocked === false
                                  ? [
                                      _vm.editable ||
                                      _vm.invoice.discountPercentage
                                        ? _c("div", { staticClass: "line" }, [
                                            _c("div", { staticClass: "left" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "invoice.details.discount"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                class:
                                                  "center" +
                                                  (_vm.editable
                                                    ? " editable"
                                                    : ""),
                                              },
                                              [
                                                _vm.editable
                                                  ? [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          min: 0,
                                                          max: 100,
                                                          suffix: "%",
                                                          dense: "",
                                                          rules: [
                                                            () =>
                                                              _vm.invoice
                                                                .discountPercentage >=
                                                                0 ||
                                                              "Must be 0 or more",
                                                            () =>
                                                              _vm.invoice
                                                                .discountPercentage <=
                                                                100 ||
                                                              "Must be 100 or less",
                                                          ],
                                                        },
                                                        on: {
                                                          change: _vm.forceSave,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.invoice
                                                              .discountPercentage,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.invoice,
                                                              "discountPercentage",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "invoice.discountPercentage",
                                                        },
                                                      }),
                                                    ]
                                                  : _vm.invoice
                                                      .discountPercentage
                                                  ? [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$formatters.decimal(
                                                              _vm.invoice
                                                                .discountPercentage
                                                            ) + "%"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "right" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$formatters.dollars(
                                                        0 -
                                                          _vm.invoice
                                                            .discountAmount,
                                                        true,
                                                        true,
                                                        _vm.invoice.currency
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                                _vm.taxEnabled
                                  ? _c("div", { staticClass: "line" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _vm._v(
                                          " " + _vm._s(_vm.taxLabel) + " "
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          class:
                                            "center" +
                                            (_vm.editable ? " editable" : ""),
                                        },
                                        [
                                          _vm.editable
                                            ? [
                                                _vm.invoice.taxRateLocked &&
                                                _vm.additionalTaxRates.length
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              "close-on-content-click": true,
                                                              "close-on-click": true,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "pointer font-gray_70",
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$formatters.decimal(
                                                                                  _vm
                                                                                    .invoice
                                                                                    .taxPercentage
                                                                                ) +
                                                                                  "%"
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              3070340451
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "column-format pa-3 font-14 gap-2",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "var(--v-white-base)",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.additionalTaxRates,
                                                                function (
                                                                  taxRate
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: taxRate.id,
                                                                      staticClass:
                                                                        "row-format gap-3 pointer",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$emit(
                                                                              "tax-rate-selected",
                                                                              taxRate
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              taxRate.name
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "ml-auto",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              taxRate.rate
                                                                            ) +
                                                                              "%"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            type: "number",
                                                            disabled:
                                                              _vm.invoice
                                                                .taxRateLocked,
                                                            min: 0,
                                                            max: 100,
                                                            step: "1",
                                                            dense: "",
                                                            suffix: "%",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.forceSave,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.invoice
                                                                .taxPercentage,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.invoice,
                                                                "taxPercentage",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "invoice.taxPercentage",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$formatters.decimal(
                                                        _vm.invoice
                                                          .taxPercentage
                                                      ) + "%"
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                      _c("div", { staticClass: "right" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$formatters.dollars(
                                                _vm.invoice.taxAmount,
                                                true,
                                                true,
                                                _vm.invoice.currency
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.invoice.creditAmount
                                  ? _c(
                                      "div",
                                      { staticClass: "line credit-applied" },
                                      [
                                        _c("div", { staticClass: "left" }, [
                                          _c(
                                            "div",
                                            { staticClass: "mr-1" },
                                            [
                                              _vm.editable
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        rounded: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.$emit(
                                                            "apply-deposit-modal"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("$edit"),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "invoice.details.credit-amount"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("div", { staticClass: "center" }),
                                        _c("div", { staticClass: "right" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$formatters.dollars(
                                                  0 - _vm.invoice.creditAmount,
                                                  true,
                                                  true,
                                                  _vm.invoice.currency
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "line total" }, [
                                  _c("div", { staticClass: "left" }),
                                  _vm.invoice.status === "DRAFT" ||
                                  _vm.invoice.status === "SENT"
                                    ? _c("div", { staticClass: "center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "invoice.details.total-due"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("div", { staticClass: "center" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("invoice.details.total")
                                          )
                                        ),
                                      ]),
                                  _c("div", { staticClass: "right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatters.dollars(
                                            _vm.invoice.total,
                                            true,
                                            true,
                                            _vm.invoice.currency
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "line total" }, [
                                  _c("div", { staticClass: "left" }),
                                  _c("div", { staticClass: "center font-14" }, [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-weight": "300" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("invoice.all-amounts")
                                          ) +
                                            " " +
                                            _vm._s(_vm.invoice.currency)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm.invoice.status === "PARTIAL" ||
                                _vm.invoice.status === "PAID" ||
                                _vm.invoice.status === "PENDING"
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "line balance-due" },
                                        [
                                          _c("div", { staticClass: "left" }),
                                          _c("div", { staticClass: "center" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "invoice.details.payments-applied"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("div", { staticClass: "right" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$formatters.dollars(
                                                    _vm.invoice.paymentTotal,
                                                    true,
                                                    true,
                                                    _vm.invoice.currency
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "line balance-due mb-3",
                                        },
                                        [
                                          _c("div", { staticClass: "left" }),
                                          _c("div", { staticClass: "center" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "invoice.details.balance-due"
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("div", { staticClass: "right" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$formatters.dollars(
                                                    _vm.invoice.amountDue,
                                                    true,
                                                    true,
                                                    _vm.invoice.currency
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm.invoice.payOnlineUrl &&
                            !_vm.editable &&
                            _vm.invoice.amountDue
                              ? _c(
                                  "div",
                                  { staticClass: "pay-button" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "primary-action",
                                        attrs: { width: 191 },
                                        on: { click: _vm.handlePaymentAction },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("invoice.details.pay-button")
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "text-left",
                                attrs: { id: "footer" },
                              },
                              [
                                _vm.invoice.notes || _vm.editable
                                  ? _c("div", [
                                      _c("h3", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("invoice.details.notes")
                                          )
                                        ),
                                      ]),
                                      _c("div", {
                                        style: `${
                                          _vm.editable
                                            ? "border: 1px solid var(--v-gray_30-base); padding:4px"
                                            : ""
                                        }`,
                                        attrs: {
                                          contenteditable: _vm.editable,
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(_vm.notes),
                                        },
                                        on: { input: _vm.handleNotesChange },
                                      }),
                                    ])
                                  : _vm._e(),
                                _vm.invoice.payInstructions || _vm.editable
                                  ? _c("div", { staticClass: "my-5" }, [
                                      _c("h3", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "invoice.details.payment-instructions"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("div", {
                                        style: `${
                                          _vm.editable
                                            ? "border: 1px solid var(--v-gray_30-base); padding:4px"
                                            : ""
                                        }`,
                                        attrs: {
                                          contenteditable: _vm.editable,
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.payInstructions
                                          ),
                                        },
                                        on: {
                                          input:
                                            _vm.handlePayInstructionsChange,
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                                _vm.editable ||
                                _vm.invoice.attachments.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "my-8" },
                                      [
                                        _c("file-attachments", {
                                          attrs: {
                                            files: _vm.invoice.attachments,
                                            "file-service":
                                              _vm.attachmentService,
                                            "edit-mode": _vm.editable,
                                            "previews-supported": false,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.invoice.paymentTerms || _vm.editable
                                  ? _c(
                                      "div",
                                      { staticClass: "my-5" },
                                      [
                                        _vm.termsSentence
                                          ? [
                                              _c("div", {
                                                class:
                                                  "body-14 font-italic " +
                                                  (_vm.editable
                                                    ? "pointer"
                                                    : ""),
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.termsSentence
                                                  ),
                                                },
                                                on: {
                                                  click:
                                                    _vm.handlePaymentTermsClick,
                                                },
                                              }),
                                            ]
                                          : _vm.editable
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pl-0 payment-link",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handlePaymentTermsClick()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            size: "20",
                                                            left: "",
                                                          },
                                                        },
                                                        [_vm._v("$handshake")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "brand-regular",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoice.details.add-payment-terms-button"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm.invoice.autoPayMethod &&
                                !_vm.invoice.wasAutoPaid
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "body-14 font-italic font-primary my-5",
                                      },
                                      [
                                        _vm._v(
                                          " Automatic payment scheduled for " +
                                            _vm._s(
                                              _vm.$DateTime.shortDate(
                                                _vm.invoice.dateDue
                                              )
                                            ) +
                                            " from "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "text-transform": "capitalize",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.invoice.autoPayMethod.label
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.invoice.autoPayMethod.last4
                                            ) +
                                            " "
                                        ),
                                        _vm.editable
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "material-symbols-outlined pointer",
                                                attrs: {
                                                  size: "20",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click:
                                                    _vm.confirmCancelAutoPay,
                                                },
                                              },
                                              [_vm._v("cancel")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm.$vuetify.breakpoint.mdAndUp
                              ? _c(
                                  "div",
                                  {
                                    class: _vm.invoice.status.toLowerCase(),
                                    attrs: { id: "status-overlay" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.invoice.status) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm.showPaymentCardBanner
                          ? _c(
                              "div",
                              {
                                staticClass: "payment-card-pay-now pointer",
                                style: `width:${
                                  _vm.$vuetify.breakpoint.width > 1200
                                    ? 1000
                                    : _vm.$vuetify.breakpoint.width
                                }px`,
                                on: { click: _vm.handlePaymentAction },
                              },
                              [
                                _c("div", {}, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-center align-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "column-format" },
                                        [
                                          _c("div", [
                                            _vm.acceptStripe
                                              ? _c("img", {
                                                  staticStyle: {
                                                    "max-width": "140px",
                                                  },
                                                  attrs: {
                                                    src: "/images/invoices/stripe-logo.svg",
                                                    width: "100",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]),
                                          _c("div", [
                                            _vm.acceptPayPal
                                              ? _c("img", {
                                                  staticStyle: {
                                                    "max-width": "140px",
                                                  },
                                                  attrs: {
                                                    src: "/images/invoices/paypal.svg",
                                                    width: "100",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c("p", {
                                      staticClass: "inter-medium text-left",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "invoice.details.payment-card.heading"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm.acceptPayPal && _vm.acceptStripe
                                      ? _c("p", {
                                          staticClass: "text-left",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "invoice.details.payment-card.message-both"
                                              )
                                            ),
                                          },
                                        })
                                      : _vm.acceptStripe
                                      ? _c("p", {
                                          staticClass: "text-left",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "invoice.details.payment-card.message-stripe"
                                              )
                                            ),
                                          },
                                        })
                                      : _vm.acceptPayPal
                                      ? _c("p", {
                                          staticClass: "text-left",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "invoice.details.payment-card.message-paypal"
                                              )
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _c("p", { staticClass: "mt-3 text-left" }, [
                                      _c(
                                        "span",
                                        { staticClass: "mr-2" },
                                        [
                                          _c("payment-icon", {
                                            attrs: { size: "16", name: "amex" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "mr-2" },
                                        [
                                          _c("payment-icon", {
                                            attrs: {
                                              size: "16",
                                              name: "master_card",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "mr-2" },
                                        [
                                          _c("payment-icon", {
                                            attrs: { size: "16", name: "visa" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "mr-2" },
                                        [
                                          _c("payment-icon", {
                                            attrs: {
                                              size: "16",
                                              name: "discover",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "mr-2" },
                                        [
                                          _vm.acceptPayPal
                                            ? _c("payment-icon", {
                                                attrs: {
                                                  size: "16",
                                                  name: "paypal",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "primary-action",
                                        attrs: {
                                          block: "",
                                          small: "",
                                          text: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "inter-medium font-16",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "invoice.details.payment-card.button"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.showSetupPaymentCardBanner
                          ? _c(
                              "div",
                              {
                                staticClass: "payment-card-setup pointer",
                                style: `width:${
                                  _vm.$vuetify.breakpoint.width > 1200
                                    ? 1000
                                    : _vm.$vuetify.breakpoint.width
                                }px`,
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("payment-connect")
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-center align-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            src: "/images/invoices/stripe-logo.svg",
                                            width: 154,
                                          },
                                        }),
                                        _c("v-img", {
                                          attrs: {
                                            src: "/images/invoices/paypal.svg",
                                            width: 154,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _c("p", {
                                    staticClass: "inter-medium text-left",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "invoice.details.payment-card-setup.heading"
                                        )
                                      ),
                                    },
                                  }),
                                  _c("p", {
                                    staticClass: "gray_80--text text-left",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "invoice.details.payment-card-setup.message"
                                        )
                                      ),
                                    },
                                  }),
                                  _c(
                                    "p",
                                    { staticClass: "text-left mt-1" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "px-0 primary-action",
                                          attrs: { small: "", text: "" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "inter-medium" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "invoice.details.payment-card-setup.button"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c("confirm-dialog", {
                          attrs: {
                            dialog: _vm.deleteItemDialog,
                            "heading-text": "Confirm",
                            "yes-text": "Yes",
                            "no-text": "No",
                            "body-text": _vm.$t(
                              "invoice.details.delete-item-confirm"
                            ),
                          },
                          on: {
                            close: function ($event) {
                              return _vm.cancelDeleteItem()
                            },
                            confirm: function ($event) {
                              return _vm.handleDeleteItem()
                            },
                          },
                        }),
                        _c(
                          "modal",
                          {
                            ref: "paymentDetailsModal",
                            attrs: {
                              dialog: !!_vm.showPaymentDetails,
                              persistent: false,
                              "max-width": 350,
                            },
                            on: {
                              close: function ($event) {
                                _vm.showPaymentDetails = null
                              },
                            },
                          },
                          [
                            _vm.showPaymentDetails
                              ? _c("payment-details-modal", {
                                  attrs: {
                                    invoice: _vm.invoice,
                                    "edit-mode": _vm.editMode,
                                    payment: _vm.showPaymentDetails,
                                    client: _vm.invoice.client,
                                  },
                                  on: {
                                    close: function ($event) {
                                      _vm.showPaymentDetails = null
                                    },
                                    updated: function ($event) {
                                      return _vm.$emit("updated", $event)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "modal",
                          {
                            ref: "itemDetailsModal",
                            attrs: {
                              dialog: !!_vm.showItemDetailsModal,
                              persistent: false,
                              "max-width": 600,
                            },
                            on: {
                              close: function ($event) {
                                _vm.showItemDetailsModal = false
                              },
                            },
                          },
                          [
                            _vm.itemDetailsItemId
                              ? _c("item-details-modal", {
                                  key: _vm.itemDetailsItemId,
                                  attrs: {
                                    invoice: _vm.invoice,
                                    "invoice-item-id": _vm.itemDetailsItemId,
                                    "invoice-service": _vm.invoiceService,
                                    client: _vm.invoice.client,
                                  },
                                  on: {
                                    close: function ($event) {
                                      _vm.showItemDetailsModal = false
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }