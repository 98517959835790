var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-3" },
    [
      _c("v-data-table", {
        staticClass: "list-view",
        staticStyle: { cursor: "pointer" },
        attrs: {
          "item-key": "id",
          "fixed-header": "",
          headers: _vm.headers,
          items: _vm.subscriptionsComputed,
          "hide-default-footer": true,
          "items-per-page": -1,
        },
        on: {
          "click:row": function ($event) {
            return _vm.handleItemClick($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.periodStart",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "row-format align-center",
                    staticStyle: { height: "100%" },
                  },
                  [
                    item.periodStart && item.periodEnd
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.DateTime.fromISO(
                                  item.periodStart
                                ).toLocaleString()
                              ) +
                              " - " +
                              _vm._s(
                                _vm.DateTime.fromISO(
                                  item.periodEnd
                                ).toLocaleString()
                              ) +
                              " "
                          ),
                        ])
                      : _c("div", [_vm._v("--")]),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.daysUntilDue",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "row-format align-center",
                    staticStyle: { height: "100%" },
                  },
                  [
                    item.daysUntilDue
                      ? _c("div", [_vm._v(_vm._s(item.daysUntilDue))])
                      : _c("div", [_vm._v("--")]),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.subscriptionItems",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "row-format align-center",
                    staticStyle: { height: "100%" },
                  },
                  _vm._l(item.subscriptionItems, function (subItem, index) {
                    return _c(
                      "div",
                      { key: item.clientMini.id + ":" + index },
                      [
                        _vm._v(
                          " " +
                            _vm._s(subItem.quantity) +
                            " x " +
                            _vm._s(subItem.description) +
                            " @ " +
                            _vm._s(
                              _vm.$formatters.dollars(
                                subItem.amount,
                                true,
                                true,
                                subItem.currency
                              )
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "item.status",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "row-format align-center",
                    staticStyle: { height: "100%" },
                  },
                  [
                    item.status === "active"
                      ? _c(
                          "v-icon",
                          { attrs: { color: "success", small: "" } },
                          [_vm._v("$check")]
                        )
                      : item.status === "canceled" || item.status === "ended"
                      ? _c(
                          "v-icon",
                          { attrs: { color: "gray_80", small: "" } },
                          [_vm._v("$cancel")]
                        )
                      : item.status === "sent"
                      ? _c(
                          "v-icon",
                          { attrs: { color: "primary", small: "" } },
                          [_vm._v("$email")]
                        )
                      : _c(
                          "v-icon",
                          { attrs: { color: "warning", small: "" } },
                          [_vm._v("$alert")]
                        ),
                    item.status === "active" && item.cancelAtPeriodEnd
                      ? _c("div", { staticClass: "ml-1" }, [
                          _vm._v(_vm._s(_vm.$t("subscriptions.cancellation"))),
                        ])
                      : _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "text-transform": "capitalize" },
                          },
                          [_vm._v(_vm._s(item.status.replace("_", " ")))]
                        ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "text-left" },
        [
          _vm.subscriptionsComputed.length
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-3 primary-action",
                  on: { click: _vm.manageSubscriptions },
                },
                [_vm._v(_vm._s(_vm.$t("subscriptions.manage")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }