var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c("div", [
        _c("div", [
          _c(
            "div",
            { staticClass: "content pt-2" },
            [
              _vm.id === "new"
                ? _c("h2", { staticClass: "text-left pl-6 pt-2" }, [
                    _vm._v(_vm._s(_vm.pageHeader)),
                  ])
                : _vm._e(),
              _vm.isNewTicket
                ? _c("new-ticket", {
                    attrs: { "ticket-types": _vm.ticketTypes },
                    on: {
                      cancel: function ($event) {
                        return _vm.returnToList()
                      },
                      "new-ticket": function ($event) {
                        return _vm.newTicket($event)
                      },
                    },
                  })
                : _c("existing-ticket", {
                    attrs: { id: _vm.id, "ticket-types": _vm.ticketTypes },
                    on: {
                      cancel: function ($event) {
                        return _vm.returnToList()
                      },
                    },
                  }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }