var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `${_vm.route ? "pointer" : ""} row-format ${
        _vm.expanded ? "align-center" : "align-center"
      } mx-2 ${_vm.active ? "nav-link-active" : ""} nav-link${
        _vm.expanded ? "-expanded" : ""
      }`,
      on: { click: _vm.handleClick },
    },
    [
      _vm.expanded
        ? _c(
            "div",
            {
              staticClass: "row-format align-center",
              staticStyle: { "padding-left": "10px" },
            },
            [
              _c(
                "v-icon",
                {
                  class: !_vm.active ? "material-symbols-outlined" : "",
                  attrs: { color: _vm.active ? "primary" : "custom" },
                },
                [_vm._v(_vm._s(_vm.icon))]
              ),
              _c(
                "div",
                { class: `ml-2 nav-label ${_vm.active ? "active" : ""}` },
                [_vm._v(_vm._s(_vm.label))]
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: {
                    placement: "right",
                    arrow: true,
                    arrowType: "round",
                    animation: "fade",
                    delay: 50,
                    boundary: "window",
                  },
                  expression:
                    "{placement : 'right',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window' }",
                },
              ],
              staticClass: "row-format centered",
              staticStyle: { "padding-left": "10px" },
              attrs: { content: _vm.label },
            },
            [
              _c(
                "v-icon",
                {
                  class: !_vm.active ? "material-symbols-outlined" : "",
                  attrs: { color: _vm.active ? "primary" : "custom" },
                },
                [_vm._v(_vm._s(_vm.icon))]
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }