var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0 selector_text", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { style: _vm.headerStyle, attrs: { cols: "12" } },
            [
              !_vm.editMode
                ? _c("span", { domProps: { innerHTML: _vm._s(_vm.lHeader) } })
                : _vm._e(),
              _vm.editMode
                ? _c("editor", {
                    ref: "headerEditor",
                    staticClass: "grey-dots",
                    attrs: {
                      "api-key": _vm.$store.getters.getTinyMceKey,
                      "initial-value": _vm.lHeader,
                      inline: true,
                      init: _vm.mceConfigHeader,
                    },
                    on: { input: _vm.handleHeaderInput },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticStyle: { "text-align": "left" }, attrs: { cols: "12" } },
            [
              _c(
                "span",
                { style: _vm.bodyStyle },
                [
                  !_vm.editMode
                    ? _c("span", { domProps: { innerHTML: _vm._s(_vm.lText) } })
                    : _vm._e(),
                  _vm.editMode
                    ? _c("editor", {
                        ref: "textEditor",
                        staticStyle: { padding: "4px" },
                        attrs: {
                          iniline: true,
                          "api-key": _vm.$store.getters.getTinyMceKey,
                          "initial-value": _vm.lText,
                          init: _vm.mceConfigText,
                        },
                        on: { input: _vm.handleTextInput },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.isLocked ||
                    _vm.cardPaymentFailed ||
                    (!_vm.pendingCharge && !_vm.clientMode),
                  expression:
                    "!isLocked || cardPaymentFailed || (!pendingCharge && !clientMode)",
                },
              ],
              attrs: { cols: "12" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "pa-3 text-left column-format",
                  staticStyle: {
                    border: "1px solid var(--v-gray_50-base)",
                    "border-radius": "8px",
                    gap: "16px",
                  },
                },
                [
                  !_vm.clientMode && !_vm.forceAllDeliverables
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: {
                                content:
                                  "Amount will be set based on client selections",
                              },
                              expression:
                                "{ content: 'Amount will be set based on client selections' }",
                            },
                          ],
                          staticClass: "brand-medium",
                          staticStyle: { width: "fit-content" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$formatters.dollars(
                                  _vm.upFrontAmount,
                                  true,
                                  true,
                                  _vm.currency
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c("div", { staticClass: "brand-medium" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$formatters.dollars(
                              _vm.upFrontAmount,
                              true,
                              true,
                              _vm.currency
                            )
                          )
                        ),
                      ]),
                  _c("div", {
                    ref: "card",
                    staticStyle: {
                      "border-bottom": "1px solid var(--v-gray_50-base)",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "row-format gap-1 mt-1" },
                    [
                      _c("v-img", {
                        staticStyle: {
                          "max-width": "30px",
                          "margin-left": "-2px",
                        },
                        attrs: { src: "/images/payment/american_express.svg" },
                      }),
                      _c("v-img", {
                        staticStyle: { "max-width": "30px" },
                        attrs: { src: "/images/payment/visa.svg" },
                      }),
                      _c("v-img", {
                        staticStyle: { "max-width": "30px" },
                        attrs: { src: "/images/payment/mastercard.svg" },
                      }),
                      _c("v-img", {
                        staticStyle: { "max-width": "30px" },
                        attrs: { src: "/images/payment/discover.svg" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm.pendingCharge && _vm.pendingCharge.clientPaymentMethod
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "pa-3 font-14 text-left column-format font-italic font-primary",
                    staticStyle: {
                      border: "1px solid var(--v-gray_50-base)",
                      "border-radius": "8px",
                      gap: "16px",
                    },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.pendingCharge.clientPaymentMethod.label) +
                          " " +
                          _vm._s(_vm.pendingCharge.clientPaymentMethod.last4) +
                          " charged " +
                          _vm._s(
                            _vm.$formatters.dollars(
                              _vm.pendingCharge.amount,
                              true,
                              true,
                              _vm.pendingCharge.currency
                            )
                          ) +
                          " " +
                          _vm._s(_vm.pendingCharge.currency) +
                          " on " +
                          _vm._s(
                            _vm.$DateTime
                              .fromISO(_vm.pendingCharge.timestamp)
                              .toLocaleString(_vm.$DateTime.DATETIME_MED)
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row-format gap-1 mt-0" },
                      [
                        _c("v-img", {
                          staticStyle: {
                            "max-width": "30px",
                            "margin-left": "-2px",
                          },
                          attrs: {
                            src: "/images/payment/american_express.svg",
                          },
                        }),
                        _c("v-img", {
                          staticStyle: { "max-width": "30px" },
                          attrs: { src: "/images/payment/visa.svg" },
                        }),
                        _c("v-img", {
                          staticStyle: { "max-width": "30px" },
                          attrs: { src: "/images/payment/mastercard.svg" },
                        }),
                        _c("v-img", {
                          staticStyle: { "max-width": "30px" },
                          attrs: { src: "/images/payment/discover.svg" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }