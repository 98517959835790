var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "columnDragHandle row-format align-center mb-2 mx-2",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("div", {
        style: `width: 8px; min-width:8px; height: 8px; border-radius: 2px; background-color: ${_vm.column.hexColor}`,
      }),
      _c("div", { staticClass: "font-14 brand-medium ml-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.column.label,
              expression: "column.label",
            },
          ],
          staticClass: "truncate",
          staticStyle: {
            "max-width": "160px",
            outline: "none",
            color: "var(--v-black-base)",
          },
          attrs: { disabled: true, type: "text" },
          domProps: { value: _vm.column.label },
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.column, "label", $event.target.value)
            },
          },
        }),
      ]),
      _vm.allowAddDeliverable
        ? _c(
            "div",
            {
              staticClass: "row-format align-center",
              staticStyle: { "margin-left": "auto" },
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "pointer ml-1",
                  attrs: { color: "gray_70", size: "20" },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.addDeliverable(_vm.column)
                    },
                  },
                },
                [_vm._v("$plus")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }