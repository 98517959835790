var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: `width: 100%; text-align: left`,
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      !_vm.inBuilder && _vm.attachmentService
        ? _c("file-attachment", {
            attrs: {
              files: _vm.invoice.attachments,
              "file-service": _vm.attachmentService,
              "edit-mode": _vm.editMode,
              "previews-supported": false,
            },
          })
        : _c(
            "span",
            { staticClass: "font-14 font-gray_70" },
            [
              _c("v-icon", { attrs: { small: "", color: "gray_70" } }, [
                _vm._v("attachment"),
              ]),
              _vm._v(" Attach file"),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }