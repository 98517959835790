import { render, staticRenderFns } from "./Tabs.vue?vue&type=template&id=f73593b8"
import script from "./Tabs.vue?vue&type=script&lang=js"
export * from "./Tabs.vue?vue&type=script&lang=js"
import style0 from "./Tabs.vue?vue&type=style&index=0&id=f73593b8&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VList,VListItem,VMenu})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2503387672/src/struxture-clientportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f73593b8')) {
      api.createRecord('f73593b8', component.options)
    } else {
      api.reload('f73593b8', component.options)
    }
    module.hot.accept("./Tabs.vue?vue&type=template&id=f73593b8", function () {
      api.rerender('f73593b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/nav/Tabs.vue"
export default component.exports