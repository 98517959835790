var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.schema, function (item, index) {
      return _c(
        "div",
        { key: item.id },
        [
          _c("item-renderer", {
            ref: "item",
            refInFor: true,
            attrs: {
              index: index + 2 * 1000,
              item: item,
              styles: _vm.styles,
              disabled: false,
              "account-logo": _vm.accountLogo,
              active: false,
              results: _vm.results,
            },
            on: {
              input: function ($event) {
                return _vm.handleInput($event)
              },
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }