var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content show-scrollbar pa-2",
      style: `height: calc(100vh - 55px)`,
    },
    [
      _vm.page && _vm.page.type === "Internal"
        ? _c(
            "v-container",
            { key: _vm.refreshKey, attrs: { fluid: "" } },
            _vm._l(_vm.pageLayout, function (row, index) {
              return _c(
                "v-row",
                { key: index },
                _vm._l(row, function (col) {
                  return _c(
                    "v-col",
                    {
                      key: "key" + col.contentIndex,
                      attrs: { md: col.cols, cols: "12" },
                    },
                    [
                      _c("content-block", {
                        attrs: {
                          index: col.contentIndex,
                          content: _vm.page.content,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            }),
            1
          )
        : _vm.page && _vm.page.type === "iFrame"
        ? _c(
            "div",
            {
              staticClass: "show-scrollbar",
              staticStyle: { width: "100%", height: "100%" },
            },
            [
              _c("iframe", {
                staticStyle: { border: "none" },
                attrs: {
                  src: _vm.page.hyperLink,
                  width: "100%",
                  height: "98%",
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }