var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [_vm._v(" FOOTER ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }