var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": true,
            transition: "scale-transition",
            "offset-y": "",
            disabled: _vm.disabled,
            "min-width": "290px",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _vm.minimal
                    ? _c(
                        "div",
                        _vm._g({ staticClass: "pointer" }, on),
                        [
                          _vm.dateFormattedMinimal
                            ? _c(
                                "div",
                                {
                                  class: _vm.contentClass,
                                  staticStyle: { "white-space": "nowrap" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.dateFormattedMinimal) + " "
                                  ),
                                ]
                              )
                            : _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v("$calendarMono"),
                              ]),
                        ],
                        1
                      )
                    : _vm.micro
                    ? _c(
                        "div",
                        _vm._g(
                          {
                            class: `pointer ${
                              _vm.microFont ? _vm.microFont : "font-12"
                            }`,
                          },
                          on
                        ),
                        [
                          _vm.dateFormattedMinimal
                            ? _c(
                                "div",
                                {
                                  class: _vm.contentClass,
                                  staticStyle: { "white-space": "nowrap" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.dateFormattedMinimal) + " "
                                  ),
                                ]
                              )
                            : _c("div", {
                                domProps: { innerHTML: _vm._s(_vm.label) },
                              }),
                        ]
                      )
                    : _vm.standard
                    ? _c(
                        "v-text-field",
                        _vm._g(
                          {
                            class:
                              (_vm.contentClass ? _vm.contentClass : "") +
                              " pointer",
                            attrs: {
                              value: _vm.dateFormatted,
                              readonly: "",
                              label: _vm.label,
                              disabled: _vm.disabled,
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": _vm.label
                                ? true
                                : false,
                              "append-icon": "event",
                            },
                          },
                          on
                        )
                      )
                    : _c(
                        "v-text-field",
                        _vm._g(
                          {
                            class:
                              (_vm.contentClass ? _vm.contentClass : "") +
                              " h-outline pointer",
                            attrs: {
                              value: _vm.dateFormatted,
                              readonly: "",
                              label: _vm.label,
                              disabled: _vm.disabled,
                              "hide-details": "",
                              "persistent-placeholder": _vm.label
                                ? true
                                : false,
                              "append-icon": "$calendar",
                            },
                          },
                          on
                        )
                      ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dateMenu,
            callback: function ($$v) {
              _vm.dateMenu = $$v
            },
            expression: "dateMenu",
          },
        },
        [
          _c("v-date-picker", {
            staticClass: "datepicker",
            attrs: { "no-title": "", scrollable: "" },
            on: { change: _vm.dateUpdated },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "row-format centered",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-wrapper font-14 font-primary py-1 px-2 pointer",
                            on: {
                              click: function ($event) {
                                _vm.localDate = null
                                _vm.dateUpdated()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("global.clear")) + " ")]
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.localDate,
              callback: function ($$v) {
                _vm.localDate = $$v
              },
              expression: "localDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }