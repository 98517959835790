var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: `width:100%; padding-left: ${_vm.padding(
        _vm.item.pl
      )}px; padding-right: ${_vm.padding(_vm.item.pr)}px; padding-bottom: ${
        _vm.item.pb
      }px; padding-top: ${_vm.item.pt}px;`,
      attrs: { id: _vm.item.id },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c(
        "table",
        {
          staticClass: "text-left payment-plan-table",
          style: `--v-table-border: ${_vm.item.borderColor}; --v-table-padding: ${_vm.item.padding}px; ${_vm.wrapperStyle}; --v-table-border-width: ${_vm.item.borderWidth}px`,
        },
        [
          _c(
            "tbody",
            _vm._l(_vm.item.scheduledPayments, function (payment, index) {
              return _c("tr", { key: payment.id, style: `height: 1px;` }, [
                _c(
                  "td",
                  {
                    staticClass: "text-left font-14",
                    attrs: {
                      colspan:
                        (payment.triggerType === "Date" &&
                          payment.triggerDate) ||
                        payment.triggerType === "RelativeDate"
                          ? 1
                          : 2,
                    },
                  },
                  [_vm._v(_vm._s(index + 1) + ") " + _vm._s(payment.label))]
                ),
                payment.triggerType === "Date" && payment.triggerDate
                  ? _c("td", { staticClass: "text-center font-14" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.DateTime.fromISO(
                              payment.triggerDate
                            ).toLocaleString(_vm.DateTime.DATE_MED)
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                payment.triggerType === "RelativeDate"
                  ? _c("td", { staticClass: "text-center font-14" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .calculateRelativeDate(
                                payment.relativeTriggerDate
                              )
                              .toLocaleString(_vm.DateTime.DATE_MED)
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c("td", { staticClass: "text-right font-14" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$formatters.dollars(
                          payment.calculatedAmount,
                          true,
                          true,
                          _vm.agreement.currency
                        )
                      ) +
                      " "
                  ),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }