var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "column-format centered",
      staticStyle: { width: "100%", height: "100%" },
    },
    [
      _c("div", { staticClass: "brand-bold font-24" }, [
        _vm._v(_vm._s(_vm.$t("integration.connecting"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }