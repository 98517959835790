var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "iconContainer" },
    [
      _c("v-icon", { staticClass: "dragGrouping handle" }, [
        _vm._v(" $vuetify.icons.drag "),
      ]),
      _c(
        "v-icon",
        {
          staticClass: "dragGrouping",
          nativeOn: {
            click: function ($event) {
              return _vm.confirmRemoveSection()
            },
          },
        },
        [_vm._v(" $vuetify.icons.delete ")]
      ),
      _c("div", [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }