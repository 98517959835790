var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-wrapper" }, [
    _c(
      "div",
      {
        class: "list-view-header" + (_vm.borderBottom ? " border-bottom" : ""),
      },
      [
        _c("div", { staticClass: "right-side" }, [
          _c("div", { staticClass: "filter" }, [_vm._t("filter")], 2),
          _vm.addNewLabel || _vm.showMenu
            ? _c(
                "div",
                { staticClass: "add-new" },
                [
                  _vm.showMenu
                    ? [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "nudge-bottom": 0,
                              elevation: 0,
                              "content-class": "add-new-menu",
                              bottom: "",
                              left: "",
                              rounded: "",
                              "offset-overflow": "",
                              "offset-y": "",
                              transition: "slide-y-transition",
                              disabled: _vm.newButtonDisabled,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (menu) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "primary-action",
                                              staticStyle: {
                                                "min-width": "120px",
                                              },
                                              attrs: {
                                                small: "",
                                                elevation: "0",
                                              },
                                            },
                                            "v-btn",
                                            menu.attrs,
                                            false
                                          ),
                                          menu.on
                                        ),
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(_vm.addNewLabel)),
                                          ]),
                                          _c(
                                            "v-icon",
                                            { attrs: { size: 16, right: "" } },
                                            [_vm._v("$arrowDown")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              764805868
                            ),
                            model: {
                              value: _vm.showMenuModel,
                              callback: function ($$v) {
                                _vm.showMenuModel = $$v
                              },
                              expression: "showMenuModel",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "add-new-dropdown" },
                              _vm._l(_vm.addNewItems, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "add-new-item nowrap",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAddNewItem(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    : _c(
                        "v-btn",
                        {
                          staticClass: "add-new-button primary-action",
                          staticStyle: { "min-width": "120px" },
                          attrs: {
                            small: "",
                            elevation: "0",
                            disabled: _vm.newButtonDisabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleAddNew()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.addNewLabel) + " ")]
                      ),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }