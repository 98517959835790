var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "pointer",
        staticStyle: {
          width: "100%",
          "border-bottom": "1px solid var(--v-gray_50-base)",
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            _vm.expandClientList = !_vm.expandClientList
          },
        },
      },
      [
        _c(
          "v-container",
          {
            staticClass: "ma-0 pa-0",
            style:
              _vm.expandClientList &&
              _vm.$store.getters.getClientAccounts.length > 1
                ? "border-bottom: 1px solid var(--v-gray_50-base)"
                : "",
            attrs: { fluid: "" },
          },
          _vm._l(_vm.clientList, function (client, index) {
            return _c(
              "v-row",
              {
                key: client.clientId,
                staticClass: "ma-0 pt-2 pb-2 pl-3",
                style:
                  index === 0
                    ? "background: var(--v-primary-base)"
                    : "background: var(--v-sidebar-base);",
                attrs: { dense: "", align: "center" },
              },
              [
                _c("v-col", { attrs: { cols: "10", align: "left" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format pointer align-center",
                      staticStyle: { "align-items": "center" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.expandClientList
                            ? _vm.handleClientSelection(client)
                            : (_vm.expandClientList = true)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-avatar",
                            { attrs: { size: "25", color: "accent" } },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text brand-bold" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getClientInitials(client.clientName)
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: `pl-2 ${index === 0 ? "black--text" : ""}`,
                          staticStyle: { "margin-left": "-2px" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "font-weight": "600",
                              },
                            },
                            [_vm._v(_vm._s(client.clientName))]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                index === 0
                  ? _c(
                      "v-col",
                      {
                        staticClass: "pointer",
                        attrs: { cols: "2", align: "right" },
                      },
                      [
                        _vm.$store.getters.getClientAccounts.length > 1
                          ? _c(
                              "v-icon",
                              {
                                staticStyle: { "margin-right": "12px" },
                                attrs: { color: "black", size: "25" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.expandClientList
                                      ? "unfold_less"
                                      : "unfold_more"
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }