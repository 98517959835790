var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "deliverable-detail" } }, [
    _c("div", { staticClass: "modal-header row-format align-center" }, [
      _c(
        "div",
        { staticClass: "row-format align-center" },
        [
          _c("v-icon", { attrs: { size: "24" } }, [_vm._v("$projects")]),
          _c("div", { staticStyle: { "margin-left": "8px" } }, [
            _vm._v(_vm._s(_vm.project.name)),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "font-14" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-auto mr-n2",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("result", _vm.deliverable)
                },
              },
            },
            [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$close")])],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "task-padding d-md-flex show-scrollbar",
        attrs: { id: "deliverable-inner-wrapper" },
      },
      [
        _c(
          "div",
          { staticClass: "left-block" },
          [
            _c("v-textarea", {
              staticClass: "h-outline font-12",
              staticStyle: { "font-size": "14px" },
              attrs: {
                rows: 1,
                disabled: _vm.isReadOnly,
                "hide-details": "",
                "persistent-placeholder": "",
                "auto-grow": "",
                placeholder: _vm.$t("projects.new-deliverable"),
                "prepend-icon": "$deliverableBox",
                label: _vm.$t("projects.deliverable-name"),
              },
              model: {
                value: _vm.deliverable.name,
                callback: function ($$v) {
                  _vm.$set(_vm.deliverable, "name", $$v)
                },
                expression: "deliverable.name",
              },
            }),
            _c("div", { staticClass: "text-left mt-3" }, [
              _c("div", { staticClass: "font-grey font-12" }, [
                _vm._v(_vm._s(_vm.$t("projects.description"))),
              ]),
              _c(
                "div",
                { staticClass: "mt-1 font-14" },
                [
                  _c("markdown", {
                    attrs: {
                      value: _vm.deliverable.description,
                      id: _vm.deliverable.id,
                      placeholder: _vm.$t("projects.description-placeholder"),
                      "input-only-mode": false,
                      disabled: _vm.isReadOnly,
                    },
                    on: {
                      update: function ($event) {
                        _vm.deliverable.description = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "mt-4 d-none d-md-block" },
              [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("projects.tasks"))),
                ]),
                _c("tasks", {
                  ref: "tasks",
                  attrs: {
                    deliverable: _vm.deliverable,
                    "detail-view": true,
                    disabled: _vm.isReadOnly,
                  },
                }),
              ],
              1
            ),
            _vm.deliverable.approvalRequired
              ? _c("div", { staticClass: "mt-2" }, [
                  _vm.deliverable.approvalRequestedAt
                    ? _c("div", { staticClass: "submitted" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("projects.submitted")) +
                            " " +
                            _vm._s(
                              _vm.DateTime.fromISO(
                                _vm.deliverable.approvalRequestedAt
                              ).toFormat("DD")
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.deliverable.approvalRequired
              ? _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "success", elevation: "0", block: "" },
                        on: { click: _vm.confirmApproval },
                      },
                      [
                        _vm._v(_vm._s(_vm.$t("global.ok")) + " "),
                        _c(
                          "v-icon",
                          { staticClass: "ml-2", attrs: { small: "" } },
                          [_vm._v("$success")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.approvals.length
              ? _c(
                  "div",
                  { staticClass: "mt-4 d-none d-md-block" },
                  [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$t("projects.client-workflow"))),
                    ]),
                    _vm._l(_vm.approvals, function (approval) {
                      return _c(
                        "ul",
                        {
                          key: approval.id,
                          staticClass: "font-gray_80 font-12 text-left",
                        },
                        [
                          _c("li", [
                            _vm._v(
                              " " +
                                _vm._s(approval.approverName) +
                                " " +
                                _vm._s(_vm.$t("global.on")) +
                                " " +
                                _vm._s(
                                  _vm.DateTime.fromISO(
                                    approval.approvedAt
                                  ).toFormat("DD")
                                ) +
                                " " +
                                _vm._s(_vm.$t("global.from")) +
                                " [" +
                                _vm._s(approval.approvalStatus) +
                                "] " +
                                _vm._s(_vm.$t("global.status")) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.$vuetify.breakpoint.mdAndUp
              ? _c(
                  "div",
                  { staticClass: "mt-4 d-none d-md-block" },
                  [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$t("projects.feed"))),
                    ]),
                    _c("activity", {
                      ref: "activity",
                      attrs: {
                        project: _vm.project,
                        deliverable: _vm.deliverable,
                        "project-service": _vm.projectService,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right-block" },
          [
            _c(
              "v-select",
              {
                staticClass: "h-outline mt-3",
                attrs: {
                  "hide-details": "",
                  disabled: _vm.isReadOnly,
                  "persistent-placeholder": "",
                  label: _vm.$t("projects.filter.status"),
                  items: _vm.statusList,
                  "item-text": "label",
                  "item-value": "id",
                },
                model: {
                  value: _vm.deliverable.statusId,
                  callback: function ($$v) {
                    _vm.$set(_vm.deliverable, "statusId", $$v)
                  },
                  expression: "deliverable.statusId",
                },
              },
              [
                _c("template", { slot: "append" }, [
                  _c(
                    "div",
                    { staticClass: "v-input__icon v-input__icon--append" },
                    [
                      _c("div", {
                        staticClass: "status-box",
                        style: `--status-color: ${_vm.currentStatus.hexColor}`,
                      }),
                    ]
                  ),
                ]),
              ],
              2
            ),
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-click": true,
                  "close-on-content-click": false,
                  "offset-y": "",
                  disabled: _vm.isReadOnly,
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass:
                                "pointer h-outline text-left py-1 px-3 mb-2",
                            },
                            on
                          ),
                          [
                            _c("div", { staticClass: "font-12 font-gray_80" }, [
                              _vm._v(_vm._s(_vm.$t("projects.assigned-to"))),
                            ]),
                            _vm.deliverable.assignedToList.length
                              ? _c(
                                  "div",
                                  { staticClass: "row-format align-center" },
                                  _vm._l(
                                    _vm.deliverable.assignedToList,
                                    function (assignedTo, index) {
                                      return _c(
                                        "assigned-user",
                                        _vm._g(
                                          {
                                            key: assignedTo,
                                            style: `${
                                              index > 0
                                                ? "margin-left: -6px"
                                                : ""
                                            }; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`,
                                            attrs: {
                                              "assigned-to": assignedTo,
                                              "show-name": false,
                                              "assignable-users":
                                                _vm.assignableUsers,
                                              "empty-label": "--",
                                            },
                                          },
                                          on
                                        )
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c("div", [_vm._v("--")]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "text-left pa-3 font-14",
                    staticStyle: { "background-color": "var(--v-white-base)" },
                  },
                  _vm._l(_vm.assignableUsers, function (user) {
                    return _c(
                      "div",
                      {
                        key: user.userId,
                        staticClass:
                          "row-format align-center text-left py-1 pointer",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.deliverable.assignedToList,
                              expression: "deliverable.assignedToList",
                            },
                          ],
                          staticStyle: {
                            width: "20px",
                            height: "20px",
                            "border-radius": "4px",
                          },
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: user.userId,
                            checked: Array.isArray(
                              _vm.deliverable.assignedToList
                            )
                              ? _vm._i(
                                  _vm.deliverable.assignedToList,
                                  user.userId
                                ) > -1
                              : _vm.deliverable.assignedToList,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.deliverable.assignedToList,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = user.userId,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.deliverable,
                                      "assignedToList",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.deliverable,
                                      "assignedToList",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.deliverable, "assignedToList", $$c)
                              }
                            },
                          },
                        }),
                        user.userId
                          ? _c("assigned-user", {
                              staticClass: "mx-2",
                              attrs: {
                                "assigned-to": user.userId,
                                "show-name": true,
                                "assignable-users": _vm.assignableUsers,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
            _c("date-selector", {
              attrs: {
                date: _vm.deliverable.startDate,
                label: _vm.$t("projects.start"),
                disabled: _vm.isReadOnly,
              },
              on: {
                change: function ($event) {
                  _vm.deliverable.startDate = $event
                },
              },
            }),
            _c("date-selector", {
              attrs: {
                date: _vm.deliverable.dueDate,
                label: _vm.$t("projects.due"),
                disabled: _vm.isReadOnly,
              },
              on: {
                change: function ($event) {
                  _vm.deliverable.dueDate = $event
                },
              },
            }),
            _c(
              "div",
              { staticClass: "mt-4 d-md-none" },
              [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("projects.tasks"))),
                ]),
                _c("tasks", {
                  ref: "tasks",
                  attrs: {
                    deliverable: _vm.deliverable,
                    "detail-view": true,
                    disabled: _vm.isReadOnly,
                  },
                }),
              ],
              1
            ),
            _vm.approvals.length
              ? _c(
                  "div",
                  { staticClass: "mt-4 d-md-none" },
                  [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$t("projects.client-workflow"))),
                    ]),
                    _vm._l(_vm.approvals, function (approval) {
                      return _c(
                        "ul",
                        {
                          key: approval.id,
                          staticClass: "font-gray_80 font-12 text-left",
                        },
                        [
                          _c("li", [
                            _vm._v(
                              " " +
                                _vm._s(approval.approverName) +
                                " " +
                                _vm._s(_vm.$t("global.on")) +
                                " " +
                                _vm._s(
                                  _vm.DateTime.fromISO(
                                    approval.approvedAt
                                  ).toFormat("DD")
                                ) +
                                " " +
                                _vm._s(_vm.$t("global.from")) +
                                " [" +
                                _vm._s(approval.approvalStatus) +
                                "] " +
                                _vm._s(_vm.$t("global.status")) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.$vuetify.breakpoint.smAndDown
              ? _c(
                  "div",
                  { staticClass: "mt-4 d-md-none" },
                  [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$t("projects.feed"))),
                    ]),
                    _c("activity", {
                      ref: "activity",
                      attrs: {
                        project: _vm.project,
                        deliverable: _vm.deliverable,
                        "project-service": _vm.projectService,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "mt-4" }, [
              _c("div", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("projects.attachments"))),
              ]),
              _c(
                "div",
                [
                  _c("file-attachments", {
                    attrs: {
                      project: _vm.project,
                      deliverable: _vm.deliverable,
                      "project-service": _vm.projectService,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]
    ),
    _vm.isReadOnly
      ? _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "v-btn",
              {
                staticClass: "primary-action ml-1",
                staticStyle: { width: "160px" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("result")
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("global.close")) + " ")]
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "modal-footer" },
          [
            _c(
              "v-btn",
              {
                staticClass: "secondary-action mr-1",
                attrs: { icon: "" },
                on: { click: _vm.confirmDeleteDeliverable },
              },
              [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$delete")])],
              1
            ),
            _c(
              "v-btn",
              {
                staticClass: "primary-action ml-1",
                staticStyle: { width: "160px" },
                on: { click: _vm.saveAndCloseInternal },
              },
              [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }