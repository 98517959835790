var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                staticClass: "row-format mx-8",
                staticStyle: { "flex-wrap": "wrap" },
              },
              [
                _c("div", { staticClass: "kpi-box mr-3 mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fit text-left",
                      staticStyle: { "white-space": "nowrap" },
                    },
                    [_vm._v(_vm._s(_vm.$t("invoices.reports.total-invoiced")))]
                  ),
                  _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$formatters.dollars(
                          _vm.metrics.totalInvoiced,
                          true,
                          true,
                          _vm.$store.state.defaultCurrency
                        )
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "kpi-box mr-3 mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fit text-left",
                      staticStyle: { "white-space": "nowrap" },
                    },
                    [_vm._v(_vm._s(_vm.$t("invoices.reports.total-payments")))]
                  ),
                  _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$formatters.dollars(
                          _vm.metrics.totalReceived,
                          true,
                          true,
                          _vm.$store.state.defaultCurrency
                        )
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "kpi-box mr-3 mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fit text-left",
                      staticStyle: { "white-space": "nowrap" },
                    },
                    [_vm._v(_vm._s(_vm.$t("invoices.reports.avg-days")))]
                  ),
                  _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                    _vm._v(_vm._s(_vm.metrics.avgDaysToPay)),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm.depositAccount
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "row-format mx-8",
                    staticStyle: { "flex-wrap": "wrap" },
                  },
                  [
                    _c("div", { staticClass: "kpi-box mr-3 mt-3" }, [
                      _c(
                        "div",
                        {
                          staticClass: "fit text-left",
                          staticStyle: { "white-space": "nowrap" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("invoices.reports.deposits-collected")
                            )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "font-24 brand-medium fit mt-1" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$formatters.dollars(
                                _vm.depositAccount.liabilities,
                                true,
                                true,
                                _vm.$store.state.defaultCurrency
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "kpi-box mr-3 mt-3" }, [
                      _c(
                        "div",
                        {
                          staticClass: "fit text-left",
                          staticStyle: { "white-space": "nowrap" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("invoices.reports.deposits-applied"))
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "font-24 brand-medium fit mt-1" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$formatters.dollars(
                                _vm.depositAccount.credits,
                                true,
                                true,
                                _vm.$store.state.defaultCurrency
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "kpi-box mr-3 mt-3" }, [
                      _c(
                        "div",
                        {
                          staticClass: "fit text-left",
                          staticStyle: { "white-space": "nowrap" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("invoices.reports.balance-remaining"))
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "font-24 brand-medium fit mt-1" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$formatters.dollars(
                                _vm.depositAccount.balance,
                                true,
                                true,
                                _vm.$store.state.defaultCurrency
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [_c("invoice-by-month", { attrs: { invoices: _vm.invoices } })],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "div",
                { staticClass: "brand-medium font-12 mb-3 font-gray_70 mt-1" },
                [_vm._v(_vm._s(_vm.$t("invoices.reports.spend-by-project")))]
              ),
              _c("spend-by-project", {
                attrs: { invoices: _vm.invoices, projects: _vm.projects },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }