var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "confirm-dialog-div" },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "", rounded: "" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.handleClose()
            },
          },
        },
        [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("div", { staticClass: "modal-title text-center mb-2 mt-4" }),
          _c(
            "v-form",
            {
              ref: "confirmDialog",
              attrs: { id: "confirmDialog", "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit()
                },
              },
              model: {
                value: _vm.isValid,
                callback: function ($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid",
              },
            },
            [
              _c(
                "div",
                { staticClass: "column-format gap-2" },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "password",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: _vm.$t("login.password"),
                      rules: _vm.passwordRules,
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      type: "password",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: _vm.$t("login.confirm-password"),
                      rules: _vm.confirmPasswordRules,
                    },
                    model: {
                      value: _vm.confirmPassword,
                      callback: function ($$v) {
                        _vm.confirmPassword = $$v
                      },
                      expression: "confirmPassword",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { height: "40px!important" },
                      attrs: {
                        disabled: !_vm.isValid,
                        color: "primary",
                        elevation: "0",
                        block: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("global.save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", block: "" },
                      on: {
                        click: function ($event) {
                          return _vm.disablePasswordLogin()
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-outlined",
                          attrs: { color: "gray_70" },
                        },
                        [_vm._v("cancel")]
                      ),
                      _vm._v(_vm._s(_vm.$t("login.disable-password"))),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }