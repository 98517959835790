var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-combobox", {
        ref: "EmailInput",
        attrs: {
          items: _vm.contactMap,
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: `${_vm.label}`,
          multiple: true,
          "small-chips": "",
        },
        on: {
          change: function ($event) {
            return _vm.$emit("update", _vm.to)
          },
        },
        model: {
          value: _vm.to,
          callback: function ($$v) {
            _vm.to = $$v
          },
          expression: "to",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }