var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.modals, function (modal) {
      return _c(
        "basic-modal",
        {
          key: modal.id,
          attrs: {
            dialog: modal.dialog,
            "close-on-escape": modal.closeOnEscape,
            fullscreen: modal.fullScreen,
            "overlay-color": "modal_overlay",
          },
          on: {
            close: function ($event) {
              return _vm.handleClose(modal)
            },
          },
        },
        [
          _c(
            modal.component,
            _vm._b(
              {
                ref: modal.id,
                refInFor: true,
                tag: "component",
                on: {
                  result: function ($event) {
                    return _vm.onResult(modal, $event)
                  },
                  error: function ($event) {
                    return _vm.onError(modal, $event)
                  },
                },
              },
              "component",
              modal.binding,
              false
            )
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }