var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mt-auto",
      style: `width:100%; text-align: ${
        _vm.buttonAlignment
      }; padding-left: ${_vm.padding(
        _vm.item.pl
      )}px; padding-right: ${_vm.padding(_vm.item.pr)}px; padding-bottom: ${
        _vm.item.pb
      }px; padding-top: ${_vm.item.pt}px;`,
      attrs: { id: _vm.item.id },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "elevation-3",
          style: _vm.buttonStyle,
          attrs: {
            color: _vm.accepted ? _vm.item.buttonColor : "#FFFFFF",
            "x-small": _vm.button.xSmall,
            small: _vm.button.small,
            large: _vm.button.large,
            "x-large": _vm.button.xLarge,
            block: _vm.button.block,
            disabled: _vm.buttonDisabled,
          },
          on: { click: _vm.handleClick },
        },
        [
          _c(
            "div",
            { staticClass: "pa-1", style: `color: ${_vm.buttonFontColor}` },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.accepted ? _vm.item.acceptedText : _vm.item.text) +
                  " "
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }