var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-3 mt-3" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", [
                _c(
                  "div",
                  {
                    staticClass: "column-format",
                    staticStyle: { gap: "12px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "column-format px-2 gap-4" },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.ticketTypes,
                            "item-text": "type",
                            "item-value": "id",
                            label: _vm.$t("requests.new.type"),
                            outlined: "",
                            "persistent-placeholder": "",
                            "hide-details": "",
                            dense: "",
                          },
                          model: {
                            value: _vm.ticketType,
                            callback: function ($$v) {
                              _vm.ticketType = $$v
                            },
                            expression: "ticketType",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: _vm.$t("requests.new.subject"),
                            dense: "",
                            outlined: "",
                            "persistent-placeholder": "",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.subject,
                            callback: function ($$v) {
                              _vm.subject = $$v
                            },
                            expression: "subject",
                          },
                        }),
                        _c("contact-selector", {
                          attrs: {
                            "to-list": _vm.ccList,
                            contacts: _vm.contacts,
                            label: _vm.$t("requests.new.cc"),
                          },
                          on: { update: _vm.updateCcList },
                        }),
                      ],
                      1
                    ),
                    _vm.selectedType.discoveryTemplate
                      ? _c(
                          "div",
                          {},
                          [
                            _vm.selectedType.discoveryTemplate.schemaVersion ==
                            1
                              ? _c("ticket-form-v1", {
                                  ref: "form",
                                  attrs: {
                                    template:
                                      _vm.selectedType.discoveryTemplate,
                                  },
                                })
                              : _vm._e(),
                            _vm.selectedType.discoveryTemplate.schemaVersion ==
                            2
                              ? _c("ticket-form-v2", {
                                  ref: "form",
                                  attrs: {
                                    template:
                                      _vm.selectedType.discoveryTemplate,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.selectedType.discoveryTemplate
                      ? _c("v-textarea", {
                          attrs: {
                            rows: "12",
                            label: _vm.$t("requests.new.details"),
                            dense: "",
                            outlined: "",
                            "persistent-placeholder": "",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.comment,
                            callback: function ($$v) {
                              _vm.comment = $$v
                            },
                            expression: "comment",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        on: {
                          drop: function ($event) {
                            $event.preventDefault()
                            return _vm.addDropFile.apply(null, arguments)
                          },
                          dragover: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c("v-file-input", {
                          attrs: {
                            placeholder: _vm.$t(
                              "requests.new.upload-attachments"
                            ),
                            label: _vm.$t("requests.new.attachments"),
                            multiple: "",
                            "prepend-icon": "attach_file",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "selection",
                              fn: function ({ text }) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      staticStyle: {
                                        color: "var(--v-white-base)",
                                      },
                                      attrs: {
                                        small: "",
                                        label: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(text) + " ")]
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.attachments,
                            callback: function ($$v) {
                              _vm.attachments = $$v
                            },
                            expression: "attachments",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "row-format",
                        staticStyle: { gap: "12px" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "secondary-action",
                            on: {
                              click: function ($event) {
                                return _vm.confirmCancel()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "super-action",
                            attrs: { width: "200" },
                            on: {
                              click: function ($event) {
                                return _vm.createTicket()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("requests.new.submit")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }