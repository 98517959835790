var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                staticClass: "row-format mx-8",
                staticStyle: { "flex-wrap": "wrap" },
              },
              [
                _c("div", { staticClass: "kpi-box mr-3 mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fit text-left",
                      staticStyle: { "white-space": "nowrap" },
                    },
                    [_vm._v(_vm._s(this.$t("timetrack.reports.total-time")))]
                  ),
                  _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$formatters.formatSeconds(_vm.metrics.totalTime)
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "kpi-box mr-3 mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fit text-left",
                      staticStyle: { "white-space": "nowrap" },
                    },
                    [_vm._v(_vm._s(this.$t("timetrack.reports.avg-month")))]
                  ),
                  _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$formatters.formatSeconds(
                          _vm.metrics.averageMonthTime
                        )
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "kpi-box mr-3 mt-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "fit text-left",
                      staticStyle: { "white-space": "nowrap" },
                    },
                    [_vm._v(_vm._s(this.$t("timetrack.reports.avg-week")))]
                  ),
                  _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$formatters.formatSeconds(
                          _vm.metrics.averageWeekTime
                        )
                      )
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("time-by-month", {
                attrs: { "timer-events": _vm.timerEvents },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "div",
                { staticClass: "brand-medium font-12 mb-3 font-gray_70 mt-1" },
                [_vm._v(_vm._s(_vm.$t("timetrack.reports.time-by-project")))]
              ),
              _c("time-by-project", {
                attrs: { "timer-events": _vm.timerEvents },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }