var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "deliverable",
      on: {
        click: function ($event) {
          return _vm.$emit("open-deliverable")
        },
      },
    },
    [
      _c("div", { staticClass: "font-14 brand-medium mb-1" }, [
        _vm._v(_vm._s(_vm.deliverable.name)),
      ]),
      _c("div", { staticClass: "font-12 mb-1" }, [
        _vm._v(
          _vm._s(_vm.deliverable.comments.length) +
            " " +
            _vm._s(_vm.$t("projects.comments"))
        ),
      ]),
      _c("div", { staticClass: "font-12 mb-1" }, [
        _vm._v(
          _vm._s(_vm.deliverable.files.length) +
            " " +
            _vm._s(_vm.$t("projects.attachments"))
        ),
      ]),
      _vm.deliverable.dueDate
        ? _c("div", { staticClass: "font-12 mb-1" }, [
            _vm._v(
              _vm._s(_vm.$t("projects.due")) +
                " " +
                _vm._s(
                  _vm.DateTime.fromISO(_vm.deliverable.dueDate).toFormat("DD")
                )
            ),
          ])
        : _vm._e(),
      _c("div", { staticStyle: { "margin-top": "auto!important" } }, [
        _vm.deliverable.approvalRequestedAt
          ? _c("div", { staticClass: "submitted" }, [
              _vm._v(
                _vm._s(_vm.$t("projects.requested")) +
                  " " +
                  _vm._s(
                    _vm.DateTime.fromISO(
                      _vm.deliverable.approvalRequestedAt
                    ).toFormat("DD")
                  )
              ),
            ])
          : _vm._e(),
        _vm.lastApproval && !_vm.deliverable.approvalRequired
          ? _c("div", { staticClass: "approved" }, [
              _vm._v(
                _vm._s(_vm.$t("projects.submitted")) +
                  " " +
                  _vm._s(
                    _vm.DateTime.fromISO(_vm.lastApproval.approvedAt).toFormat(
                      "DD"
                    )
                  )
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }