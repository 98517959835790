var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height column-format" },
    [
      _c("h-header", {
        scopedSlots: _vm._u([
          {
            key: "filter",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "row-format",
                    staticStyle: { "flex-wrap": "wrap" },
                  },
                  [
                    _vm.view === "list"
                      ? _c("v-text-field", {
                          staticStyle: { "font-size": "14px", width: "200px" },
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            "prepend-icon": "$filter",
                            placeholder: _vm.$t("global.filter"),
                            clearable: "",
                          },
                          model: {
                            value: _vm.filter,
                            callback: function ($$v) {
                              _vm.filter = $$v
                            },
                            expression: "filter",
                          },
                        })
                      : _vm._e(),
                    _vm.view === "list" || _vm.view === "reports"
                      ? _c("v-select", {
                          staticClass: "ml-3",
                          staticStyle: { width: "200px" },
                          attrs: {
                            clearable: "",
                            dense: "",
                            "hide-details": "",
                            items: _vm.dateOptions,
                            placeholder: _vm.$t("global.date"),
                          },
                          model: {
                            value: _vm.dateFilter,
                            callback: function ($$v) {
                              _vm.dateFilter = $$v
                            },
                            expression: "dateFilter",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "show-scrollbar", staticStyle: { height: "var(--vh)" } },
        [
          _c("invoice-table", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.view === "list",
                expression: "view === 'list'",
              },
            ],
            ref: "InvoiceTable",
            attrs: { invoices: _vm.dateFilteredInvoices, filter: _vm.filter },
            on: { refresh: _vm.getInvoices },
          }),
          _c("invoice-charts", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.view === "reports" &&
                  _vm.$store.getters.getFeatures.summaryFinancialsEnabled,
                expression:
                  "view === 'reports' && $store.getters.getFeatures.summaryFinancialsEnabled",
              },
            ],
            ref: "InvoiceChart",
            attrs: {
              invoices: _vm.dateFilteredInvoices,
              projects: _vm.projects,
              "deposit-account": _vm.depositAccount,
            },
          }),
          _c("payment-methods", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.view === "accounts",
                expression: "view === 'accounts'",
              },
            ],
            attrs: { "ach-enabled": _vm.achEnabled },
          }),
          _vm.view === "subscriptions" ? _c("subscription-list") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }