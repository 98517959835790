var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ma-4" }, [
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c("v-data-table", {
          staticStyle: { cursor: "pointer" },
          attrs: {
            headers: _vm.headers,
            items: _vm.filteredResults,
            "sort-by": "dateSent",
            "sort-desc": true,
            "hide-default-footer": true,
            "disable-pagination": true,
            "mobile-breakpoint": 200,
          },
          on: {
            "click:row": function ($event) {
              return _vm.openInvoice($event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "item.dateSent",
              fn: function ({ item }) {
                return [
                  item.dateSent
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.DateTime.fromISO(item.dateSent).toFormat("DD")
                          )
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.dateDue",
              fn: function ({ item }) {
                return [
                  item.dateDue
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.DateTime.fromISO(item.dateDue).toFormat("DD")
                          )
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.invoiceNumberFormatted",
              fn: function ({ item }) {
                return [
                  _c("span", [_vm._v(_vm._s(item.invoiceNumberFormatted))]),
                ]
              },
            },
            {
              key: "item.total",
              fn: function ({ item }) {
                return [
                  item.total
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$formatters.dollars(
                              item.total ? item.total : 0,
                              true,
                              true,
                              item.currency
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.status",
              fn: function ({ item }) {
                return [
                  _c("div", {
                    staticClass: "font-12",
                    domProps: { innerHTML: _vm._s(_vm.getStatusHtml(item)) },
                  }),
                ]
              },
            },
            {
              key: "item.id",
              fn: function ({ item }) {
                return [
                  item.statusDetail.payable &&
                  item.payOnlineUrl &&
                  item.allowOnlinePayment
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            elevation: "0",
                            color: "success",
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.payNow(item.payOnlineUrl)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "font-12 brand-bold" }, [
                            _vm._v(_vm._s(_vm.$t("invoices.pay-now"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  _vm._v(
                    " " + _vm._s(_vm.$t("invoices.no-invoice-record")) + " "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }