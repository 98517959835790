var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0 selector_text", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { style: _vm.headerStyle, attrs: { cols: "12" } },
            [
              !_vm.editMode
                ? _c("span", { domProps: { innerHTML: _vm._s(_vm.lHeader) } })
                : _vm._e(),
              _vm.editMode
                ? _c("editor", {
                    ref: "headerEditor",
                    staticClass: "grey-dots",
                    attrs: {
                      "api-key": _vm.$store.getters.getTinyMceKey,
                      "initial-value": _vm.lHeader,
                      inline: true,
                      init: _vm.mceConfigHeader,
                    },
                    on: { input: _vm.handleHeaderInput },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", align: "center" } },
            [
              !_vm.editMode
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { "min-width": "200px" },
                      attrs: {
                        large: "",
                        elevation: "0",
                        color: "primary",
                        outlined: "",
                        disabled: !_vm.clientMode || _vm.isLocked,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.confirmAccept()
                        },
                      },
                    },
                    [
                      _c("div", [_vm._v(_vm._s(_vm.lText))]),
                      _c(
                        "v-icon",
                        { staticClass: "ml-2", attrs: { color: "primary" } },
                        [_vm._v("$success")]
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "row-format centered font-16 font-primary brand-medium",
                      staticStyle: {
                        border: "1px solid var(--v-primary-base)",
                        "border-radius": "4px",
                        width: "fit-content",
                        "min-width": "200px",
                        height: "50px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "px-2",
                          staticStyle: { width: "fit-content" },
                          attrs: { contenteditable: "" },
                          on: {
                            input: function ($event) {
                              return _vm.handleTextInput($event)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.seedText))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-2",
                          attrs: { color: "primary", size: "20" },
                        },
                        [_vm._v("$success")]
                      ),
                    ],
                    1
                  ),
              _vm.accepted
                ? _c("div", { staticClass: "ipBlock mt-4" }, [
                    _vm._v(" " + _vm._s(_vm.$t("proposals.country")) + ": "),
                    _c("img", {
                      attrs: { src: _vm.ip.countryFlag, width: "15px" },
                    }),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("proposals.ip")) +
                        ": " +
                        _vm._s(_vm.ip.ip)
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("proposals.location")) +
                        ": " +
                        _vm._s(_vm.ip.city) +
                        ", " +
                        _vm._s(_vm.ip.region) +
                        ", " +
                        _vm._s(_vm.ip.country)
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("proposals.date")) +
                        ": " +
                        _vm._s(this.$DateTime.dateTime(_vm.acceptedAt)) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }