var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "pa-0 ma-0 text-left overflow-hidden",
          staticStyle: { "max-width": "425px" },
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("workspace-selector")],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-4" },
                [
                  _c("settings-locale", {
                    attrs: {
                      label: _vm.$t("settings.language"),
                      children: _vm.countries.getCountriesTranslate(),
                      icon: "translate",
                    },
                    on: {
                      selected: function ($event) {
                        return _vm.switchLocale($event)
                      },
                    },
                  }),
                  _c("settings-link", {
                    attrs: {
                      label: _vm.$t("settings.theme"),
                      children: _vm.theme,
                      icon: _vm.themeIcon,
                    },
                    on: {
                      selected: function ($event) {
                        return _vm.handleThemeSelection($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }