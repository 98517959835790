var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      (_vm.editing || _vm.inputOnlyMode) && !_vm.disabled
        ? _c("v-textarea", {
            ref: "markdownEditor",
            staticClass: "ma-0 pa-0",
            staticStyle: { "font-size": "14px" },
            attrs: {
              "auto-grow": "",
              rows: "1",
              id: "markdown_editor_" + _vm.localId,
              placeholder: _vm.placeholder,
              solo: _vm.solo,
              flat: _vm.flat,
            },
            on: {
              blur: function ($event) {
                return _vm.handleBlur()
              },
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  )
                    return null
                  $event.preventDefault()
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (!$event.shiftKey) return null
                  if ($event.ctrlKey || $event.altKey || $event.metaKey)
                    return null
                  $event.preventDefault()
                },
              ],
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  )
                    return null
                  return _vm.handleEnter()
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (!$event.shiftKey) return null
                  if ($event.ctrlKey || $event.altKey || $event.metaKey)
                    return null
                  return _vm.handleShiftEnter()
                },
              ],
            },
            model: {
              value: _vm.data,
              callback: function ($$v) {
                _vm.data = $$v
              },
              expression: "data",
            },
          })
        : _c("div", {
            staticClass: "marked",
            domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) },
            on: {
              click: function ($event) {
                return _vm.setEditing(true, true)
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }