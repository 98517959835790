var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "text-left",
      staticStyle: {
        color: "var(--body-color)!important",
        "font-size": "var(--body-size) !important",
      },
    },
    [
      _c("div", { staticClass: "row-format align-center gap-3" }, [
        _c("div", [
          _c("div", { staticClass: "description" }, [
            _c("div", { staticStyle: { "white-space": "pre-wrap" } }, [
              _vm._v(_vm._s(_vm.item.description)),
            ]),
          ]),
          _vm.tokens.get("Item.DueDate")
            ? _c("div", { staticClass: "font-14" }, [
                _vm._v(_vm._s(_vm.tokens.get("Item.DueDate"))),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "ml-auto text-right" }, [
          _c("span", { staticStyle: { "font-weight": "600" } }, [
            _vm._v(_vm._s(_vm.tokens.get("Item.Total"))),
          ]),
          _c("br"),
          _vm.item.taxable && _vm.item.quantity && _vm.item.rate
            ? _c("span", { staticClass: "font-14" }, [
                _vm._v(
                  "+" +
                    _vm._s(_vm.tokens.get("Item.TaxRate")) +
                    " " +
                    _vm._s(_vm.tokens.get("Item.TaxLabel"))
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }