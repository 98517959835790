var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `page-wrapper column-format align-center ${
        _vm.isMobile ? "px-0" : "px-12"
      }`,
      staticStyle: { width: "100%" },
    },
    [
      _c(
        "div",
        {
          class: `page column-format ${
            _vm.agreement.styles.pageShadow ? "shadow" : ""
          } ${_vm.layoutClass}`,
          style: `--max-page-width: ${_vm.agreement.styles.maxWidth}px`,
          on: {
            drop: function ($event) {
              return _vm.drop($event)
            },
            dragover: function ($event) {
              return _vm.allowDrop($event)
            },
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("edit", null)
            },
          },
        },
        _vm._l(_vm.schema, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticStyle: { width: "100%" },
              attrs: {
                id: "schemaWrapper-" + item.id,
                draggable: _vm.draggable && _vm.allowDrag,
              },
              on: {
                dragenter: _vm.handleDragEnter,
                dragleave: _vm.handleDragLeave,
                dragstart: _vm.reOrderDragStart,
              },
            },
            [
              _c("item-renderer", {
                ref: `item-${item.id}`,
                refInFor: true,
                attrs: {
                  item: item,
                  "in-container": false,
                  index: index,
                  active:
                    (item.id === _vm.activeElementId ||
                      item.id === _vm.activeContainerId) &&
                    _vm.editMode,
                  "mce-config": _vm.mceConfig,
                  agreement: _vm.agreement,
                  "client-mode": _vm.clientMode,
                  "edit-mode": _vm.editMode,
                  tokens: _vm.tokens,
                  signer: _vm.signer,
                  "template-mode": _vm.templateMode,
                  "page-width": _vm.pageWidth,
                  "is-mobile": _vm.isMobile,
                },
                on: {
                  delete: function ($event) {
                    return _vm.$emit("delete", $event)
                  },
                  signed: function ($event) {
                    return _vm.$emit("signed", $event)
                  },
                  change: function ($event) {
                    return _vm.$emit("change", $event)
                  },
                  "container-item-update": function ($event) {
                    return _vm.$emit("container-item-update", $event)
                  },
                  "container-item-signed": function ($event) {
                    return _vm.$emit("container-item-signed", $event)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$emit("edit", item)
                  },
                  "add-container-element": function ($event) {
                    return _vm.addContainerElement($event)
                  },
                  "container-item-click": function ($event) {
                    return _vm.$emit("container-item-click", $event)
                  },
                  "drag-enabled": function ($event) {
                    return _vm.setDragEnabled($event)
                  },
                  "move-up": function ($event) {
                    return _vm.moveUp(item, index)
                  },
                  "move-down": function ($event) {
                    return _vm.moveDown(item, index)
                  },
                  "add-element-above": function ($event) {
                    return _vm.handleAddElement(index, $event)
                  },
                  "add-element-below": function ($event) {
                    return _vm.handleAddElement(index + 1, $event)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm.agreement.pages.length > 1
        ? _c(
            "div",
            {
              staticClass: "font-14 font-gray_70 mt-1 text-right page-number",
              staticStyle: { "margin-left": "30px", "margin-right": "30px" },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.pageIndex + 1) +
                  "/" +
                  _vm._s(_vm.agreement.pages.length) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }