var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pa-5 container",
      style: `--width: ${_vm.$vuetify.breakpoint.width * 0.8}px`,
    },
    [
      _c(
        "div",
        { staticClass: "row-format align-center" },
        [
          _c(
            "v-icon",
            {
              staticClass: "ml-auto pointer",
              staticStyle: { color: "#000" },
              attrs: { size: "20" },
              on: {
                click: function ($event) {
                  return _vm.$emit("result")
                },
              },
            },
            [_vm._v("$close")]
          ),
        ],
        1
      ),
      _c("div", [
        _c("iframe", {
          staticClass: "responsive-iframe",
          attrs: { src: _vm.finalUrl, id: `frame-${_vm.frameId}` },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }