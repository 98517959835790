var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height column-format" },
    [
      _c("h-header", {
        scopedSlots: _vm._u([
          {
            key: "filter",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "row-format",
                    staticStyle: { "flex-wrap": "wrap" },
                  },
                  [
                    _c("v-text-field", {
                      staticStyle: { "font-size": "14px", width: "200px" },
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        "prepend-icon": "$filter",
                        placeholder: _vm.$t("global.filter"),
                        clearable: "",
                      },
                      on: { change: _vm.handleFilterChange },
                      model: {
                        value: _vm.filter,
                        callback: function ($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "ml-3",
                      staticStyle: { width: "200px" },
                      attrs: {
                        clearable: "",
                        dense: "",
                        "hide-details": "",
                        items: _vm.dateOptions,
                        placeholder: _vm.$t("global.date"),
                      },
                      on: { change: _vm.handleDateFilterChange },
                      model: {
                        value: _vm.dateFilter,
                        callback: function ($$v) {
                          _vm.dateFilter = $$v
                        },
                        expression: "dateFilter",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "show-scrollbar" },
        [
          _c("v-data-table", {
            staticStyle: {
              cursor: "pointer",
              height: "calc(var(--vh) - 150px)",
            },
            attrs: {
              headers: _vm.headers,
              items: _vm.combinedList,
              "disable-pagination": true,
              "hide-default-footer": true,
              "items-per-page": -1,
              "fixed-header": "",
            },
            on: {
              "click:row": function ($event) {
                return _vm.routeClick($event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.formData.firstName",
                fn: function ({ item }) {
                  return [
                    item.formData
                      ? _c("span", [
                          _vm._v(
                            _vm._s(item.formData.firstName) +
                              " " +
                              _vm._s(item.formData.lastName) +
                              " <" +
                              _vm._s(item.formData.email) +
                              ">"
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
              {
                key: "item.type",
                fn: function ({ item }) {
                  return [
                    item.type === "SUBMITTED"
                      ? _c("div", { staticClass: "font-12 status-submitted" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("forms.submissions.complete")) +
                              " "
                          ),
                        ])
                      : _c("div", { staticClass: "font-12 status-requested" }, [
                          _vm._v(
                            _vm._s(_vm.$t("forms.submissions.incomplete"))
                          ),
                        ]),
                  ]
                },
              },
              {
                key: "item.submittedAt",
                fn: function ({ item }) {
                  return [
                    item.submittedAt
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.DateTime.fromISO(
                                item.submittedAt
                              ).toLocaleString(_vm.DateTime.DATETIME_MED)
                            )
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }