var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isConditionMet
    ? _c(
        "div",
        {
          staticClass: "text-left",
          on: {
            click: function ($event) {
              return _vm.$emit("click", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-question" },
            [
              _vm._v(_vm._s(_vm.item.question) + " "),
              _vm.item.required
                ? _c("span", { staticClass: "font-red brand-medium" }, [
                    _vm._v("*"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showConditionalHelper
                ? _c("v-icon", { attrs: { small: "", color: "gray_50" } }, [
                    _vm._v("account_tree"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                "close-on-click": false,
                "offset-y": "",
                disabled: _vm.disabled,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-form",
                          {
                            ref: "form",
                            attrs: { "lazy-validation": "" },
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value: _vm.isValid,
                              callback: function ($$v) {
                                _vm.isValid = $$v
                              },
                              expression: "isValid",
                            },
                          },
                          [
                            _c(
                              "v-text-field",
                              _vm._g(
                                {
                                  staticClass: "form-input",
                                  attrs: {
                                    "hide-details": "",
                                    dense: "",
                                    placeholder: _vm.item.placeholder,
                                    outlined: _vm.outlined,
                                    solo: _vm.solo,
                                    filled: _vm.filled,
                                    readonly: true,
                                    value: _vm.dateFormatted,
                                    disabled: _vm.disabled,
                                    "append-icon": "event",
                                    rules: _vm.item.required
                                      ? _vm.inputRequired
                                      : [],
                                  },
                                },
                                on
                              )
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1857912740
              ),
              model: {
                value: _vm.menu,
                callback: function ($$v) {
                  _vm.menu = $$v
                },
                expression: "menu",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "background-color": "var(--v-white-base)" } },
                [
                  _c("v-date-picker", {
                    attrs: {
                      "no-title": "",
                      scrollable: "",
                      "first-day-of-week": _vm.$store.state.firstDayOfWeek,
                    },
                    on: { change: _vm.dateUpdated },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "row-format centered",
                                  staticStyle: { width: "100%" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input-wrapper font-14 font-primary py-1 px-2 pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearDate()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("global.clear")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      861800985
                    ),
                    model: {
                      value: _vm.localDate,
                      callback: function ($$v) {
                        _vm.localDate = $$v
                      },
                      expression: "localDate",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }