var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.invoices.length
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "brand-medium pointer",
            on: {
              click: function ($event) {
                return _vm.$router.push("/invoices?v=list")
              },
            },
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("welcome.currently-have")) + " "),
            _c("span", { staticStyle: { color: "var(--v-primary-base)" } }, [
              _vm._v(_vm._s(_vm.invoices.length)),
            ]),
            _vm._v(
              " " +
                _vm._s(_vm.$tc("welcome.invoices-ready", _vm.invoices.length)) +
                ". "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "column-format",
            staticStyle: { gap: "8px", "margin-top": "12px" },
          },
          _vm._l(_vm.invoices, function (invoice) {
            return _c(
              "div",
              {
                key: invoice.id,
                staticClass: "action-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.openInvoice(invoice)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(invoice.invoiceNumberFormatted) +
                    " - " +
                    _vm._s(_vm.$formatters.dollars(invoice.total)) +
                    " - " +
                    _vm._s(_vm.$t("global.due")) +
                    " " +
                    _vm._s(
                      _vm.DateTime.fromISO(invoice.dateDue).toFormat("MMM dd")
                    ) +
                    " "
                ),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }