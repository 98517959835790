var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "show-scrollbar",
      staticStyle: {
        "background-color": "var(--v-gray_20-base)",
        "max-height": "100vh",
      },
    },
    [
      _c(
        "div",
        { staticClass: "row-format centered" },
        [
          _vm.rendererType === "InvoiceRenderer"
            ? _c(
                "div",
                { staticStyle: { "max-width": "155px" } },
                [_c("language-switcher")],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: { elevation: "0", text: "", color: "gray_70" },
              on: { click: _vm.downloadPdf },
            },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "primary" } },
                [_vm._v("$download")]
              ),
              _vm._v(" Download PDF"),
            ],
            1
          ),
        ],
        1
      ),
      _vm.invoice && _vm.account
        ? _c(_vm.rendererType, {
            key: _vm.refreshKey,
            tag: "component",
            staticStyle: {
              "max-height": "calc(100vh - 32px)",
              height: "calc(100vh - 32px)",
            },
            attrs: {
              invoice: _vm.invoice,
              "scheduled-payments": _vm.invoice.scheduledPayments,
              account: _vm.account,
              "edit-mode": false,
              "attachment-service": _vm.invoiceService,
              "invoice-service": _vm.invoiceService,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }