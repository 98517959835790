var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._l(_vm.schemaTypes, function (category, index) {
        return _c(
          "div",
          { key: index, staticClass: "mb-3", staticStyle: { width: "100%" } },
          [
            _c("div", { staticClass: "text-left font-14 pb-3" }, [
              _vm._v(_vm._s(category.label)),
            ]),
            _c(
              "div",
              { staticClass: "row-format flex-wrap gap-2" },
              _vm._l(category.types, function (schema) {
                return _c(
                  "div",
                  {
                    key: schema.type,
                    class: `row-format schema-item align-start px-3 py-2 text-left ${
                      _vm.disableDrag ? "drag-disabled" : "drag-enabled"
                    }`,
                    attrs: { id: schema.type, draggable: !_vm.disableDrag },
                    on: {
                      click: function ($event) {
                        _vm.disableDrag ? _vm.$emit("selected", schema) : null
                      },
                      dragstart: function ($event) {
                        return _vm.$emit("dragstart", $event)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "font-14" }, [
                      _vm._v(_vm._s(schema.label)),
                    ]),
                    _c(
                      "v-icon",
                      {
                        staticClass: "material-symbols-outlined ml-auto",
                        attrs: { color: "black", size: "22" },
                      },
                      [_vm._v(_vm._s(schema.icon))]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "column-format pt-1 pb-2 text-left" }, [
      _c("div", { staticClass: "brand-bold font-18" }, [_vm._v("Elements")]),
      _c("div", { staticClass: "font-14 font-gray_80" }, [
        _vm._v("Click or drag into your document"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }