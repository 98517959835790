var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "font-14" }, [
    _c("div", { staticClass: "text-left brand-bold px-2 py-1" }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _c(
      "table",
      {
        staticClass: "text-left",
        staticStyle: {
          "border-collapse": "collapse",
          width: "100%",
          border: "1px solid #cfd3d7",
          "border-radius": "4px",
        },
      },
      _vm._l(_vm.scheduledPayments, function (payment, index) {
        return _c(
          "tr",
          {
            key: payment.id,
            class:
              _vm.invoice &&
              _vm.invoice.id &&
              payment.invoiceId === _vm.invoice.id
                ? "brand-bold"
                : "brand-light",
            style: `border-bottom: 1px solid #cfd3d7; ${
              payment.mileStoneAchieved
                ? "background-color: var(--v-gray_20-base)"
                : ""
            }`,
          },
          [
            _c(
              "td",
              { staticClass: "text-left pa-2 font-14" },
              [
                _vm.invoice &&
                _vm.invoice.id &&
                payment.invoiceId === _vm.invoice.id
                  ? _c("v-icon", { staticClass: "mr-n1 ml-n2" }, [
                      _vm._v("arrow_right"),
                    ])
                  : _vm._e(),
                _vm._v(
                  " " + _vm._s(index + 1) + ") " + _vm._s(payment.label) + " "
                ),
              ],
              1
            ),
            payment.mileStoneAchieved
              ? _c("td", { staticClass: "text-left pa-2 font-14" }, [
                  _vm._v(
                    " " +
                      _vm._s(payment.invoiceNumberFormatted) +
                      " - " +
                      _vm._s(
                        _vm.DateTime.fromISO(
                          payment.invoiceDate
                        ).toLocaleString(_vm.DateTime.DATE_MED)
                      ) +
                      " "
                  ),
                ])
              : _c("td", { staticClass: "text-left pa-2 font-14" }, [
                  _vm._v(
                    _vm._s(
                      payment.triggerType === "Date" && payment.triggerDate
                        ? _vm.DateTime.fromISO(
                            payment.triggerDate
                          ).toLocaleString(_vm.DateTime.DATE_MED)
                        : ""
                    )
                  ),
                ]),
            _c("td", { staticClass: "text-right pa-2 font-14" }, [
              _vm._v(
                _vm._s(
                  _vm.$formatters.dollars(
                    payment.calculatedAmount,
                    true,
                    true,
                    _vm.invoice.currency
                  )
                ) +
                  " (" +
                  _vm._s(Number(payment.calculatedPercentage).toFixed(0)) +
                  "%)"
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }