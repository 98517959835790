var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: `width: 100%; text-align: ${_vm.item.alignment};`,
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c("img", {
        style: _vm.imageStyle,
        attrs: { alt: "logo", src: _vm.accountLogo },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }