var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        _vm.deliverable.project.portalAccess === "Full access"
          ? "cardWrapper"
          : "",
    },
    [
      _vm.deliverable.emptyCard
        ? _c(
            "div",
            {
              staticClass: "blankCard mx-0 my-2 pl-4 pr-0 pt-2",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("add-new-empty")
                },
              },
            },
            [
              _c("v-icon", { attrs: { size: "34" } }, [_vm._v("$duplicate")]),
              _c("span", { staticClass: "font-grey" }, [
                _vm._v(_vm._s(_vm.$t("projects.add-first-deliverable"))),
              ]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "card mb-2" },
            [
              _c(
                "div",
                {
                  class: `card-wrapper ${
                    _vm.deliverable.project.portalAccess === "Full access"
                      ? "full-access"
                      : ""
                  }`,
                },
                [
                  _c(
                    "div",
                    { staticClass: "row-format mb-2" },
                    [
                      _c("div", { staticClass: "project-name ellipsis" }, [
                        _vm._v(_vm._s(_vm.deliverable.project.name)),
                      ]),
                      _vm.deliverable.invoiceId
                        ? _c(
                            "v-icon",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: {
                                    content: `Invoice: ${_vm.deliverable.invoiceNumber}`,
                                  },
                                  expression:
                                    "{content:`Invoice: ${deliverable.invoiceNumber}`}",
                                },
                              ],
                              staticClass: "ml-auto",
                              attrs: { small: "", color: "success" },
                            },
                            [_vm._v("$money")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "deliverable-name mb-2 row-format" },
                    [
                      _c("div", [_vm._v(_vm._s(_vm.deliverable.name))]),
                      _vm.showStatus
                        ? _c(
                            "div",
                            { staticClass: "ml-auto row-format align-center" },
                            [
                              _vm.showStatus
                                ? _c("div", [_vm._v(_vm._s(_vm.status.label))])
                                : _vm._e(),
                              _c("div", {
                                staticClass: "ml-2",
                                style: `width:20px; height: 20px; border-radius: 4px; background-color: ${_vm.status.hexColor}`,
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("div", { staticClass: "row-format align-center" }, [
                    _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      _vm._l(
                        _vm.deliverable.assignedToList,
                        function (assignedTo, index) {
                          return _c("assigned-user", {
                            key: assignedTo,
                            style: `${
                              index > 0 ? "margin-left: -6px" : ""
                            }; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`,
                            attrs: {
                              "show-name": false,
                              small: true,
                              "assigned-to": assignedTo,
                              "assignable-users": _vm.assignableUsers,
                            },
                          })
                        }
                      ),
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "row-format align-center",
                        staticStyle: { "margin-left": "auto" },
                      },
                      [
                        _vm.deliverable.dueDate
                          ? _c(
                              "div",
                              { staticClass: "font-12 font-grey" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { size: "12" },
                                  },
                                  [_vm._v("$clock")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.DateTime.fromISO(
                                        _vm.deliverable.dueDate
                                      ).toLocaleString({
                                        month: "short",
                                        day: "numeric",
                                      })
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.deliverable.tasks.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "font-12 font-grey ml-1 pointer task-box",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.showTasks = !_vm.showTasks
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mb-1", attrs: { size: "8" } },
                                  [_vm._v("$check")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getTaskCountLabel(
                                        _vm.deliverable.tasks
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "x-small": "" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.showTasks
                                          ? "$arrowUp"
                                          : "$arrowDown"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.deliverable.tasks.length == 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "font-12 font-grey ml-1 pointer task-box",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.readOnly ? "" : _vm.addFirstTask()
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mb-1", attrs: { size: "8" } },
                                  [_vm._v("$check")]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mb-1",
                                    staticStyle: { "margin-left": "2px" },
                                    attrs: { size: "12", color: "gray_80" },
                                  },
                                  [_vm._v("$plus")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
              _c("tasks", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTasks && _vm.deliverable.tasks.length > 0,
                    expression: "showTasks && deliverable.tasks.length > 0",
                  },
                ],
                ref: "tasks",
                attrs: { deliverable: _vm.deliverable, disabled: _vm.readOnly },
                on: {
                  empty: function ($event) {
                    _vm.showTasks = false
                  },
                  "tasks-updated": function ($event) {
                    return _vm.updateTasks()
                  },
                },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }