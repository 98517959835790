var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "pa-0 ma-0",
      style: `min-height: ${_vm.item.minHeight}px; ${_vm.background}`,
      attrs: { fluid: "", id: `container-${_vm.item.id}` },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c(
        "v-row",
        { staticClass: "pa-0 ma-0" },
        _vm._l(_vm.filteredColumns, function (column, colIndex) {
          return _c(
            "v-col",
            {
              key: column.id,
              staticClass: "column-format",
              style: `width:${_vm.width}%; padding-left: ${_vm.padding(
                column.pl
              )}px; padding-right: ${_vm.padding(
                column.pr
              )}px; padding-bottom: ${column.pb}px; padding-top: ${
                column.pt
              }px; min-height:40px `,
              attrs: { cols: _vm.cols },
              on: {
                click: function ($event) {
                  return _vm.$emit("click", $event)
                },
              },
            },
            [
              _c(
                "draggable",
                {
                  attrs: {
                    list: column.items,
                    group: _vm.item.id,
                    disabled: !_vm.active,
                  },
                },
                _vm._l(column.items, function (item, subIndex) {
                  return _c(
                    "div",
                    { key: item.id },
                    [
                      _c("item-renderer", {
                        ref: `item-${item.id}`,
                        refInFor: true,
                        attrs: {
                          index: `${_vm.index}.${colIndex}.${subIndex}`,
                          item: item,
                          agreement: _vm.agreement,
                          styles: _vm.styles,
                          "in-container": true,
                          "account-logo": _vm.accountLogo,
                          "mce-config": _vm.mceConfig,
                          active: false,
                          "client-mode": _vm.clientMode,
                          "edit-mode": _vm.editMode,
                          tokens: _vm.tokens,
                          signer: _vm.signer,
                          "template-mode": _vm.templateMode,
                          "page-width": _vm.pageWidth,
                          "is-mobile": _vm.isMobile,
                        },
                        on: {
                          click: function ($event) {
                            _vm.active
                              ? _vm.containerItemClick(colIndex, item, $event)
                              : null
                          },
                          change: function ($event) {
                            return _vm.containerItemUpdated(colIndex, $event)
                          },
                          signed: function ($event) {
                            return _vm.containerItemSigned(colIndex, $event)
                          },
                          "drag-enabled": function ($event) {
                            return _vm.$emit("drag-enabled", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.active
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": true,
                        "close-on-click": true,
                        bottom: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        small: "",
                                        text: "",
                                        color: "primary",
                                      },
                                    },
                                    on
                                  ),
                                  [_vm._v("+ Add element")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pa-2",
                          staticStyle: {
                            "background-color": "var(--v-white-base)",
                          },
                        },
                        _vm._l(_vm.schemaTypes, function (category, sIndex) {
                          return _c(
                            "div",
                            {
                              key: sIndex,
                              staticClass: "mb-2 pb-1",
                              staticStyle: {
                                "border-bottom":
                                  "1px solid var(--v-gray_30-base)",
                              },
                            },
                            [
                              _c("div", { staticClass: "text-left" }, [
                                _vm._v(_vm._s(category.label)),
                              ]),
                              _vm._l(
                                category.types.filter(
                                  (t) => t.allowInContainer
                                ),
                                function (schema) {
                                  return _c(
                                    "div",
                                    {
                                      key: schema.type,
                                      staticClass:
                                        "row-format align-center schema-item pa-1 gap-2",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addItem(
                                            _vm.item,
                                            colIndex,
                                            schema,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "material-symbols-outlined",
                                          attrs: {
                                            color: "secondary",
                                            size: "24",
                                          },
                                        },
                                        [_vm._v(_vm._s(schema.icon))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "column-format text-left",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "font-14" },
                                            [_vm._v(_vm._s(schema.label))]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "font-12 mt-n1 font-gray_70",
                                            },
                                            [_vm._v(_vm._s(schema.description))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }