var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("tag-user-field", {
                ref: "tagUserField",
                attrs: { commentToEdit: null },
                on: {
                  sendComment: function ($event) {
                    return _vm.sendComment($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            _vm._l(_vm.sortedActivity, function (comment, index) {
              return _c(
                "div",
                {
                  key: comment.id,
                  class: _vm.canEdit(comment, index) ? "can-edit" : "",
                  staticStyle: { "word-wrap": "anywhere" },
                  on: {
                    mouseover: function ($event) {
                      return _vm.setHoverIndex(index)
                    },
                    mouseleave: function ($event) {
                      return _vm.setHoverIndex(null)
                    },
                  },
                },
                [
                  comment.events && _vm.showEvents
                    ? _c(
                        "div",
                        {
                          staticClass: "font-12 text-left my-2 brand-semilight",
                        },
                        [
                          _c("div", [
                            _c("span", { staticClass: "brand-medium" }, [
                              _vm._v(_vm._s(comment.user)),
                            ]),
                            _vm._v(
                              " " + _vm._s(comment.events.join(" and ")) + " "
                            ),
                          ]),
                          _c(
                            "div",
                            { staticStyle: { color: "var(--v-gray_70-base)" } },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "12", color: "gray_70" } },
                                [_vm._v("$clock")]
                              ),
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatForTimeAgo")(comment.timestamp)
                                ) + " "
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  comment.comment
                    ? _c("div", { staticClass: "mt-2 comment-box" }, [
                        _c(
                          "div",
                          { staticClass: "row-format align-center font-12" },
                          [
                            _c("div", { staticClass: "ml-1" }, [
                              _vm._v(" " + _vm._s(comment.author) + " "),
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-3 mr-1",
                                attrs: { size: "14" },
                              },
                              [_vm._v("$clock")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: { color: "var(--v-gray_70-base)" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatForTimeAgo")(
                                        comment.timestamp
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _vm.canEdit(comment, index)
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-left": "auto" } },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pointer mr-2",
                                        attrs: { small: "", color: "grey" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.editComment(
                                              comment,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("$edit")]
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pointer",
                                        attrs: { small: "", color: "grey" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.confirmDelete(comment)
                                          },
                                        },
                                      },
                                      [_vm._v("$delete")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.editIndex !== index
                          ? _c("div", {
                              staticClass: "pl-1 font-14 comment",
                              staticStyle: {
                                width: "100%",
                                "text-align": "left",
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.formatMarkdown(comment.comment)
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm.editIndex === index
                          ? _c(
                              "div",
                              {
                                staticClass: "pl-1 comment",
                                staticStyle: {
                                  width: "100%",
                                  "text-align": "left",
                                },
                              },
                              [
                                _c("tag-user-field", {
                                  attrs: { commentToEdit: _vm.commentToEdit },
                                  on: {
                                    sendComment: function ($event) {
                                      return _vm.sendComment($event)
                                    },
                                    saveEdit: function ($event) {
                                      return _vm.saveEdit($event)
                                    },
                                    cancelEdit: function ($event) {
                                      return _vm.cancelEdit($event)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          "heading-text": _vm.$t("global.confirm"),
          "yes-text": _vm.$t("global.delete"),
          "no-text": _vm.$t("global.cancel"),
          "body-text": _vm.$t("projects.delete-note"),
        },
        on: {
          close: function ($event) {
            return _vm.cancelDelete()
          },
          confirm: function ($event) {
            return _vm.deleteComment()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }