var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row-format centered",
      style: `height:${_vm.headerHeight}`,
      attrs: { id: "project-kanban" },
    },
    [
      _c(
        "div",
        { staticClass: "my-0 py-0", attrs: { id: "kanban-container" } },
        [
          _c(
            "div",
            { staticClass: "ml-1 mr-3", attrs: { id: "kanban-v-row" } },
            [
              _c("div", { staticClass: "row-format" }, [
                _vm.cards["unknown"].length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "kanban-v-col",
                        style:
                          "--kanban-width: " + _vm.columns.length * 272 + "px",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "kanban-column",
                            attrs: { id: "id.unknown" },
                          },
                          [
                            _c("div", { staticClass: "row-format" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "kanban-label",
                                  staticStyle: {
                                    "white-space": "nowrap",
                                    "padding-bottom": "10px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "[ " +
                                      _vm._s(_vm.$t("projects.no-status")) +
                                      " ]"
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { attrs: { id: "unknown" } },
                              [
                                _c(
                                  "draggable",
                                  {
                                    attrs: {
                                      list: _vm.cards["unknown"],
                                      group: { name: "card", pull: "clone" },
                                      tag: "section",
                                      emptyInsertThreshold: 100,
                                      animation: 200,
                                      handle: _vm.$store.state.isMobile
                                        ? "disabled"
                                        : ".cardWrapper",
                                      "ghost-class": "ghost",
                                      move: _vm.cardDragHandler,
                                    },
                                    on: { end: _vm.endDragHandler },
                                  },
                                  _vm._l(
                                    _vm.cards["unknown"],
                                    function (deliverable) {
                                      return _c(
                                        "div",
                                        {
                                          key: deliverable.id,
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editDeliverable(
                                                deliverable
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("kanban-card", {
                                            attrs: {
                                              deliverable: deliverable,
                                              "show-status": false,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "row-format" },
                  _vm._l(_vm.columns, function (column) {
                    return _c("div", { key: column.id }, [
                      _c("div", { staticClass: "kanban-v-col" }, [
                        _c(
                          "div",
                          {
                            staticClass: "kanban-column pointer",
                            attrs: { id: `id.${column.id}` },
                            on: {
                              click: function ($event) {
                                return _vm.addDeliverable(column)
                              },
                            },
                          },
                          [
                            _c("kanban-header", {
                              attrs: {
                                "all-columns": _vm.columns,
                                column: column,
                                "is-collaborator": _vm.isCollaborator,
                                "allow-add-deliverable":
                                  _vm.hasFullAccessProjects,
                              },
                              on: {
                                "add-deliverable": function ($event) {
                                  return _vm.addDeliverableNoWait($event)
                                },
                              },
                            }),
                            _c(
                              "draggable",
                              {
                                class: `draggable-container ${
                                  _vm.$store.state.isMac ? "hidden-scroll" : ""
                                }`,
                                style: `height: calc(100vh - ${
                                  _vm.headerHeight + 57
                                }px); overflow-y: auto;`,
                                attrs: {
                                  list: _vm.cards["id" + column.id],
                                  group: { name: "card", pull: "clone" },
                                  tag: "section",
                                  emptyInsertThreshold: 100,
                                  animation: 200,
                                  handle: _vm.$store.state.isMobile
                                    ? "disabled"
                                    : ".cardWrapper",
                                  "ghost-class": "ghost",
                                  move: _vm.cardDragHandler,
                                },
                                on: { end: _vm.endDragHandler },
                              },
                              _vm._l(
                                _vm.cards["id" + column.id],
                                function (deliverable) {
                                  return _c(
                                    "div",
                                    {
                                      key: deliverable.id,
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.editDeliverable(
                                            deliverable
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("kanban-card", {
                                        attrs: {
                                          deliverable: deliverable,
                                          "assignable-users":
                                            _vm.assignableUsers,
                                          "status-list": _vm.columns,
                                        },
                                        on: {
                                          "add-new": function ($event) {
                                            return _vm.addDeliverable(column)
                                          },
                                          "add-new-empty": function ($event) {
                                            return _vm.addDeliverableNoWait(
                                              column
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }