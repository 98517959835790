var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.agreement
    ? _c(
        "div",
        {
          key: _vm.agreement.id,
          staticClass: "agreement-builder",
          style: `--v-zoom: ${_vm.zoom / 100}; ${_vm.styles}`,
        },
        [
          _c("div", { staticClass: "header-area px-3" }, [
            _vm.$vuetify.breakpoint.mdAndUp
              ? _c("div", { staticClass: "header-left" }, [
                  _c(
                    "span",
                    {
                      style: `font-family: '${_vm.agreement.styles.h1.fontFamily}'; font-weight:600; font-size: 20px`,
                    },
                    [_vm._v(_vm._s(_vm.agreement.name))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "row-format align-center ml-2 pointer",
                      on: {
                        click: function ($event) {
                          return _vm.openPrint()
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-outlined",
                          attrs: {
                            size: "20",
                            color: _vm.agreement.styles.h1.color,
                          },
                        },
                        [_vm._v("print")]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-outlined",
                          attrs: {
                            size: "20",
                            color: _vm.agreement.styles.h1.color,
                          },
                        },
                        [_vm._v("picture_as_pdf")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.signer
              ? _c(
                  "div",
                  { staticClass: "header-right" },
                  [
                    _vm.showFinishWarning
                      ? _c(
                          "div",
                          {
                            staticClass: "font-weight-medium",
                            staticStyle: {
                              color: "red",
                              "text-decoration": "underline",
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "red", size: "48" } },
                              [_vm._v("arrow_right_alt")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.signer.locked
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "super-action ml-2",
                            on: { click: _vm.finalize },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "px-6",
                                style: `font-family: '${_vm.agreement.styles.h1.fontFamily}';font-size:16px`,
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.agreement.settings.finishedButton
                                      ? _vm.agreement.settings.finishedButton
                                      : "Finish"
                                  )
                                ),
                              ]
                            ),
                          ]
                        )
                      : _c(
                          "v-btn",
                          {
                            staticClass: "super-action ml-2",
                            attrs: { disabled: "" },
                          },
                          [
                            _c("v-icon", [_vm._v("done_all")]),
                            _c(
                              "span",
                              {
                                style: `font-family: '${_vm.agreement.styles.h1.fontFamily}';font-size:16px`,
                              },
                              [
                                _vm._v(
                                  "  " +
                                    _vm._s(
                                      _vm.DateTime.fromISO(
                                        _vm.signer.timestamp
                                      ).toLocaleString(
                                        _vm.DateTime.DATETIME_MED
                                      )
                                    )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "row-format",
              staticStyle: { height: "calc(100% - 50px)" },
            },
            [
              _vm.indexedPages.length && _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "div",
                    {
                      staticClass: "column-format pb-3 px-2",
                      staticStyle: {
                        "border-right": "1px solid var(--v-gray_30-base)",
                        height: "100%",
                        "max-height": "100%",
                        "background-color": "var(--v-white-base)",
                        "min-width": "250px",
                        "max-width": "250px",
                      },
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "text-left font-14 py-2" },
                        _vm._l(_vm.indexedPages, function (page) {
                          return _c(
                            "li",
                            {
                              key: page.index,
                              staticClass: "pointer py-1 font-16 nav-link",
                              style: `border-bottom: 1px solid var(--v-gray_30-base); font-family: '${_vm.agreement.styles.h1.fontFamily}';`,
                              on: {
                                click: function ($event) {
                                  return _vm.scrollToPage(page.index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(page.title) + " ")]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "show-scrollbar",
                  staticStyle: {
                    "max-height": "calc(100vh - 50px)",
                    width: "100%",
                    "overflow-y": "scroll",
                  },
                  attrs: { id: "page-area" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "zoom",
                      staticStyle: {
                        "min-width": "fit-content",
                        width: "100%",
                        "overflow-x": "scroll",
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "print-area" } },
                        _vm._l(_vm.agreement.pages, function (page, index) {
                          return _c(
                            "div",
                            {
                              key: page.id,
                              staticClass: "column-format centered",
                              attrs: { id: `page-${index}` },
                            },
                            [
                              _c("page", {
                                ref: `page-${index}`,
                                refInFor: true,
                                attrs: {
                                  id: `page-${index}`,
                                  "page-index": index,
                                  agreement: _vm.agreement,
                                  "mce-config": {},
                                  "client-mode": _vm.clientMode,
                                  signer: _vm.signer,
                                  "edit-mode": false,
                                  "template-mode": false,
                                  tokens: _vm.tokens,
                                  "page-width": _vm.pageWidth,
                                },
                                on: {
                                  "container-item-signed": function ($event) {
                                    return _vm.itemSigned(page, $event)
                                  },
                                  signed: function ($event) {
                                    return _vm.itemSigned(page, $event)
                                  },
                                },
                              }),
                              _c("div", { staticClass: "page-break" }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm.signer
                        ? _c(
                            "div",
                            { staticClass: "row-format centered my-4" },
                            [
                              !_vm.signer.locked
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "super-action",
                                      attrs: { large: "" },
                                      on: { click: _vm.finalize },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "px-10",
                                          style: `font-family: '${_vm.agreement.styles.h1.fontFamily}';font-size:16px`,
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.agreement.settings
                                                .finishedButton
                                                ? _vm.agreement.settings
                                                    .finishedButton
                                                : "Finish"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm.agreement.styles.pageSize !== "RESPONSIVE"
            ? _c(
                "div",
                {
                  staticClass: "row-format align-center",
                  staticStyle: {
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                  },
                },
                [
                  _c("div", { staticClass: "font-12" }, [
                    _vm._v(_vm._s(_vm.zoom) + "%"),
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "pointer",
                      attrs: { size: "24" },
                      on: {
                        click: function ($event) {
                          return _vm.zoomIn()
                        },
                      },
                    },
                    [_vm._v("zoom_in")]
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "pointer",
                      attrs: { size: "24" },
                      on: {
                        click: function ($event) {
                          return _vm.zoomOut()
                        },
                      },
                    },
                    [_vm._v("zoom_out")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }