var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "item-details-modal" } },
    [
      _c(
        "v-container",
        { staticClass: "pa-0 ma-0" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("invoice.item-details-modal.heading", {
                        invoiceNumber: _vm.invoice.invoiceNumber,
                      })
                    )
                  ),
                ]),
                _vm.item
                  ? _c("p", { staticClass: "mt-4" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("invoice.item-details-modal.subheading", {
                              description: _vm.item.description,
                            })
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.timerEvents
                    ? _c("v-data-table", {
                        staticClass: "row-pointer mb-6",
                        attrs: {
                          id: "timer-events-table",
                          "hide-default-header": this.$vuetify.breakpoint.xsOnly
                            ? true
                            : false,
                          headers: _vm.headers,
                          "sort-by": "dateDisplay",
                          "sort-desc": true,
                          items: _vm.timerEvents,
                          "hide-default-footer": true,
                          "disable-pagination": true,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.dateDisplay",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "6px" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.dateDisplay) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.timeWorkedSortable",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "6px" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.timeWorked) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.projectName",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("empty")(item.projectName)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.deliverableName",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("empty")(
                                              item.deliverableName
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.notes",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [_vm._v(" " + _vm._s(item.notes) + " ")]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.xsRenderSlot",
                              fn: function ({ item }) {
                                return [
                                  _c("div", { staticClass: "row-format" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.dateDisplay) +
                                        " — " +
                                        _vm._s(item.timeWorked) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          926451105
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-4 mt-0", attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { staticClass: "px-16", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("global.close")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }