var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "h-header",
        [
          _c("meeting-list-filter", {
            attrs: { slot: "filter" },
            on: {
              "clear-filter": function ($event) {
                return _vm.clearFilter()
              },
            },
            slot: "filter",
            model: {
              value: _vm.filter,
              callback: function ($$v) {
                _vm.filter = $$v
              },
              expression: "filter",
            },
          }),
        ],
        1
      ),
      _vm.isReady
        ? _c(
            "div",
            {
              staticClass: "column-format py-3 pl-3 show-scrollbar",
              staticStyle: { flex: "1", height: "var(--vh)" },
            },
            [
              _c("v-data-table", {
                key: _vm.tableKey,
                staticStyle: { cursor: "pointer" },
                attrs: {
                  headers: _vm.headers,
                  items: _vm.filteredMeetingList,
                  "items-per-page": 10,
                  "sort-by": "confirmedTime.start",
                  "sort-asc": "",
                  "hide-default-footer": _vm.scheduledMeetings.length <= 10,
                },
                on: {
                  "click:row": function ($event) {
                    return _vm.openScheduledMeeting($event)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.confirmedTime.start",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            {
                              class: `${
                                item.meetingStatus === "Cancelled"
                                  ? "cancelled"
                                  : ""
                              }`,
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.DateTime.fromISO(
                                    item.confirmedTime.start
                                  ).toFormat("ccc, LLL dd '@' t")
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.meetingName",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            {
                              class: `${
                                item.meetingStatus === "Cancelled"
                                  ? "cancelled"
                                  : ""
                              }`,
                            },
                            [_vm._v(_vm._s(item.meetingName))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.contact",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            {
                              class: `${
                                item.meetingStatus === "Cancelled"
                                  ? "cancelled"
                                  : ""
                              }`,
                            },
                            [
                              _vm._v(
                                _vm._s(item.formData.firstName) +
                                  " " +
                                  _vm._s(item.formData.lastName)
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "no-data",
                      fn: function () {
                        return [
                          !_vm.scheduledMeetings.length
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "meetings.booked.no-meetings-booked"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "meetings.booked.no-matching-criteria"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3259874387
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }