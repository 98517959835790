var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isConditionMet
    ? _c(
        "div",
        {
          staticClass: "text-left",
          on: {
            click: function ($event) {
              return _vm.$emit("click", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-question" },
            [
              _vm._v(_vm._s(_vm.item.question)),
              _vm.item.required
                ? _c("span", { staticClass: "font-red brand-medium" }, [
                    _vm._v("*"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showConditionalHelper
                ? _c("v-icon", { attrs: { small: "", color: "gray_50" } }, [
                    _vm._v("account_tree"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
              model: {
                value: _vm.isValid,
                callback: function ($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid",
              },
            },
            [
              _vm.isPhoneInput
                ? _c("div", { class: _vm.classList }, [
                    _c("div", { staticClass: "v-input__control" }, [
                      _c(
                        "div",
                        {
                          staticClass: "v-input__slot",
                          staticStyle: { padding: "0!important" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "v-text-field__slot",
                              staticStyle: { padding: "0!important" },
                            },
                            [
                              _vm.outlined
                                ? _c(
                                    "fieldset",
                                    {
                                      style: `border-radius: 4px!important; ${
                                        !this.phoneIsValid
                                          ? "border: 2px solid var(--v-error-base)!important;"
                                          : ""
                                      }`,
                                      attrs: { "aria-hidden": "true" },
                                    },
                                    [
                                      _c(
                                        "legend",
                                        { staticStyle: { width: "0px" } },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "notranslate" },
                                            [_vm._v("​")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("vue-tel-input", {
                                staticClass: "ml-0 custom-phone",
                                attrs: {
                                  "preferred-countries": _vm.preferredCountries,
                                  disabled: _vm.disabled,
                                  name: "phone",
                                  autocomplete: "phone",
                                  inputOptions: {
                                    placeholder: _vm.item.placeholder,
                                  },
                                },
                                on: { input: _vm.phoneUpdated },
                                model: {
                                  value: _vm.textInput,
                                  callback: function ($$v) {
                                    _vm.textInput = $$v
                                  },
                                  expression: "textInput",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _c("v-text-field", {
                    staticClass: "form-input",
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      placeholder: _vm.item.placeholder,
                      outlined: _vm.outlined,
                      solo: _vm.solo,
                      filled: _vm.filled,
                      disabled: _vm.disabled,
                      rules: _vm.rules,
                      name: _vm.fieldName,
                      autocomplete: _vm.autoComplete,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.handleInput()
                      },
                    },
                    model: {
                      value: _vm.textInput,
                      callback: function ($$v) {
                        _vm.textInput = $$v
                      },
                      expression: "textInput",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }