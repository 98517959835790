var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "proposal-viewer",
      staticStyle: {
        width: "100%",
        "background-color": "var(--v-gray_10-base)",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "header-buttons d-print-none row-format centered gap-2 py-4",
        },
        [
          _vm.proposal
            ? _c("comments-menu", {
                attrs: {
                  comments: _vm.proposal.comments,
                  "proposal-id": _vm.proposal.id,
                  "client-id": _vm.proposal.clientId,
                  "proposal-service": _vm.proposalService,
                  "client-mode": true,
                  "viewed-timestamps": _vm.proposal.viewedTimestamps,
                },
              })
            : _vm._e(),
          _vm.$vuetify.breakpoint.smAndUp
            ? _c(
                "v-btn",
                {
                  staticClass: "primary-action",
                  on: {
                    click: function ($event) {
                      return _vm.print()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "row-format align-center" },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("$paperClip"),
                      ]),
                      _c("div", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.$t("proposals.print-save-pdf"))),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "v-btn",
            { staticClass: "primary-action" },
            [
              _c("language-switcher", {
                attrs: {
                  label: _vm.$t("settings.language"),
                  children: _vm.countries.getCountriesTranslate(),
                  icon: "translate",
                },
                on: {
                  selected: function ($event) {
                    return _vm.switchLocale($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isReady
        ? _c("proposal-renderer", {
            attrs: {
              proposal: _vm.proposal,
              account: _vm.account,
              "proposal-service": _vm.proposalService,
              "edit-mode": false,
              "pod-service": null,
              "client-service": null,
              "need-save": false,
              saving: false,
              "client-mode": true,
              "is-locked": _vm.isLocked,
              "card-payment-failed": _vm.cardPaymentFailed,
            },
            on: {
              "deliverable-selected": function ($event) {
                return _vm.deliverableSelected($event)
              },
              "client-signed": function ($event) {
                return _vm.clientFinalized($event)
              },
              "client-accepted": function ($event) {
                return _vm.clientFinalized($event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }