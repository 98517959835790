var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0 selector_files", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { style: _vm.headerStyle, attrs: { cols: "12" } },
            [
              !_vm.editMode
                ? _c("span", { domProps: { innerHTML: _vm._s(_vm.lHeader) } })
                : _vm._e(),
              _vm.editMode
                ? _c("editor", {
                    ref: "headerEditor",
                    staticClass: "grey-dots",
                    attrs: {
                      "api-key": _vm.$store.getters.getTinyMceKey,
                      "initial-value": _vm.lHeader,
                      inline: true,
                      init: _vm.mceConfigHeader,
                    },
                    on: { input: _vm.handleHeaderInput },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticStyle: { "text-align": "left" }, attrs: { cols: "12" } },
            [
              _c(
                "span",
                { style: _vm.bodyStyle },
                [
                  !_vm.editMode
                    ? _c("span", { domProps: { innerHTML: _vm._s(_vm.lText) } })
                    : _vm._e(),
                  _vm.editMode
                    ? _c("editor", {
                        ref: "textEditor",
                        staticClass: "grey-dots",
                        attrs: {
                          "api-key": _vm.$store.getters.getTinyMceKey,
                          "initial-value": _vm.lText,
                          inline: true,
                          init: _vm.mceConfigText,
                        },
                        on: { input: _vm.handleTextInput },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("v-col", { staticClass: "px-0 mx-0", attrs: { cols: "12" } }, [
            _c(
              "div",
              { staticClass: "fileWrapper" },
              _vm._l(this.localFiles, function (file) {
                return _c(
                  "div",
                  { key: file.fileName, staticClass: "fileContainer" },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: { size: "48" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(file)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.iconName(file)))]
                    ),
                    _c("div", { staticClass: "font-14" }, [
                      _vm._v(_vm._s(file.fileName)),
                    ]),
                    _vm.editMode
                      ? _c(
                          "div",
                          { staticClass: "deleteButton" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "deleteIcon",
                                attrs: { icon: "", color: "gray_50" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDeleteFile(file)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("$delete"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _vm.editMode
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mx-0 px-0", attrs: { cols: "12" } },
                [
                  _c(
                    "vue-dropzone",
                    {
                      ref: "fileDropzone",
                      attrs: {
                        id: "dropzone",
                        "use-custom-slot": true,
                        options: _vm.dropzoneOptions,
                      },
                      on: {
                        "vdropzone-success": _vm.uploadSuccess,
                        "vdropzone-error": _vm.uploadFailure,
                        "vdropzone-sending": _vm.addFileUploadAuthHeader,
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("v-icon", [_vm._v("$vuetify.icons.upload")]),
                          _c(
                            "span",
                            {
                              staticClass: "ml-2",
                              staticStyle: { "font-size": "0.8em" },
                            },
                            [_vm._v(_vm._s(_vm.$t("proposal.files.upload")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.confirmDelete,
            callback: function ($$v) {
              _vm.confirmDelete = $$v
            },
            expression: "confirmDelete",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "brand-regular" },
            [
              _c("v-card-text", { staticClass: "pt-4" }, [
                _vm._v(_vm._s(_vm.$t("proposal.files.delete"))),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelDeleteFile()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("global.no")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteFile()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("global.yes")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }