var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.actionItems
    ? _c(
        "div",
        { staticClass: "column-format font-14 ma-3" },
        [
          _vm.features.projectsEnabled
            ? _c("deliverables", {
                staticClass: "action-item",
                attrs: { deliverables: _vm.actionItems.deliverables },
                on: { refresh: _vm.getActionItems },
              })
            : _vm._e(),
          _vm.features.invoicesEnabled
            ? _c("invoices", {
                staticClass: "action-item",
                attrs: { invoices: _vm.actionItems.invoices },
                on: { refresh: _vm.getActionItems },
              })
            : _vm._e(),
          _vm.features.formsEnabled
            ? _c("forms", {
                staticClass: "action-item",
                attrs: { forms: _vm.actionItems.forms },
                on: { refresh: _vm.getActionItems },
              })
            : _vm._e(),
          _vm.features.proposalsEnabled
            ? _c("proposals", {
                staticClass: "action-item",
                attrs: {
                  proposals: _vm.actionItems.proposals,
                  agreements: _vm.actionItems.agreements,
                },
                on: { refresh: _vm.getActionItems },
              })
            : _vm._e(),
          _vm.features.meetingsEnabled
            ? _c("meetings", {
                staticClass: "action-item",
                attrs: { meetings: _vm.actionItems.meetings },
                on: { refresh: _vm.getActionItems },
              })
            : _vm._e(),
          _vm.features.invoicesEnabled
            ? _c("subscription-requests", {
                staticClass: "action-item",
                attrs: {
                  "subscription-requests": _vm.actionItems.subscriptionRequests,
                },
                on: { refresh: _vm.getActionItems },
              })
            : _vm._e(),
          _vm.actionCount === 0
            ? _c(
                "div",
                { staticStyle: { "text-align": "center", width: "100%" } },
                [
                  _c("div", { staticClass: "font-14 font-gray_70" }, [
                    _vm._v("Zero notifications found"),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }