var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      !_vm.disabled
        ? _c(
            "v-row",
            { staticClass: "hello" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", align: "left" } },
                [
                  _c(
                    "vue-dropzone",
                    {
                      ref: "fileDropzone",
                      staticClass: "pa-0 ma-0 drop row-format centered",
                      staticStyle: { "border-radius": "4px" },
                      attrs: {
                        id: "dropzone",
                        "use-custom-slot": true,
                        options: _vm.dropzoneOptions,
                      },
                      on: {
                        "vdropzone-success": _vm.uploadSuccess,
                        "vdropzone-error": _vm.uploadFailure,
                        "vdropzone-sending": _vm.addFileUploadAuthHeader,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row-format centered" },
                        [
                          _c("v-icon", { attrs: { size: "35" } }, [
                            _vm._v("$attachment"),
                          ]),
                          _c("br"),
                          _c("div", {
                            staticClass: "font-12 text-left ml-2",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("projects.deliverable-upload")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            _vm._l(_vm.deliverable.files, function (file) {
              return _c("div", { key: file.fileName }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-center attachment-wrapper",
                    staticStyle: { width: "100%", position: "relative" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "buttonOverlay row-format px-6",
                        staticStyle: { "justify-content": "space-between" },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pointer",
                            attrs: { color: "gray_80" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.downloadFile(file)
                              },
                            },
                          },
                          [_vm._v(" $download ")]
                        ),
                        _vm.project.portalAccess === "Full access"
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "pointer",
                                attrs: { color: "gray_80" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.deleteFile(file)
                                  },
                                },
                              },
                              [_vm._v("$delete")]
                            )
                          : _vm._e(),
                        _vm.isOpenableFile(file)
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "pointer",
                                attrs: { color: "gray_80" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.downloadFile(file, false)
                                  },
                                },
                              },
                              [_vm._v("open_in_new")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "fileContainer" },
                      [
                        _c("v-img", {
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            contain: "",
                            src: _vm.getFilePreview(file),
                            width: "56",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "fileName ml-2" }, [
                      _vm._v(_vm._s(file.fileName)),
                    ]),
                  ]
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }