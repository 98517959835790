var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pointer v-event-draggable row-format pb-2",
      staticStyle: {
        "align-items": "center",
        "justify-content": "space-between",
      },
      on: {
        mousedown: function ($event) {
          $event.stopPropagation()
          return _vm.startDrag(_vm.event)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "row-format",
          staticStyle: { "align-items": "center", width: "90%" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.editDeliverable(_vm.event)
            },
          },
        },
        [
          _c("div", {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { content: _vm.tippyLabel },
                expression: "{ content: tippyLabel }",
              },
            ],
            staticClass: "pl-1 eventLabel no-select",
            staticStyle: { "font-size": "12px" },
            domProps: { innerHTML: _vm._s(_vm.event.name) },
          }),
        ]
      ),
      _c("div", {
        directives: [
          {
            name: "tippy",
            rawName: "v-tippy",
            value: { content: _vm.status.label },
            expression: "{ content: status.label }",
          },
        ],
        staticClass: "coloredCircle",
        style: `--circle-color: ${_vm.status.hexColor}`,
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }