var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "show-scrollbar",
      staticStyle: { "min-height": "100%" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { align: "center" } },
            [
              _c("gantt-chart", {
                attrs: {
                  deliverables: _vm.deliverablesWithDates,
                  "start-date": _vm.start,
                  "end-date": _vm.end,
                  "assignable-users": _vm.assignableUsers,
                },
                on: {
                  "edit-deliverable": function ($event) {
                    return _vm.$emit("edit-deliverable", $event)
                  },
                  "dates-updated": function ($event) {
                    return _vm.$emit("dates-updated", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }