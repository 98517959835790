var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.form
    ? _c(
        "div",
        {
          class: `${
            _vm.inFrame ? "outer-wrapper-in-frame" : "outer-wrapper"
          } row-format centered`,
          style: `font-family: ${_vm.font}; --background-color: ${_vm.form.accentColor}`,
        },
        [
          _vm.form
            ? _c(
                "v-container",
                {
                  class: `${
                    _vm.inFrame ? "main-wrapper-in-frame" : "main-wrapper"
                  }`,
                },
                [
                  _vm.form.showLogo && _vm.form.accountLogo
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("img", {
                              staticStyle: {
                                "max-height": "100px",
                                "max-width": "250px",
                              },
                              attrs: { src: _vm.form.accountLogo },
                            }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.complete
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "mx-4" },
                              [
                                _c("renderer", {
                                  ref: "renderer",
                                  attrs: { schema: _vm.form.schema },
                                  on: { input: _vm.handleResult },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "mx-8 mt-4" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      width: "150",
                                      color: _vm.form.accentColor,
                                      elevation: "0",
                                    },
                                    on: { click: _vm.saveForm },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        style: `font-weight: 600; color: ${_vm.form.buttonText}!important; text-transform:none!important`,
                                      },
                                      [_vm._v(_vm._s(_vm.$t("global.submit")))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "font-10 font-gray_70 mt-8" },
                                  [
                                    _vm._v(
                                      " This page is protected by reCAPTCHA and the Google "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "https://policies.google.com/privacy",
                                        },
                                      },
                                      [_vm._v("Privacy Policy")]
                                    ),
                                    _vm._v(" and "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "https://policies.google.com/terms",
                                        },
                                      },
                                      [_vm._v("Terms of Service")]
                                    ),
                                    _vm._v(" apply. "),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _vm._v(_vm._s(_vm.$t("discovery.complete"))),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-snackbar",
            {
              attrs: { top: "", color: "gray_80", timeout: 5000 },
              scopedSlots: _vm._u(
                [
                  {
                    key: "action",
                    fn: function ({ attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._b(
                            {
                              attrs: { color: "error", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.showError = false
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          [_vm._v(" OK ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                441339782
              ),
              model: {
                value: _vm.showError,
                callback: function ($$v) {
                  _vm.showError = $$v
                },
                expression: "showError",
              },
            },
            [_vm._v(" " + _vm._s(_vm.errorText) + " ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }