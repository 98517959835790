var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        {
          style: _vm.scheduledMeeting.formData.answers.length
            ? "min-width: 60vw; max-width: 60vw;"
            : "width:500px",
          attrs: { id: "meeting-detail" },
        },
        [
          _c(
            "div",
            {
              staticClass: "pa-5 d-flex",
              attrs: { id: "meeting-inner-wrapper" },
            },
            [
              _c(
                "div",
                { staticClass: "row-format mb-2 align-center" },
                [
                  _vm.view === "MAIN"
                    ? _c("div", { staticClass: "brand-medium font-18 ml-1" }, [
                        _vm._v(_vm._s(_vm.scheduledMeeting.meetingName)),
                      ])
                    : _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              _vm.view = "MAIN"
                            },
                          },
                        },
                        [_vm._v("$arrowLeft")]
                      ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-auto pointer",
                      attrs: { size: "20" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("result")
                        },
                      },
                    },
                    [_vm._v("$close")]
                  ),
                ],
                1
              ),
              _vm.view === "MAIN"
                ? _c("div", [
                    _c(
                      "div",
                      [
                        _c(
                          "v-container",
                          { staticClass: "ml-0 pl-1" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "ml-0",
                                    attrs: {
                                      md: _vm.scheduledMeeting.formData.answers
                                        .length
                                        ? 6
                                        : 12,
                                      cols: "12",
                                      align: "left",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "pa-2 ma-0 font-14" },
                                      [
                                        _vm.isCancelled
                                          ? _c(
                                              "v-row",
                                              { attrs: { dense: "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "brand-medium",
                                                    attrs: { cols: "3" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "meetings.detail.status"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "brand-medium",
                                                    staticStyle: {
                                                      color:
                                                        "var(--v-error-base)",
                                                    },
                                                    attrs: { cols: "8" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "meetings.detail.cancelled"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-row",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "brand-medium",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "meetings.detail.name"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "8" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.scheduledMeeting
                                                      .formData.firstName
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.scheduledMeeting
                                                        .formData.lastName
                                                    )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "brand-medium",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "meetings.detail.email"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "8" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.scheduledMeeting
                                                      .formData.email
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "brand-medium",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "meetings.detail.phone"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "8" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.scheduledMeeting
                                                      .formData.phone
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "brand-medium",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "meetings.detail.time"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                class: _vm.isCancelled
                                                  ? "strike"
                                                  : "",
                                                attrs: { cols: "8" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.DateTime.fromISO(
                                                      _vm.scheduledMeeting
                                                        .confirmedTime.start
                                                    ).toFormat(
                                                      "cccc, LLL dd yyyy '@' t"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "brand-medium",
                                                attrs: { cols: "3" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "meetings.detail.duration"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                class: _vm.isCancelled
                                                  ? "strike"
                                                  : "",
                                                attrs: { cols: "8" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.DateTime.fromISO(
                                                      _vm.scheduledMeeting
                                                        .confirmedTime.end
                                                    ).diff(
                                                      _vm.DateTime.fromISO(
                                                        _vm.scheduledMeeting
                                                          .confirmedTime.start
                                                      ),
                                                      "minutes"
                                                    ).minutes
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "meetings.detail.minutes"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.isCancelled
                                          ? _c("div", [
                                              _vm.scheduledMeeting.location
                                                .type === "Zoom"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "meetings.detail.join"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: _vm
                                                                      .scheduledMeeting
                                                                      .zoomMeeting
                                                                      .join_url,
                                                                    target:
                                                                      "_blank",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .scheduledMeeting
                                                                        .zoomMeeting
                                                                        .join_url
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "meetings.detail.id"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .scheduledMeeting
                                                                    .zoomMeeting
                                                                    .id
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.scheduledMeeting
                                                        .zoomMeeting
                                                        .pstnPassword
                                                        ? _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "meetings.detail.passcode"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "8",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .scheduledMeeting
                                                                        .zoomMeeting
                                                                        .pstnPassword
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.zoomDialIn
                                                        ? _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "meetings.detail.dial-in"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "8",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.zoomDialIn.number.replaceAll(
                                                                        " ",
                                                                        ""
                                                                      )
                                                                    ) +
                                                                      " (" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .zoomDialIn
                                                                          .city
                                                                      ) +
                                                                      ", " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .zoomDialIn
                                                                          .country_name
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm.scheduledMeeting.location
                                                    .type === "Google"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "meetings.detail.join"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: _vm
                                                                      .scheduledMeeting
                                                                      .googleMeeting
                                                                      .hangoutLink,
                                                                    target:
                                                                      "_blank",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .scheduledMeeting
                                                                        .googleMeeting
                                                                        .hangoutLink
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.googleDialIn
                                                        ? _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "meetings.detail.dial-in"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "8",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .googleDialIn
                                                                        .label
                                                                    ) +
                                                                      " (" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .googleDialIn
                                                                          .regionCode
                                                                      ) +
                                                                      ")"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.googleDialIn
                                                        ? _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "3",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "meetings.detail.pin"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "8",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .googleDialIn
                                                                        .pin
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm.scheduledMeeting.location
                                                    .type === "PhoneIn"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "meetings.detail.invitee-call"
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .scheduledMeeting
                                                                      .location
                                                                      .phone
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm.scheduledMeeting.location
                                                    .type === "PhoneOut"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "meetings.detail.call-invitee"
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .scheduledMeeting
                                                                      .formData
                                                                      .phone
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm.scheduledMeeting.location
                                                    .type === "InPerson"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "meetings.detail.meet"
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .scheduledMeeting
                                                                      .location
                                                                      .address
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .scheduledMeeting
                                                                    .location
                                                                    .custom
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ])
                                          : _vm._e(),
                                        !_vm.isCancelled
                                          ? _c(
                                              "v-row",
                                              { attrs: { dense: "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "mt-2",
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "secondary-action mb-1 mr-2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.view = "CANCEL"
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color:
                                                                "var(--v-error-base)",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "meetings.detail.cancel"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "primary-action mb-1",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.view =
                                                              "RESCHEDULE"
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "meetings.detail.reschedule"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.scheduledMeeting.formData.answers.length
                                  ? _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          md: "6",
                                          cols: "12",
                                          align: "left",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "pa-2 ma-0 font-14",
                                            staticStyle: {
                                              "background-color":
                                                "var(--v-gray_20-base)",
                                              "border-radius": "4px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { dense: "" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { staticClass: "pa-4" },
                                                  _vm._l(
                                                    _vm.scheduledMeeting
                                                      .formData.answers,
                                                    function (answer, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "text-left font-14 pb-3",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "brand-medium",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  answer.question
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                answer.answer
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.view === "CANCEL"
            ? _c(
                "div",
                [
                  _c("i-frame-wrapper", {
                    attrs: { "frame-url": _vm.cancelUrl },
                    on: { "frame-event": _vm.frameEvent },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.view === "RESCHEDULE"
            ? _c(
                "div",
                [
                  _c("i-frame-wrapper", {
                    attrs: { "frame-url": _vm.rescheduleUrl },
                    on: { "frame-event": _vm.frameEvent },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }