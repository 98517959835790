var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "show-scrollbar",
      staticStyle: {
        "max-height": "calc(var(--vh) - 50px)",
        "overflow-y": "auto",
      },
    },
    [_c("notifications")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }