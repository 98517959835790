var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-2" },
    [
      _c("renderer", {
        ref: "renderer",
        attrs: { schema: _vm.template.schema },
        on: { input: _vm.handleResult },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }