var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height column-format" },
    [
      _c("h-header", {
        attrs: { "add-new-label": _vm.$t("requests.new-request") },
        on: {
          "add-new": function ($event) {
            return _vm.newTicket()
          },
        },
      }),
      _c(
        "div",
        { staticClass: "show-scrollbar", staticStyle: { height: "var(--vh)" } },
        [
          _c(
            "div",
            { staticClass: "mx-3 mt-5" },
            [
              _c("v-data-table", {
                staticStyle: { cursor: "pointer" },
                attrs: {
                  id: "timer-events-table",
                  headers: _vm.headers,
                  "sort-by": "updated",
                  "sort-desc": true,
                  "must-sort": true,
                  items: _vm.filteredTickets,
                  "disable-pagination": true,
                  "hide-default-footer": true,
                  "mobile-breakpoint": 200,
                  "no-data-text": _vm.$t("requests.no-found-requests"),
                },
                on: {
                  "click:row": function ($event) {
                    return _vm.viewTicketDetail($event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.status",
                    fn: function ({ item }) {
                      return [
                        _c("div", { staticClass: "row-format align-center" }, [
                          item.unreadClient
                            ? _c("div", { staticClass: "umi mr-2" })
                            : _vm._e(),
                          item.open
                            ? _c("div", { staticClass: "status" }, [
                                _vm._v(_vm._s(item.status)),
                              ])
                            : _c("div", { staticClass: "closed" }, [
                                _vm._v(_vm._s(_vm.$t("requests.closed"))),
                              ]),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.type",
                    fn: function ({ item }) {
                      return [
                        _vm.getTicketType(item.type)
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.getTicketType(item.type).type)),
                            ])
                          : _c("span", [_vm._v(" -- ")]),
                      ]
                    },
                  },
                  {
                    key: "item.updated",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.DateTime.fromISO(item.updated).toLocaleString(
                                _vm.DateTime.DATETIME_MED
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.subject",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.truncate(item.subject, 50)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.lastComment",
                    fn: function ({ item }) {
                      return [
                        _c("div", {
                          staticClass: "truncate-item",
                          staticStyle: { "max-width": "200px" },
                          domProps: { innerHTML: _vm._s(item.lastComment) },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "div",
                {
                  staticClass: "text-left font-14 font-primary pointer ml-2",
                  staticStyle: { width: "fit-content" },
                  on: { click: _vm.toggleClosedTickets },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.showClosedTickets
                          ? _vm.$t("requests.list.hide")
                          : _vm.$t("requests.list.show")
                      ) +
                      " " +
                      _vm._s(_vm.closedTicketCount) +
                      " " +
                      _vm._s(_vm.$t("requests.list.closed")) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }