var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.timeEnabled || _vm.invoicesEnabled
    ? _c("div", { staticClass: "mt-1" }, [
        _vm.invoicesEnabled
          ? _c("div", { staticClass: "row-format" }, [
              _c("div", { staticClass: "kpi-box mr-3" }, [
                _c("div", { staticClass: "fit" }, [
                  _vm._v(_vm._s(_vm.$t("projects.overview.total-invoiced"))),
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(_vm._s(_vm.totalInvoiced)),
                ]),
              ]),
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit" }, [
                  _vm._v(_vm._s(_vm.$t("projects.overview.total-payments"))),
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(_vm._s(_vm.totalReceived)),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.timeEnabled
          ? _c("div", { staticClass: "row-format mt-3" }, [
              _c("div", { staticClass: "kpi-box mr-3" }, [
                _c("div", { staticClass: "fit" }, [
                  _vm._v(_vm._s(_vm.$t("projects.overview.estimated-hours"))),
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(_vm._s(_vm.formatTimeEstimate)),
                ]),
              ]),
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit" }, [
                  _vm._v(_vm._s(_vm.$t("projects.overview.hours-worked"))),
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(_vm._s(_vm.formattedTimeWorked)),
                ]),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }