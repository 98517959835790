var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-ach-dialog-div" },
    [
      _vm.stripeAccountId
        ? _c(
            "v-container",
            { staticClass: "mt-0 mb-4", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "1" } }),
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c("h2", [
                      _vm._v(
                        _vm._s(_vm.$t("invoices.manual-ach.account-info"))
                      ),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c("v-icon", { on: { click: _vm.handleClose } }, [
                        _vm._v("$close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "accountForm",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                          model: {
                            value: _vm.isValid,
                            callback: function ($$v) {
                              _vm.isValid = $$v
                            },
                            expression: "isValid",
                          },
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "h-outline",
                            attrs: {
                              label: _vm.$t("invoices.manual-ach.name"),
                              "hide-details": "",
                              rules: _vm.noBlank,
                              "persistent-placeholder": "",
                            },
                            model: {
                              value: _vm.accountHolderName,
                              callback: function ($$v) {
                                _vm.accountHolderName = $$v
                              },
                              expression: "accountHolderName",
                            },
                          }),
                          _c("v-select", {
                            staticClass: "h-outline",
                            attrs: {
                              items: _vm.holderTypes,
                              label: _vm.$t("invoices.manual-ach.type"),
                              "hide-details": "",
                              "persistent-placeholder": "",
                            },
                            model: {
                              value: _vm.accountHolderType,
                              callback: function ($$v) {
                                _vm.accountHolderType = $$v
                              },
                              expression: "accountHolderType",
                            },
                          }),
                          _c("v-text-field", {
                            staticClass: "h-outline",
                            attrs: {
                              label: _vm.$t(
                                "invoices.manual-ach.routing-number"
                              ),
                              "hide-details": "",
                              maxlength: "9",
                              rules: _vm.requiredRules,
                              "persistent-placeholder": "",
                            },
                            model: {
                              value: _vm.routingNumber,
                              callback: function ($$v) {
                                _vm.routingNumber = $$v
                              },
                              expression: "routingNumber",
                            },
                          }),
                          _c("v-text-field", {
                            staticClass: "h-outline",
                            attrs: {
                              label: _vm.$t(
                                "invoices.manual-ach.account-number"
                              ),
                              "hide-details": "",
                              rules: _vm.requiredRules,
                              "persistent-placeholder": "",
                            },
                            model: {
                              value: _vm.accountNumber,
                              callback: function ($$v) {
                                _vm.accountNumber = $$v
                              },
                              expression: "accountNumber",
                            },
                          }),
                          _c("v-select", {
                            staticClass: "h-outline",
                            attrs: {
                              items: _vm.accountTypes,
                              label: _vm.$t("invoices.manual-ach.account-type"),
                              "hide-details": "",
                              "persistent-placeholder": "",
                            },
                            model: {
                              value: _vm.accountType,
                              callback: function ($$v) {
                                _vm.accountType = $$v
                              },
                              expression: "accountType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "pt-2 pb-4",
          staticStyle: { "border-top": "1px solid var(--v-gray_50-base)" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              on: { click: _vm.handleClose },
            },
            [_vm._v(_vm._s(_vm.$t("global.cancel")))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action ml-2",
              attrs: { disabled: !_vm.isValid },
              on: { click: _vm.linkNewAccount },
            },
            [_vm._v(_vm._s(_vm.$t("invoices.payment.new-linked")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }