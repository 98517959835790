var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "payment-details-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function ($event) {
              return _vm.handleClose()
            },
          },
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("h2", { staticClass: "modal-title" }, [
            _vm._v(
              _vm._s(_vm.$t("invoice.details.payment-details-modal.heading"))
            ),
          ]),
          _c("p", { staticClass: "modal-subtitle" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("invoice.mark-paid-modal.subheading", {
                    invoiceNumber: _vm.invoice.invoiceNumberFormatted,
                  })
                ) +
                " "
            ),
          ]),
          _c(
            "v-container",
            { staticClass: "px-0 pb-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { staticClass: "label", attrs: { cols: "5" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "invoice.details.payment-details-modal.date-paid"
                        )
                      )
                    ),
                  ]),
                  _c("v-col", { staticClass: "value", attrs: { cols: "7" } }, [
                    _vm._v(" " + _vm._s(this.formattedDate()) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { staticClass: "label", attrs: { cols: "5" } }, [
                    _vm._v(_vm._s(_vm.$t("invoice.status"))),
                  ]),
                  _vm.payment.pending
                    ? _c(
                        "v-col",
                        { staticClass: "value", attrs: { cols: "7" } },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("invoice.pending")) + " "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "mb-1",
                              attrs: { small: "", color: "warning" },
                            },
                            [_vm._v("$alert")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-col",
                        { staticClass: "value", attrs: { cols: "7" } },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("invoice.cleared")) + " "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "mb-1",
                              attrs: { small: "", color: "success" },
                            },
                            [_vm._v("$success")]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { staticClass: "label", attrs: { cols: "5" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("invoice.details.payment-details-modal.amount")
                      )
                    ),
                  ]),
                  _c("v-col", { staticClass: "value", attrs: { cols: "7" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$formatters.dollars(
                            _vm.payment.amount,
                            true,
                            true,
                            _vm.payment.currency
                          )
                        )
                      ),
                    ]),
                    _vm.payment.localAmount
                      ? _c("span", { staticClass: "font-12 font-gray_70" }, [
                          _vm._v(
                            " / " +
                              _vm._s(
                                _vm.$formatters.dollars(
                                  _vm.payment.localAmount,
                                  true,
                                  true,
                                  _vm.$store.state.defaultCurrency
                                )
                              )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { staticClass: "label", attrs: { cols: "5" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "invoice.details.payment-details-modal.payment-type"
                        )
                      )
                    ),
                  ]),
                  _c("v-col", { staticClass: "value", attrs: { cols: "7" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          this.$t(
                            "invoice.payment-providers." +
                              _vm.payment.paymentProvider
                          )
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _vm.payment.memo
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "label", attrs: { cols: "5" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "invoice.details.payment-details-modal.memo"
                              )
                            )
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "value", attrs: { cols: "7" } },
                        [_vm._v(" " + _vm._s(_vm.payment.memo) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "mt-4", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "body-12 text-left gray_70--text",
                      attrs: { cols: "12" },
                    },
                    [
                      _c("span", { staticClass: "mr-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "invoice.details.payment-details-modal.paid-by"
                            )
                          )
                        ),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.payment.paidBy))]),
                      _c("br"),
                      _vm.payment.referenceNumber
                        ? _c(
                            "div",
                            {
                              staticClass: "my-2",
                              staticStyle: { "word-wrap": "break-word" },
                            },
                            [
                              _c("span", { staticClass: "mr-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "invoice.details.payment-details-modal.reference-number"
                                    )
                                  )
                                ),
                              ]),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(_vm.payment.referenceNumber)),
                              ]),
                              _c("br"),
                            ]
                          )
                        : _vm._e(),
                      _c("span", { staticClass: "mr-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "invoice.details.payment-details-modal.timestamp"
                            )
                          )
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$DateTime
                              .fromISO(_vm.payment.timestamp)
                              .toLocaleString(
                                _vm.$DateTime.DATETIME_SHORT_WITH_SECONDS
                              )
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm.payment.integrationKeys.quickbooksId
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", align: "left" } },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v("$quickbooks"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _vm.editMode && !_vm.payment.integratedPayment
            ? _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-1",
                  attrs: { icon: "" },
                  on: { click: _vm.confirmDelete },
                },
                [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$delete")])],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "primary-action ml-1",
              staticStyle: { width: "140px" },
              on: {
                click: function ($event) {
                  return _vm.handleClose()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("global.close")))]
          ),
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          "heading-text": _vm.$t("global.confirm"),
          "yes-text": _vm.$t("global.yes"),
          "no-text": _vm.$t("global.no"),
          "body-text": _vm.$t("invoice.delete-payment"),
        },
        on: {
          close: function ($event) {
            _vm.confirmDeleteDialog = false
          },
          confirm: function ($event) {
            return _vm.deletePayment()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }