var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "products-widget" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _vm.isReady
        ? _c(
            "div",
            { staticClass: "modal-padding pb-4" },
            [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  staticStyle: { height: "24px", "line-height": "24px" },
                },
                [_vm._v(_vm._s(_vm.$t("product.title")))]
              ),
              _c("v-data-table", {
                attrs: {
                  id: "products-data-table",
                  "items-per-page": 25,
                  "hide-default-footer": _vm.products.length <= 25,
                  "hide-default-header": false,
                  headers: _vm.headers,
                  items: _vm.products,
                  "item-key": "id",
                  "mobile-breakpoint": 0,
                  "item-class": _vm.itemClass,
                },
                on: {
                  "click:row": function ($event) {
                    return _vm.selectProduct($event)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header.manage",
                      fn: function ({}) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row-format ml-auto mr-1",
                              staticStyle: { "justify-content": "flex-end" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "pointer",
                                  attrs: { size: "20" },
                                  on: { click: _vm.createNewProduct },
                                },
                                [_vm._v("$plus")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.productName",
                      fn: function ({ item }) {
                        return [
                          _c("div", { staticClass: "product-name" }, [
                            _vm._v(_vm._s(item.productName)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.rate",
                      fn: function ({ item }) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(_vm.$formatters.decimal(item.rate))),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.unit",
                      fn: function ({ item }) {
                        return [_c("div", [_vm._v(_vm._s(item.unit))])]
                      },
                    },
                    {
                      key: "item.hourly",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              item.hourly
                                ? _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("product.check"))),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.taxable",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              item.taxable
                                ? _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("product.check"))),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.manage",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-right mt-n1" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "edit-icon",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editProduct(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "gray_50", size: "20" } },
                                    [_vm._v("$edit")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "delete-icon",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteProduct(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: "var(--v-gray_70-base)",
                                        size: "20",
                                      },
                                    },
                                    [_vm._v("$delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1171534390
                ),
              }),
              _c(
                "div",
                {
                  staticClass: "text-left",
                  staticStyle: { height: "32px", "line-height": "32px" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action ml-n2",
                      staticStyle: { width: "140px" },
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.createNewProduct()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "14" } }, [
                        _vm._v("$plus"),
                      ]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.$t("global.create-new"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "modal-footer text-center" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              staticStyle: { width: "140px" },
              on: {
                click: function ($event) {
                  return _vm.cancel()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.close")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.productModal, persistent: true },
          on: {
            close: function ($event) {
              _vm.productModal = false
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { id: "edit-product" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-dialog",
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.productModal = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("$close")])],
                1
              ),
              _c(
                "div",
                { staticClass: "modal-padding" },
                [
                  _c("div", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.$t("product.create-product-title"))),
                  ]),
                  _c("v-text-field", {
                    staticClass: "h-outline",
                    attrs: {
                      autofocus: "",
                      "hide-details": "",
                      "persistent-placeholder": "",
                      placeholder: _vm.$t("product.product-name-placeholder"),
                      label: _vm.$t("product.product-name-label"),
                      rules: [(value) => !!value || "Required."],
                    },
                    model: {
                      value: _vm.editingProduct.productName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editingProduct, "productName", $$v)
                      },
                      expression: "editingProduct.productName",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between half" },
                    [
                      !_vm.editingProduct.hourly
                        ? _c("v-text-field", {
                            staticClass: "h-outline",
                            attrs: {
                              "hide-details": "",
                              "persistent-placeholder": "",
                              placeholder: _vm.$t("product.unit-placeholder"),
                              label: _vm.$t("product.unit-label"),
                              rules: [(value) => !!value || "Required."],
                              disabled: _vm.editingProduct.hourly,
                            },
                            model: {
                              value: _vm.editingProduct.unit,
                              callback: function ($$v) {
                                _vm.$set(_vm.editingProduct, "unit", $$v)
                              },
                              expression: "editingProduct.unit",
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "h-outline px-2 py-1 mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-start align-start" },
                          [
                            _c("v-simple-checkbox", {
                              on: {
                                click: function ($event) {
                                  return _vm.handleHourlyClick()
                                },
                              },
                              model: {
                                value: _vm.editingProduct.hourly,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editingProduct, "hourly", $$v)
                                },
                                expression: "editingProduct.hourly",
                              },
                            }),
                            _c("div", { staticClass: "text-left" }, [
                              _c(
                                "label",
                                { staticClass: "font-14 font-gray_80 ml-n1" },
                                [_vm._v(_vm._s(_vm.$t("product.hourly-label")))]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between half" },
                    [
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          "hide-details": "",
                          "persistent-placeholder": "",
                          placeholder: _vm.$t("product.rate-placeholder"),
                          label: _vm.$t("product.rate-label"),
                          rules: [(value) => !!value || "Required."],
                        },
                        model: {
                          value: _vm.editingProduct.rate,
                          callback: function ($$v) {
                            _vm.$set(_vm.editingProduct, "rate", $$v)
                          },
                          expression: "editingProduct.rate",
                        },
                      }),
                      _c("div", { staticClass: "h-outline px-2 py-1" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-start align-start" },
                          [
                            _c("v-simple-checkbox", {
                              model: {
                                value: _vm.editingProduct.taxable,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editingProduct, "taxable", $$v)
                                },
                                expression: "editingProduct.taxable",
                              },
                            }),
                            _c(
                              "label",
                              { staticClass: "font-14 font-gray_80 ml-n1" },
                              [_vm._v(_vm._s(_vm.$t("product.taxable-label")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary-action mr-1",
                      staticStyle: { width: "140px" },
                      on: {
                        click: function ($event) {
                          _vm.productModal = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action ml-1",
                      staticStyle: { width: "140px" },
                      attrs: { disabled: !_vm.editingProduct.productName },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }