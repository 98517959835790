var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
          model: {
            value: _vm.isValid,
            callback: function ($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid",
          },
        },
        [
          _c("label", {
            staticClass: "question",
            domProps: { innerHTML: _vm._s(_vm.htmlText) },
          }),
          _c(
            "v-radio-group",
            {
              attrs: { rules: _vm.required ? _vm.inputRequired : [] },
              on: {
                change: function ($event) {
                  return _vm.handleChange()
                },
              },
              model: {
                value: _vm.selectedValue,
                callback: function ($$v) {
                  _vm.selectedValue = $$v
                },
                expression: "selectedValue",
              },
            },
            [
              _vm._l(_vm.options, function (option) {
                return _c("v-radio", {
                  key: option,
                  staticClass: "box",
                  attrs: {
                    value: option,
                    label: option,
                    disabled: _vm.previewMode,
                  },
                })
              }),
              _vm.hasOther
                ? _c(
                    "v-radio",
                    {
                      staticClass: "box",
                      attrs: { value: "$$other", disabled: _vm.previewMode },
                    },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "div",
                          { staticStyle: { width: "320px" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                placeholder: _vm.$t("discovery.other"),
                                dense: "",
                                "hide-details": "",
                                disabled: this.selectedValue !== "$$other",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.handleChange()
                                },
                              },
                              model: {
                                value: _vm.otherValue,
                                callback: function ($$v) {
                                  _vm.otherValue = $$v
                                },
                                expression: "otherValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }