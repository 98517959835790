var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.proposals.length || _vm.agreements.length
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "brand-medium pointer",
            on: {
              click: function ($event) {
                return _vm.$router.push("/agreements")
              },
            },
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("welcome.currently-have")) + " "),
            _c("span", { staticStyle: { color: "var(--v-primary-base)" } }, [
              _vm._v(_vm._s(_vm.proposals.length + _vm.agreements.length)),
            ]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.$tc(
                    "welcome.proposals-ready",
                    _vm.proposals.length + _vm.agreements.length
                  )
                ) +
                ". "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "column-format",
            staticStyle: { gap: "8px", "margin-top": "12px" },
          },
          [
            _vm._l(_vm.proposals, function (proposal) {
              return _c(
                "div",
                {
                  key: proposal.id,
                  staticClass: "action-button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openProposal(proposal)
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(proposal.coverPage.heading) },
                  }),
                  _vm._v(
                    " - " +
                      _vm._s(_vm.$t("global.submitted")) +
                      " " +
                      _vm._s(
                        _vm.DateTime.fromISO(proposal.dateSent).toFormat(
                          "MMM dd"
                        )
                      ) +
                      " "
                  ),
                ]
              )
            }),
            _vm._l(_vm.agreements, function (agreement) {
              return _c(
                "div",
                {
                  key: agreement.id,
                  staticClass: "action-button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openAgreement(agreement)
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(agreement.name))]),
                  _vm._v(
                    " - " +
                      _vm._s(_vm.$t("global.submitted")) +
                      " " +
                      _vm._s(
                        _vm.DateTime.fromISO(agreement.dateCreated).toFormat(
                          "MMM dd"
                        )
                      ) +
                      " "
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }