var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        {
          staticStyle: { "background-color": "#fff" },
          attrs: { id: "sign-dialog" },
        },
        [
          _c(
            "div",
            { staticClass: "pa-4" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-size": "4", "slider-color": "black" },
                  on: { change: _vm.tabChanged },
                },
                [
                  _c("v-tab", [
                    _vm._v(_vm._s(_vm.$t("proposal.signature.type-signature"))),
                  ]),
                  _c("v-tab", [
                    _vm._v(_vm._s(_vm.$t("proposal.signature.draw-signature"))),
                  ]),
                  _c(
                    "v-tab-item",
                    { staticClass: "column-format gap-2" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "signature-block mt-4 mb-3",
                          style: _vm.signatureFont,
                        },
                        [
                          _c("div", { staticClass: "signature" }, [
                            _c("input", {
                              staticClass: "keyboardInput",
                              attrs: { disabled: "", type: "text" },
                              domProps: {
                                value: `${_vm.signer.firstName} ${_vm.signer.lastName}`,
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c("v-select", {
                        attrs: {
                          outlined: "",
                          "hide-details": "",
                          "persistent-placeholder": "",
                          dense: "",
                          label: _vm.$t("proposal.signature.signature-font"),
                          items: _vm.fonts,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      style: `font-family: ${item}, cursive; font-size:30px`,
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("proposal.signature.signature")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "selection",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      style: `font-family: ${item}, cursive; font-size:30px`,
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("proposal.signature.signature")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2745115292
                        ),
                        model: {
                          value: _vm.element.font,
                          callback: function ($$v) {
                            _vm.$set(_vm.element, "font", $$v)
                          },
                          expression: "element.font",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "row-format gap-2" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              label: "Title",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _vm._v(" Title"),
                                      _c("span", { staticClass: "red--text" }, [
                                        _c("strong", [_vm._v("* ")]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              861112194
                            ),
                            model: {
                              value: _vm.element.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.element, "title", $$v)
                              },
                              expression: "element.title",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              label: "Initials",
                              maxlength: "3",
                              "aria-required": "true",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _vm._v(" Initials"),
                                      _c("span", { staticClass: "red--text" }, [
                                        _c("strong", [_vm._v("* ")]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4092781647
                            ),
                            model: {
                              value: _vm.element.initials,
                              callback: function ($$v) {
                                _vm.$set(_vm.element, "initials", $$v)
                              },
                              expression: "element.initials",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { staticClass: "column-format gap-2" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "signature-block mt-4 mb-3",
                          on: {
                            dragover: _vm.cancelDragDrop,
                            dragenter: _vm.cancelDragDrop,
                            drop: _vm.handleDrop,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "instructions row-format align-center",
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("proposal.signature.draw-in-box")
                                  )
                                ),
                              ]),
                              _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "ml-auto material-symbols-outlined pointer mr-3",
                                  attrs: { size: "16" },
                                  on: { click: _vm.clearDrawingPad },
                                },
                                [_vm._v("cancel")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "signature",
                              style:
                                "background-color: var(--v-gray_10-base);" +
                                _vm.signatureFont,
                            },
                            [
                              _c("vue-signature-pad", {
                                ref: "signaturePad",
                                attrs: { width: "476px", height: "150px" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          "persistent-placeholder": "",
                          label: "Name",
                          disabled: "",
                          value: `${_vm.signer.firstName} ${_vm.signer.lastName}`,
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "row-format gap-2" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "persistent-placeholder": "",
                              label: "Title",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _vm._v(" Title"),
                                      _c("span", { staticClass: "red--text" }, [
                                        _c("strong", [_vm._v("*")]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3582623618
                            ),
                            model: {
                              value: _vm.element.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.element, "title", $$v)
                              },
                              expression: "element.title",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "persistent-placeholder": "",
                              label: "Initials",
                              maxlength: "3",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _vm._v(" Initials"),
                                      _c("span", { staticClass: "red--text" }, [
                                        _c("strong", [_vm._v("*")]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2053561423
                            ),
                            model: {
                              value: _vm.element.initials,
                              callback: function ($$v) {
                                _vm.$set(_vm.element, "initials", $$v)
                              },
                              expression: "element.initials",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: { elevation: "0", color: "gray_20" },
                  on: {
                    click: function ($event) {
                      return _vm.cancelSign()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("global.cancel")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: {
                    color: "primary",
                    disabled: !_vm.element.initials || !_vm.element.title,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSign()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("proposal.signature.sign-accept")))]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }