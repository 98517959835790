var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.children.length
        ? _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                "content-class": "locale-workspace-sub-menu",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass:
                                "settings-link row-format align-center pointer",
                            },
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { size: "16" } }, [
                              _vm._v(_vm._s(_vm.icon)),
                            ]),
                            _c("div", { staticClass: "ml-1" }, [
                              _vm._v(_vm._s(_vm.label)),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3130083711
              ),
            },
            [
              _c(
                "div",
                { staticClass: "column-format" },
                _vm._l(_vm.children, function (child) {
                  return _c("locale-switcher", {
                    key: child.code,
                    attrs: {
                      label: child.name,
                      route: child.route,
                      "select-value": child.code,
                    },
                    on: {
                      selected: function ($event) {
                        return _vm.$emit("selected", $event)
                      },
                    },
                  })
                }),
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "settings-link row-format align-center pointer",
              on: { click: _vm.handleClick },
            },
            [
              _c("v-icon", { attrs: { size: "16" } }, [
                _vm._v(_vm._s(_vm.icon)),
              ]),
              _c("div", { staticClass: "ml-1" }, [_vm._v(_vm._s(_vm.label))]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }