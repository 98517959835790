var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "confirm-dialog-div", staticStyle: { width: "500px" } },
    [
      _c(
        "div",
        { staticClass: "ma-4" },
        [
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              type: "text",
              label: _vm.label,
              "persistent-placeholder": "",
              autofocus: "",
              "hide-details": "",
            },
            model: {
              value: _vm.inputValue,
              callback: function ($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue",
            },
          }),
        ],
        1
      ),
      _c(
        "v-form",
        {
          ref: "confirmDialog",
          attrs: { id: "confirmDialog", "lazy-validation": "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleSubmit()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              !_vm.hideNo
                ? _c(
                    "v-btn",
                    {
                      staticClass: "secondary-action mr-1",
                      attrs: { "min-width": "100" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleClose()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.no))]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "primary-action ml-1",
                  attrs: {
                    type: "submit",
                    "min-width": "100",
                    elevation: "0",
                    disabled: _vm.submitDisabled,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleSubmit()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.yes) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }