var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "fill-height column-format" },
        [
          _c("h-header", {
            scopedSlots: _vm._u(
              [
                {
                  key: "filter",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "row-format",
                          staticStyle: { "flex-wrap": "wrap" },
                        },
                        [
                          _vm.view === "list"
                            ? _c("v-text-field", {
                                staticStyle: {
                                  "font-size": "14px",
                                  width: "200px",
                                },
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  "prepend-icon": "$filter",
                                  placeholder: _vm.$t("global.filter"),
                                  clearable: "",
                                },
                                on: { change: _vm.handleFilterChange },
                                model: {
                                  value: _vm.filter,
                                  callback: function ($$v) {
                                    _vm.filter = $$v
                                  },
                                  expression: "filter",
                                },
                              })
                            : _vm._e(),
                          _c("v-select", {
                            staticClass: "ml-3",
                            staticStyle: { width: "200px" },
                            attrs: {
                              clearable: "",
                              dense: "",
                              "hide-details": "",
                              items: _vm.dateOptions,
                              placeholder: _vm.$t("global.date"),
                            },
                            on: { change: _vm.handleDateFilterChange },
                            model: {
                              value: _vm.dateFilter,
                              callback: function ($$v) {
                                _vm.dateFilter = $$v
                              },
                              expression: "dateFilter",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1064076849
            ),
          }),
          _c(
            "div",
            {
              staticClass: "show-scrollbar",
              staticStyle: { height: "var(--vh)" },
            },
            [
              _c("timer-event-table", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.view === "list",
                    expression: "view === 'list'",
                  },
                ],
                attrs: { "timer-events": _vm.timerEvents, filter: _vm.filter },
              }),
              _c("timer-event-charts", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.view === "reports",
                    expression: "view === 'reports'",
                  },
                ],
                attrs: {
                  "timer-events": _vm.timerEvents,
                  earliest: _vm.earliest,
                  latest: _vm.latest,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }