var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: _vm.paymentStyle,
      attrs: { id: _vm.item.id },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.pendingCharge,
              expression: "!pendingCharge",
            },
          ],
          staticClass: "text-left column-format gap-1",
        },
        [
          _c("div", {
            ref: "card",
            staticStyle: { "border-bottom": "1px solid var(--v-gray_30-base)" },
          }),
          _c(
            "div",
            { staticClass: "row-format gap-1 mt-1" },
            [
              _c("v-img", {
                staticStyle: { "max-width": "30px", "margin-left": "-2px" },
                attrs: { src: "/images/payment/american_express.svg" },
              }),
              _c("v-img", {
                staticStyle: { "max-width": "30px" },
                attrs: { src: "/images/payment/visa.svg" },
              }),
              _c("v-img", {
                staticStyle: { "max-width": "30px" },
                attrs: { src: "/images/payment/mastercard.svg" },
              }),
              _c("v-img", {
                staticStyle: { "max-width": "30px" },
                attrs: { src: "/images/payment/discover.svg" },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.pendingCharge && _vm.pendingCharge.clientPaymentMethod
        ? _c("div", { staticClass: "font-14 text-left column-format gap-2" }, [
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(_vm.pendingCharge.clientPaymentMethod.label) +
                  " " +
                  _vm._s(_vm.pendingCharge.clientPaymentMethod.last4) +
                  " charged " +
                  _vm._s(
                    _vm.$formatters.dollars(
                      _vm.pendingCharge.amount,
                      true,
                      true,
                      _vm.pendingCharge.currency
                    )
                  ) +
                  " " +
                  _vm._s(_vm.pendingCharge.currency) +
                  " on " +
                  _vm._s(
                    _vm.$DateTime
                      .fromISO(_vm.pendingCharge.timestamp)
                      .toLocaleString(_vm.$DateTime.DATETIME_MED)
                  ) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "row-format gap-1 mt-0" },
              [
                _c("v-img", {
                  staticStyle: { "max-width": "30px", "margin-left": "-2px" },
                  attrs: { src: "/images/payment/american_express.svg" },
                }),
                _c("v-img", {
                  staticStyle: { "max-width": "30px" },
                  attrs: { src: "/images/payment/visa.svg" },
                }),
                _c("v-img", {
                  staticStyle: { "max-width": "30px" },
                  attrs: { src: "/images/payment/mastercard.svg" },
                }),
                _c("v-img", {
                  staticStyle: { "max-width": "30px" },
                  attrs: { src: "/images/payment/discover.svg" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }