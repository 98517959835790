var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tabs-component" }, [
    _vm.title
      ? _c(
          "div",
          {
            ref: "moduleTitle",
            class: `row-format align-center ${_vm.titleClass()}`,
            on: {
              click: function ($event) {
                return _vm.goTo(_vm.title)
              },
            },
          },
          [
            _c("div", [_c("span", [_vm._v(_vm._s(_vm.$t(_vm.title.label)))])]),
            _vm.parentValue
              ? _c(
                  "div",
                  { staticClass: "row-format align-center" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mx-1",
                        attrs: { size: "12", color: "secondary" },
                      },
                      [_vm._v("$chevronRight")]
                    ),
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$router.push(_vm.parentValue.route)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t(_vm.parentValue.parent)))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.navDetailValue
              ? _c(
                  "div",
                  { staticClass: "row-format align-center" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mx-1",
                        attrs: { size: "12", color: "secondary" },
                      },
                      [_vm._v("$chevronRight")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab-item selected",
                        staticStyle: { cursor: "default!important" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t(_vm.navDetailValue)))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.visibleTabs.length || _vm.menuTabs.length
      ? _c(
          "div",
          { ref: "visibleTabs", staticClass: "tabs-wrapper" },
          [
            _vm._l(_vm.visibleTabs, function (tab) {
              return _c(
                "div",
                {
                  key: tab.uri,
                  class: _vm.tabClass(tab),
                  on: {
                    click: function ($event) {
                      return _vm.goTo(tab)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(tab.label)) + " ")]
              )
            }),
            _vm.menuTabs.length
              ? _c(
                  "div",
                  { staticClass: "menu-wrapper" },
                  [
                    _c(
                      "v-menu",
                      {
                        ref: "menuTabs",
                        attrs: {
                          "close-on-content-click": true,
                          transition: "scale-transition",
                          "offset-y": "",
                          "min-width": "290px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      {
                                        ref: "menuTabsActivator",
                                        class: _vm.menuActivatorClass,
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t(_vm.moreVerb)) + " "
                                      ),
                                      _vm.menuTabsOpen
                                        ? _c("h-icon-3", {
                                            staticClass: "ml-1",
                                            attrs: {
                                              size: "10",
                                              name: "arrow-up",
                                              color: "var(--v-secondary-base)",
                                            },
                                          })
                                        : _c("h-icon-3", {
                                            staticClass: "ml-1",
                                            attrs: {
                                              size: "10",
                                              name: "arrow-down",
                                              color: "var(--v-secondary-base)",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          460624119
                        ),
                        model: {
                          value: _vm.menuTabsOpen,
                          callback: function ($$v) {
                            _vm.menuTabsOpen = $$v
                          },
                          expression: "menuTabsOpen",
                        },
                      },
                      [
                        _c(
                          "v-list",
                          _vm._l(_vm.menuTabs, function (tab) {
                            return _c(
                              "v-list-item",
                              {
                                key: tab.uri,
                                on: {
                                  click: function ($event) {
                                    return _vm.goTo(tab)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.$t(tab.label)) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }