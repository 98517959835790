var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-ach-dialog-div" },
    [
      _c(
        "v-container",
        { staticClass: "mt-0 mb-4", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: "1" } }),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("h1", [
                  _vm._v(
                    _vm._s(_vm.$t("invoices.new-ach-account.connect-account"))
                  ),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c("v-icon", { on: { click: _vm.handleClose } }, [
                    _vm._v("$close"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                {
                  staticStyle: {
                    "border-right": "1px solid var(--v-gray_50-base)",
                  },
                  attrs: { cols: "6" },
                },
                [
                  _c("h2", [
                    _vm._v(
                      _vm._s(_vm.$t("invoices.new-ach-account.verification"))
                    ),
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mt-3",
                      attrs: { size: "65", color: "black" },
                    },
                    [_vm._v("$plaidVertical")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("h2", [
                    _vm._v(
                      _vm._s(_vm.$t("invoices.new-ach-account.enter-info"))
                    ),
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mt-3",
                      attrs: { size: "52", color: "black" },
                    },
                    [_vm._v(_vm._s(_vm.$t("global.paid")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-3",
                  staticStyle: {
                    "border-right": "1px solid var(--v-gray_50-base)",
                  },
                  attrs: { cols: "6" },
                },
                [
                  _c("div", { staticClass: "font-14" }, [
                    _vm._v(
                      _vm._s(_vm.$t("invoices.new-ach-account.use-plaid"))
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "brand-bold mt-3" },
                    [
                      _c("v-icon", { attrs: { color: "success" } }, [
                        _vm._v("$success"),
                      ]),
                      _vm._v(
                        _vm._s(_vm.$t("invoices.new-ach-account.recommended"))
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-col", { staticClass: "pa-3", attrs: { cols: "6" } }, [
                _c("div", { staticClass: "font-14" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("invoices.new-ach-account.stripe-verification")
                    )
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                {
                  staticStyle: {
                    "border-right": "1px solid var(--v-gray_50-base)",
                  },
                  attrs: { cols: "6" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary-action",
                      on: { click: _vm.connectPlaid },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("invoices.new-ach-account.connect-plaid"))
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary-action",
                      on: { click: _vm.connectManually },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("invoices.new-ach-account.connect-manually")
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }