var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "invoice-details" } }, [
    _vm.invoice
      ? _c("div", { attrs: { id: "header-area" } }, [
          _c("div", { staticClass: "header-left pl-md-6 pl-4" }, [
            _c(
              "div",
              { staticClass: "mr-6 brand-medium" },
              [
                _c("h-icon2", {
                  staticClass: "mr-4",
                  attrs: { name: "invoices", active: true, size: "24" },
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("invoice.invoice-number-appended", {
                        invoiceNumber: _vm.invoice.invoiceNumberFormatted,
                      })
                    ) +
                    " "
                ),
              ],
              1
            ),
            _vm.$vuetify.breakpoint.mdAndUp
              ? _c(
                  "div",
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-click": true,
                          "nudge-bottom": 30,
                          disabled: !_vm.invoice.payments.length,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "secondary-action",
                                        attrs: {
                                          disabled:
                                            !_vm.invoice.payments.length,
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { size: "14" },
                                        },
                                        [_vm._v("$time")]
                                      ),
                                      _c("span", { staticClass: "body-12" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "invoices.details.payment-history"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm.invoice.payments.length
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "body-10 ml-2 px-1",
                                              staticStyle: {
                                                "background-color":
                                                  "var(--v-gray_10-base)",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.invoice.payments.length
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1492662527
                        ),
                      },
                      [
                        _c("payment-history", {
                          attrs: { invoice: _vm.invoice },
                          on: {
                            openPayment: function ($event) {
                              return _vm.openPaymentDetail($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "header-right pr-md-6 pr-4" }, [
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    attrs: { icon: "" },
                    on: { click: _vm.downloadInvoice },
                  },
                  [
                    _c("v-icon", { attrs: { size: "25", color: "gray_60" } }, [
                      _vm._v("$download"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        return _vm.handleClose()
                      },
                    },
                  },
                  [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
                  1
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "show-scrollbar" },
      [
        _vm.invoice && _vm.account
          ? _c(_vm.rendererType, {
              key: _vm.refreshKey,
              tag: "component",
              staticStyle: {
                "max-height": "calc(100vh - 55px)",
                height: "calc(100vh - 55px)",
              },
              attrs: {
                invoice: _vm.invoice,
                "scheduled-payments": _vm.invoice.scheduledPayments,
                account: _vm.account,
                "edit-mode": false,
                "attachment-service": _vm.invoiceService,
                "invoice-service": _vm.invoiceService,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }