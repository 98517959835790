var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.agreement
    ? _c("agreement-renderer", {
        ref: "AgreementRenderer",
        attrs: {
          agreement: _vm.agreement,
          signer: _vm.signer,
          "show-finish-warning": _vm.showWarning,
        },
        on: { finalize: _vm.submitFinalized },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }