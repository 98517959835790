var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fill-height column-format" },
    [
      _c("h-header"),
      _vm.filteredProposalList.length
        ? _c("v-data-table", {
            staticClass: "pointer",
            attrs: {
              items: _vm.filteredProposalList,
              headers: _vm.headers,
              "hide-default-footer": true,
              height: "calc(100vh - 150px)",
              "fixed-header": "",
            },
            on: { "click:row": _vm.openAgreement },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.date",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.DateTime.fromISO(item.date).toLocaleString(
                              _vm.DateTime.DATE_MED
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.dateCompleted",
                  fn: function ({ item }) {
                    return [
                      item.dateCompleted
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.DateTime.fromISO(
                                  item.dateCompleted
                                ).toLocaleString(_vm.DateTime.DATE_MED)
                              )
                            ),
                          ])
                        : _c("span", [_vm._v("--")]),
                    ]
                  },
                },
                {
                  key: "item.status",
                  fn: function ({ item }) {
                    return [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.formatStatus(item.status)),
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "item.lastEvent",
                  fn: function ({ item }) {
                    return [
                      item.lastEvent
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(item.lastEvent.event) +
                                " - " +
                                _vm._s(
                                  _vm.DateTime.fromISO(
                                    item.lastEvent.timestamp
                                  ).toLocaleString(_vm.DateTime.DATETIME_MED)
                                ) +
                                " "
                            ),
                          ])
                        : _c("div", [_vm._v("--")]),
                    ]
                  },
                },
              ],
              null,
              false,
              2802549359
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }