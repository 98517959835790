import { render, staticRenderFns } from "./ProposalRenderer.vue?vue&type=template&id=fca466dc"
import script from "./ProposalRenderer.vue?vue&type=script&lang=js"
export * from "./ProposalRenderer.vue?vue&type=script&lang=js"
import style0 from "./ProposalRenderer.vue?vue&type=style&index=0&id=fca466dc&lang=scss"
import style1 from "./ProposalRenderer.vue?vue&type=style&index=1&id=fca466dc&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VRow})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2503387672/src/struxture-clientportal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fca466dc')) {
      api.createRecord('fca466dc', component.options)
    } else {
      api.reload('fca466dc', component.options)
    }
    module.hot.accept("./ProposalRenderer.vue?vue&type=template&id=fca466dc", function () {
      api.rerender('fca466dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/proposal/proposal-renderer/ProposalRenderer.vue"
export default component.exports