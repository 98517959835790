var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { position: "relative" },
      attrs: { id: `comment-wrapper-${_vm.uuid}` },
    },
    [
      _c("v-textarea", {
        staticStyle: { "font-size": "14px" },
        attrs: {
          id: `comment-${_vm.uuid}`,
          dense: "",
          "hide-details": "",
          rows: "1",
          "auto-grow": "",
          outlined: "",
          placeholder: _vm.$t("projects.comment-placeholder"),
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                !_vm.commentToEdit
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "pointer",
                        attrs: {
                          color:
                            !_vm.newComment || _vm.newComment.length == 0
                              ? "gray"
                              : "primary",
                          disabled:
                            !_vm.newComment || _vm.newComment.length == 0,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.sendComment()
                          },
                        },
                      },
                      [_vm._v("$comment ")]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.newComment,
          callback: function ($$v) {
            _vm.newComment = $$v
          },
          expression: "newComment",
        },
      }),
      _vm.commentToEdit
        ? _c(
            "div",
            { staticClass: "row-format mt-2" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-1",
                  on: {
                    click: function ($event) {
                      return _vm.cancelEdit()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary-action ml-1",
                  staticStyle: { "min-width": "100px" },
                  on: {
                    click: function ($event) {
                      return _vm.saveEdit()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }