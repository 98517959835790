var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      !_vm.dialog
        ? _c(
            "div",
            { staticClass: "text-center ma-12" },
            [
              _c("v-progress-circular", {
                attrs: {
                  indeterminate: true,
                  size: "200",
                  color: "light-blue",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticStyle: { "padding-top": "15px" } }, [
                _vm._v(_vm._s(_vm.$t("login.token-expired"))),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.freshLogin()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("global.ok")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }