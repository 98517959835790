var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `${_vm.active ? "form-item-active" : "form-item-inactive"} ${
        _vm.inContainer ? "" : "px-2"
      }`,
    },
    [
      _c(_vm.item.type, {
        key: _vm.item.type,
        ref: "component",
        tag: "component",
        class: _vm.item.type !== "Container" ? "pb-3" : "",
        attrs: {
          item: _vm.item,
          styles: _vm.styles,
          font: _vm.font,
          "button-props": _vm.buttonProps,
          active: _vm.active,
          disabled: _vm.disabled,
          "account-logo": _vm.accountLogo,
          index: _vm.index,
          "current-page": _vm.currentPage,
          "page-count": _vm.pageCount,
          results: _vm.results,
          "known-data": _vm.knownData,
          builder: _vm.builder,
          container: _vm.container,
        },
        on: {
          "next-page": function ($event) {
            return _vm.$emit("next-page")
          },
          "previous-page": function ($event) {
            return _vm.$emit("previous-page")
          },
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          click: function ($event) {
            return _vm.$emit("click", $event)
          },
          delete: function ($event) {
            return _vm.$emit("delete", $event)
          },
          change: function ($event) {
            return _vm.$emit("change", $event)
          },
          "container-item-click": function ($event) {
            return _vm.$emit("container-item-click", $event)
          },
          "container-item-update": function ($event) {
            return _vm.$emit("container-item-update", $event)
          },
          "add-container-element": function ($event) {
            return _vm.$emit("add-container-element", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }