var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReady
    ? _c("div", { staticClass: "pa-3" }, [
        _c(
          "div",
          {
            staticClass: "row-format pointer",
            staticStyle: { "flex-wrap": "wrap" },
            on: { click: _vm.toggleExpanded },
          },
          [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "row-format align-center",
                        staticStyle: { "text-align": "left" },
                        attrs: { cols: "12", md: "6" },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "chevron-icon",
                            attrs: { size: "16", color: "black" },
                          },
                          [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.isExpanded ? "$chevronUp" : "$chevronDown"
                                )
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "brand-medium text-left ml-2" },
                          [_vm._v(_vm._s(_vm.project.name))]
                        ),
                        !_vm.project.active
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ml-2 px-1 row-format align-center font-12",
                                staticStyle: {
                                  "background-color":
                                    "var(--v-success_10-base)",
                                  "border-radius": "4px",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "success", size: "10" },
                                  },
                                  [_vm._v("$check")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "var(--v-success-base)",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("projects.completed")))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticStyle: { "text-align": "right" },
                        attrs: { cols: "12", md: "6" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "secondary-box" },
                          [
                            _c(
                              "v-btn",
                              { staticClass: "secondary-action" },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("global.due")) + ": "),
                                ]),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.formattedDueDate),
                                  },
                                }),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { size: "16", color: "gray_80" },
                                  },
                                  [_vm._v("$calendarMono")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.isExpanded
          ? _c(
              "div",
              [
                _vm.project.proposalId
                  ? _c(
                      "div",
                      {
                        staticClass: "text-left font-14 font-gray_80 pointer",
                        on: {
                          click: function ($event) {
                            return _vm.openProposal()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "brand-semilight" }, [
                          _vm._v(_vm._s(_vm.$t("projects.from")) + " "),
                        ]),
                        _c("span", { staticClass: "brand-medium" }, [
                          _vm._v(
                            _vm._s(
                              _vm.project.proposalName
                                ? _vm.project.proposalName
                                : "Proposal"
                            )
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "my-2" }),
                _c("div", {
                  staticClass: "text-left",
                  domProps: { innerHTML: _vm._s(_vm.project.description) },
                }),
                _c("div", { staticClass: "my-2" }),
                _c("time-and-money", { attrs: { project: _vm.project } }),
                _c("div", { staticClass: "my-4" }),
                _vm.project.files
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-container",
                          { staticClass: "py-5 font-14", attrs: { fluid: "" } },
                          _vm._l(_vm.project.files, function (file) {
                            return _c(
                              "v-row",
                              {
                                key: file.fileName,
                                staticClass: "pointer file-row",
                                attrs: { align: "center" },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(file)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", align: "left" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row-format align-center",
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: file.fileIconUrl,
                                            width: "30",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ml-2 font-12 brand-medium",
                                          },
                                          [_vm._v(_vm._s(file.fileName))]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.pendingDeliverables.length ||
                _vm.previousDeliverables.length
                  ? _c(
                      "v-container",
                      { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "6", align: "left" } },
                              _vm._l(
                                _vm.pendingDeliverables,
                                function (pending) {
                                  return _c("div", { key: pending.statusId }, [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 font-16 ml-2" },
                                      [_vm._v(_vm._s(pending.label))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row-format",
                                        staticStyle: { "flex-wrap": "wrap" },
                                      },
                                      _vm._l(
                                        pending.deliverables,
                                        function (deliverable) {
                                          return _c("deliverable-card", {
                                            key: deliverable.id,
                                            attrs: {
                                              "status-list": _vm.statusList,
                                              deliverable: deliverable,
                                            },
                                            on: {
                                              "open-deliverable": function (
                                                $event
                                              ) {
                                                return _vm.openDeliverable(
                                                  deliverable
                                                )
                                              },
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "6", align: "left" } },
                              _vm._l(
                                _vm.previousDeliverables,
                                function (previous) {
                                  return _c("div", { key: previous.statusId }, [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 font-16 ml-2" },
                                      [_vm._v(_vm._s(previous.label))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row-format",
                                        staticStyle: { "flex-wrap": "wrap" },
                                      },
                                      _vm._l(
                                        previous.deliverables,
                                        function (deliverable) {
                                          return _c("deliverable-card", {
                                            key: deliverable.id,
                                            attrs: {
                                              "status-list": _vm.statusList,
                                              deliverable: deliverable,
                                            },
                                            on: {
                                              "open-deliverable": function (
                                                $event
                                              ) {
                                                return _vm.openDeliverable(
                                                  deliverable
                                                )
                                              },
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }