var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.forms.length
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "brand-medium pointer",
            on: {
              click: function ($event) {
                return _vm.$router.push("/forms")
              },
            },
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("welcome.currently-have")) + " "),
            _c("span", { staticStyle: { color: "var(--v-primary-base)" } }, [
              _vm._v(_vm._s(_vm.forms.length)),
            ]),
            _vm._v(
              " " +
                _vm._s(_vm.$tc("forms.forms-ready", _vm.forms.length)) +
                ". "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "column-format",
            staticStyle: { gap: "8px", "margin-top": "12px" },
          },
          _vm._l(_vm.forms, function (form) {
            return _c(
              "div",
              {
                key: form.id,
                staticClass: "action-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.openRequest(form)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(form.name) +
                    " - " +
                    _vm._s(
                      _vm.DateTime.fromISO(form.sentTimestamp).toLocaleString(
                        _vm.DateTime.DATETIME_SHORT
                      )
                    ) +
                    " "
                ),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }