var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: _vm.schedulerStyle,
      attrs: { id: _vm.item.id },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm.meetingInfo
        ? _c(
            "div",
            {
              class: `flex-wrap row-format gap-2 ${
                _vm.scheduledMeeting ? "" : "centered"
              }`,
            },
            [
              _vm.meetingInfo && (_vm.item.showInfo || _vm.scheduledMeeting)
                ? _c("meeting-info", {
                    staticStyle: { flex: "1 1 0", "min-width": "250px" },
                    attrs: {
                      meet: _vm.meetingInfo,
                      "confirmed-time": _vm.confirmedTime,
                      "token-map": _vm.tokenMap,
                    },
                  })
                : _vm._e(),
              _vm.meetingInfo && !_vm.scheduledMeeting
                ? _c("meeting-schedule", {
                    staticStyle: { flex: "1 1 0" },
                    attrs: {
                      meet: _vm.meetingInfo,
                      "scheduler-id": _vm.item.attachedSchedulerId,
                      "http-client": _vm.httpClient,
                    },
                    on: {
                      "time-confirmed": function ($event) {
                        return _vm.timeConfirmed($event)
                      },
                    },
                  })
                : _vm._e(),
              _vm.scheduledMeeting
                ? _c(
                    "v-container",
                    {
                      staticClass: "font-14 text-left mx-5",
                      staticStyle: {
                        border: "1px solid var(--v-gray_30-base)",
                        "border-radius": "4px",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "brand-medium",
                              attrs: { cols: "4" },
                            },
                            [_vm._v("Meeting status")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticStyle: { "font-weight": "600" },
                              attrs: { cols: "8" },
                            },
                            [_vm._v(_vm._s(_vm.scheduledMeeting.meetingStatus))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "brand-medium",
                              attrs: { cols: "4" },
                            },
                            [_vm._v("Date/Time")]
                          ),
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.DateTime.fromISO(
                                  _vm.scheduledMeeting.confirmedTime.start
                                ).toFormat("cccc") +
                                  " " +
                                  _vm.DateTime.fromISO(
                                    _vm.scheduledMeeting.confirmedTime.start
                                  ).toLocaleString(_vm.DateTime.DATETIME_MED) +
                                  " (" +
                                  _vm.Settings.defaultZone.name +
                                  ")"
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm.scheduledMeeting.location.type === "Zoom" &&
                      _vm.scheduledMeeting.zoomMeeting
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "brand-medium",
                                      attrs: { cols: "4" },
                                    },
                                    [_vm._v("Join URL")]
                                  ),
                                  _c("v-col", { attrs: { cols: "8" } }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.scheduledMeeting.zoomMeeting
                                            .join_url,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.scheduledMeeting.zoomMeeting
                                              .join_url
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "brand-medium",
                                      attrs: { cols: "4" },
                                    },
                                    [_vm._v("Meeting Id")]
                                  ),
                                  _c("v-col", { attrs: { cols: "8" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.scheduledMeeting.zoomMeeting.id
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm.scheduledMeeting.zoomMeeting.pstnPassword
                                ? _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "brand-medium",
                                          attrs: { cols: "4" },
                                        },
                                        [_vm._v("Passcode")]
                                      ),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.scheduledMeeting.zoomMeeting
                                              .pstnPassword
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.zoomDialIn
                                ? _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "brand-medium",
                                          attrs: { cols: "4" },
                                        },
                                        [_vm._v("Dial in")]
                                      ),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.zoomDialIn.number.replaceAll(
                                              " ",
                                              ""
                                            )
                                          ) +
                                            " (" +
                                            _vm._s(_vm.zoomDialIn.city) +
                                            ", " +
                                            _vm._s(
                                              _vm.zoomDialIn.country_name
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm.scheduledMeeting.microsoftEvent &&
                          _vm.scheduledMeeting.microsoftEvent.meetingLink
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", {
                                    staticClass: "brand-medium",
                                    attrs: { cols: "4" },
                                  }),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "my-2",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { elevation: "0" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openTeamsMeeting(
                                                _vm.scheduledMeeting
                                                  .microsoftEvent.meetingLink
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-1" },
                                            [_vm._v("$microsoft")]
                                          ),
                                          _vm._v(" Join Teams meeting"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.scheduledMeeting.location.type === "Google" &&
                          _vm.scheduledMeeting.googleMeeting
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "brand-medium",
                                      attrs: { cols: "4" },
                                    },
                                    [_vm._v("Join URL")]
                                  ),
                                  _c("v-col", { attrs: { cols: "8" } }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.scheduledMeeting
                                            .googleMeeting.hangoutLink,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.scheduledMeeting.googleMeeting
                                              .hangoutLink
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm.googleDialIn
                                ? _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "brand-medium",
                                          attrs: { cols: "4" },
                                        },
                                        [_vm._v("Dial in")]
                                      ),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(_vm.googleDialIn.label) +
                                            " (" +
                                            _vm._s(
                                              _vm.googleDialIn.regionCode
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.googleDialIn
                                ? _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "brand-medium",
                                          attrs: { cols: "4" },
                                        },
                                        [_vm._v("Pin")]
                                      ),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(_vm._s(_vm.googleDialIn.pin)),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm.scheduledMeeting.location.type === "PhoneIn"
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "brand-medium",
                                      attrs: { cols: "4" },
                                    },
                                    [_vm._v("Invitee will call")]
                                  ),
                                  _c("v-col", { attrs: { cols: "8" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.scheduledMeeting.location.phone
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.scheduledMeeting.location.type === "PhoneOut"
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "brand-medium",
                                      attrs: { cols: "4" },
                                    },
                                    [_vm._v("Call invitee at")]
                                  ),
                                  _c("v-col", { attrs: { cols: "8" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.scheduledMeeting.formData.phone
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.scheduledMeeting.location.type === "InPerson"
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "brand-medium",
                                      attrs: { cols: "4" },
                                    },
                                    [_vm._v("Meet at")]
                                  ),
                                  _c("v-col", { attrs: { cols: "8" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.scheduledMeeting.location.address
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.scheduledMeeting.meetingStatus !== "Cancelled"
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.scheduledMeeting.location.custom
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.scheduledMeeting.meetingStatus === "Scheduled"
                        ? _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mt-4 d-flex align-center justify-space-between gap-3",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secondary-action",
                                      staticStyle: { flex: "0 1 50%" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openLink(
                                            _vm.scheduledMeeting.cancelUrl
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "var(--v-error-base)",
                                          },
                                        },
                                        [_vm._v("Cancel meeting")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary-action",
                                      staticStyle: { flex: "0 1 50%" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openLink(
                                            _vm.scheduledMeeting.rescheduleUrl
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Reschedule meeting")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "row-format centered",
              staticStyle: {
                height: "200px",
                border: "1px solid var(--v-gray_20-base)",
              },
            },
            [
              _c(
                "div",
                { staticClass: "font-gray_60 row-format align-center" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { color: "gray_60" },
                    },
                    [_vm._v("event")]
                  ),
                  _c("div", [
                    _vm._v("Please select an embedded meeting scheduler"),
                  ]),
                ],
                1
              ),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }