var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.deliverables.length
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "brand-medium pointer",
            on: {
              click: function ($event) {
                return _vm.$router.push("/projects?v=overview")
              },
            },
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("welcome.currently-have")) + " "),
            _c("span", { staticStyle: { color: "var(--v-primary-base)" } }, [
              _vm._v(_vm._s(_vm.deliverables.length)),
            ]),
            _vm._v(
              " " +
                _vm._s(_vm.$tc("welcome.approval", _vm.deliverables.length)) +
                ". "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "column-format",
            staticStyle: { gap: "8px", "margin-top": "12px" },
          },
          _vm._l(_vm.deliverables, function (deliverable) {
            return _c(
              "div",
              {
                key: deliverable.id,
                staticClass: "action-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.openDeliverable(deliverable)
                  },
                },
              },
              [_vm._v(" " + _vm._s(deliverable.name) + " ")]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }