var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "text-left",
      style: `padding-left: ${_vm.padding(
        _vm.item.pl
      )}px; padding-right: ${_vm.padding(_vm.item.pr)}px; padding-bottom: ${
        _vm.item.pb
      }px; padding-top: ${_vm.item.pt}px; `,
      on: {
        click: function ($event) {
          return _vm.handleClick($event)
        },
      },
    },
    [
      _vm.isReady && _vm.activeElementId === _vm.item.id && _vm.editMode
        ? _c("editor", {
            ref: "textEditor",
            attrs: {
              "api-key": _vm.$store.getters.getTinyMceKey,
              "initial-value": _vm.text,
              inline: true,
              init: _vm.mceConfigText,
              spellcheck: true,
            },
            on: { input: _vm.handleTextInput },
          })
        : _c("div", { domProps: { innerHTML: _vm._s(_vm.tokenizedText) } }),
      _vm.active
        ? _c(
            "div",
            { staticClass: "row-format mb-n1 mt-1" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-outlined",
                  staticStyle: { cursor: "grab" },
                  attrs: { size: "22" },
                },
                [_vm._v("drag_indicator")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }