var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.schema, function (field) {
      return _c(
        "div",
        { key: field.id, staticClass: "text-left" },
        [
          _c(
            field.fieldType,
            _vm._b(
              {
                key: field.id,
                ref: "inputField",
                refInFor: true,
                tag: "component",
                attrs: {
                  value: _vm.formData[field.name],
                  "preview-mode": _vm.previewMode,
                },
                on: {
                  input: function ($event) {
                    return _vm.updateForm(field.id, $event)
                  },
                },
              },
              "component",
              field,
              false
            )
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }