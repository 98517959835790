var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _vm._l(_vm.files, function (file) {
        return _c(
          "div",
          {
            key: file.fileName,
            staticClass: "row-format align-center file-row",
            on: {
              click: function ($event) {
                return _vm.downloadFile(file)
              },
            },
          },
          [
            _c("div", { staticClass: "row-format align-center truncate" }, [
              _c("img", { attrs: { src: file.fileIconUrl, width: "20" } }),
              _c("div", { staticClass: "ml-2 font-12 brand-medium truncate" }, [
                _vm._v(_vm._s(file.fileName)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "row-format align-center ml-auto" },
              [
                _vm.isImageType(file)
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "pointer file-delete",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.previewImage(file)
                          },
                        },
                      },
                      [_vm._v("$visibility")]
                    )
                  : _vm._e(),
                _vm.editMode
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "pointer file-delete ml-2",
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.confirmDeleteFile(file)
                          },
                        },
                      },
                      [_vm._v("$delete")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      }),
      _vm.editMode
        ? _c(
            "div",
            { staticStyle: { width: "fit-content" } },
            [
              _c(
                "vue-dropzone",
                {
                  ref: "fileDropzone",
                  staticStyle: { "background-color": "var(--v-white-base)" },
                  attrs: {
                    id: "dropzone",
                    "use-custom-slot": true,
                    options: _vm.dropzoneOptions,
                  },
                  on: {
                    "vdropzone-success": _vm.uploadSuccess,
                    "vdropzone-error": _vm.uploadFailure,
                    "vdropzone-sending": _vm.addFileUploadAuthHeader,
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "font-14 font-gray_70" },
                    [
                      _c("v-icon", { attrs: { small: "", color: "gray_70" } }, [
                        _vm._v("attachment"),
                      ]),
                      _vm._v(" Attach file"),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.imageModal
        ? _c(
            "modal",
            {
              attrs: { dialog: _vm.imageModal, persistent: false },
              on: {
                close: function ($event) {
                  _vm.imageModal = false
                },
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("img", {
                          staticStyle: { "max-width": "500px" },
                          attrs: { src: _vm.imagePreview },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "modal",
        {
          attrs: {
            dialog: _vm.deleteFileDialog,
            persistent: true,
            "max-width": 290,
          },
          on: {
            close: function ($event) {
              return _vm.cancelDeleteFile()
            },
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "mt-4 pa-4" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("projects.delete-attachment-confirm")) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-4", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "super-action mr-2",
                          on: {
                            click: function ($event) {
                              return _vm.cancelDeleteFile()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("global.no")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary-action ml-2",
                          attrs: { elevation: "0" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteFile()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("global.yes")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }