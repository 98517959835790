var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "submission-details" } }, [
    _c("div", { attrs: { id: "header-area" } }, [
      _c("div", { staticClass: "header-left pl-md-6 pl-4" }, [
        _c("div", { staticClass: "mr-6 brand-medium" }, [
          _vm._v(
            " " +
              _vm._s(_vm.submission.formName) +
              " - " +
              _vm._s(
                _vm.DateTime.fromISO(_vm.submission.submittedAt).toLocaleString(
                  _vm.DateTime.DATETIME_MED
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "header-right pr-md-6 pr-4" }, [
        _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "primary-action",
                attrs: { icon: "" },
                on: {
                  click: function ($event) {
                    return _vm.handleClose()
                  },
                },
              },
              [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "show-scrollbar",
        staticStyle: { width: "100%", height: "100%" },
      },
      [
        _c("iframe", {
          staticClass: "responsive-iframe",
          attrs: { src: _vm.fullUrl, id: `frame-${_vm.frameId}` },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }