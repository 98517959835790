var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ma-4" }, [
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c(
          "v-data-table",
          {
            staticStyle: { cursor: "pointer" },
            attrs: {
              id: "timer-events-table",
              headers: _vm.headers,
              "sort-by": "dateTime",
              "sort-desc": true,
              "must-sort": true,
              items: _vm.fixedUpEvents,
              "disable-pagination": true,
              "hide-default-footer": true,
              "mobile-breakpoint": 200,
            },
            scopedSlots: _vm._u([
              {
                key: "item.invoiced",
                fn: function ({ item }) {
                  return [
                    _c("div", {
                      staticClass: "brand-semilight font-12",
                      domProps: { innerHTML: _vm._s(_vm.getStatus(item)) },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openInvoice(item.invoiceId)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "item.timerStart",
                fn: function ({ item }) {
                  return [
                    _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                      _vm._v(" " + _vm._s(item.dateDisplay) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "item.timeWorkedSortable",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "time-worked text-end",
                        staticStyle: { "text-wrap": "none" },
                      },
                      [_vm._v(" " + _vm._s(item.timeWorked) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "item.projectName",
                fn: function ({ item }) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("empty")(item.projectName))),
                    ]),
                  ]
                },
              },
              {
                key: "item.deliverableName",
                fn: function ({ item }) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("empty")(item.deliverableName)) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.notes",
                fn: function ({ item }) {
                  return [
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm._f("empty")(item.notes)) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "no-data",
                fn: function () {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("timetrack.time-entries.no-timetrack")) +
                        " "
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c("template", { slot: "body.append" }, [
              _c("tr", [
                _c("td"),
                _c("th", { staticClass: "total text-end" }, [
                  _c("span", { staticClass: "font-14" }, [
                    _vm._v(_vm._s(this.$t("timetrack.time-entries.total"))),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "time-worked text-end" }, [
                    _vm._v(_vm._s(_vm.timeWorkedTotal)),
                  ]),
                ]),
                _c("td"),
                _c("td"),
                _c("td"),
              ]),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }