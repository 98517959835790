var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { position: "relative" },
      attrs: { id: "project-selector" },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: { click: _vm.handleClose },
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c("h2", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("projects.add-deliverable"))),
        ]),
        _c("div", { staticClass: "modal-subtitle" }, [
          _vm._v(_vm._s(_vm.$t("projects.add-deliverable-sub"))),
        ]),
        _c(
          "div",
          [
            _c("v-data-table", {
              staticStyle: { cursor: "pointer" },
              attrs: {
                "items-per-page": 100,
                "hide-default-footer": _vm.projects.length <= 100,
                "hide-default-header": true,
                headers: _vm.headers,
                items: _vm.projects,
                "mobile-breakpoint": 0,
              },
              on: {
                "click:row": function ($event) {
                  return _vm.selectProject($event)
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "item.name",
                  fn: function ({ item }) {
                    return [
                      _c("div", { staticClass: "project-wrapper" }, [
                        _c("div", { staticClass: "project-name" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }