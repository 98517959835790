var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row-format",
      staticStyle: { height: "100%", width: "100%", "max-height": "100%" },
    },
    [
      _vm.editable
        ? _c(
            "div",
            {
              staticClass: "show-scrollbar",
              staticStyle: {
                height: "100%",
                "max-height": "100%",
                "overflow-x": "hidden",
                "overflow-y": "auto",
                "max-width": "400px",
                width: "400px",
                "background-color": "var(--v-white-base)",
              },
            },
            [
              _vm.recurring || _vm.paymentPlan
                ? _c(
                    "div",
                    {
                      staticClass: "row-format py-2 px-2",
                      staticStyle: {
                        "border-bottom": "1px solid var(--v-gray_30-base)",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: `row-format align-center page-link ${
                            _vm.tab === "Settings" ? "page-link-active" : ""
                          }`,
                          on: {
                            click: function ($event) {
                              return _vm.selectTab("Settings")
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "material-symbols-outlined",
                              attrs: {
                                size: "22",
                                color:
                                  _vm.tab === "Elements"
                                    ? "primary"
                                    : "gray_50",
                              },
                            },
                            [_vm._v("settings")]
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _vm._v("Settings"),
                          ]),
                        ],
                        1
                      ),
                      _vm.recurring
                        ? _c(
                            "div",
                            {
                              class: `row-format align-center page-link ${
                                _vm.tab === "Additional"
                                  ? "page-link-active"
                                  : ""
                              }`,
                              on: {
                                click: function ($event) {
                                  return _vm.selectTab("Additional")
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-outlined",
                                  attrs: {
                                    size: "22",
                                    color:
                                      _vm.tab === "Additional"
                                        ? "primary"
                                        : "gray_50",
                                  },
                                },
                                [_vm._v("replay_circle_filled")]
                              ),
                              _c("div", { staticClass: "ml-1" }, [
                                _vm._v("Recurring"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.paymentPlan
                        ? _c(
                            "div",
                            {
                              class: `row-format align-center page-link ${
                                _vm.tab === "Additional"
                                  ? "page-link-active"
                                  : ""
                              }`,
                              on: {
                                click: function ($event) {
                                  return _vm.selectTab("Additional")
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-outlined",
                                  attrs: {
                                    size: "22",
                                    color:
                                      _vm.tab === "Additional"
                                        ? "primary"
                                        : "gray_50",
                                  },
                                },
                                [_vm._v("timeline")]
                              ),
                              _c("div", { staticClass: "ml-1" }, [
                                _vm._v("Payment plan"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _c("invoice-settings", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tab === "Settings",
                    expression: "tab === 'Settings'",
                  },
                ],
                attrs: {
                  invoice: _vm.invoice,
                  "additional-tax-rates": _vm.additionalTaxRates,
                  recurring: _vm.recurring,
                  "payment-plan": _vm.paymentPlan,
                  "hide-auto-pay": _vm.recurring || _vm.paymentPlan,
                },
                on: {
                  "force-save": function ($event) {
                    return _vm.$emit("force-save")
                  },
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tab === "Additional",
                      expression: "tab === 'Additional'",
                    },
                  ],
                  staticClass: "px-3",
                },
                [_vm._t("default")],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "pa-8 column-format align-center show-scrollbar main-form",
          style: `width:100%; height: 100%; max-height: 100%; overflow-y: auto; background-color: ${_vm.styles.backgroundColor}`,
          on: {
            click: function ($event) {
              return _vm.handleClick(null)
            },
          },
        },
        [
          _c(
            "div",
            {
              style: `width:100%; min-width: 300px; max-width: ${_vm.styles.maxWidth}px; background-color: ${_vm.styles.pageColor}`,
            },
            _vm._l(_vm.schema, function (item) {
              return _c(
                "div",
                { key: item.id },
                [
                  _c("item-renderer", {
                    attrs: {
                      item: item,
                      styles: _vm.styles,
                      font: _vm.template.font,
                      invoice: _vm.invoice,
                      "edit-mode": _vm.editable,
                      "in-builder": false,
                      "account-logo": _vm.accountLogo,
                      tokens: _vm.tokens,
                      "token-map": _vm.tokenMap,
                      "deposit-account": _vm.depositAccount,
                      "has-expenses": _vm.hasExpenses,
                      recurring: _vm.recurring,
                      "payment-plan": _vm.paymentPlan,
                      "invoice-service": _vm.invoiceService,
                      "attachment-service": _vm.attachmentService,
                      "scheduled-payments": _vm.scheduledPayments,
                      active:
                        item.id === _vm.editItemId ||
                        item.id === _vm.editContainerId,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleClick(item)
                      },
                      change: function ($event) {
                        return _vm.elementUpdated($event)
                      },
                      "container-item-update": function ($event) {
                        return _vm.containerItemUpdated($event)
                      },
                      "container-item-click": function ($event) {
                        return _vm.containerItemClicked($event)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }